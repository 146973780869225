/* Etiquetas multiidoma en castellano. */
export default {
  "bookingSearcherForm.action.clear": "Borrar",
  "bookingSearcherForm.action.search": "Buscar",
  "bookingSearcherForm.creationDate.label": "Creación de la reserva",
  "bookingSearcherForm.excursionDate.label": "Fecha de la excursión",
  "bookingSearcherForm.passenger.label": "Pasajero",
  "bookingSearcherForm.passenger.placeholder": "Nombre y/o apellidos",
  "bookingSearcherForm.reference.label": "Localizador / Nº Ticket",
  "bookingSearcherForm.seller.label": "Vendedor",
};
