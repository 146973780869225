/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/icons/SentimentDissatisfied";

import { InjectedI18nProps, withI18n } from "~/services/i18n";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    code: {
      fontWeight: "bold",
    },
    icon: {
      fontSize: theme.typography.fontSize * 4,
    },
    root: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    text: {
      marginTop: theme.spacing(1),
    },
  });

/** Propiedades del componente. */
interface Props extends WithStyles<typeof styles>, InjectedI18nProps {
  /** El código de la excursión. */
  excursionCode: string;

  /** El código de la modalidad. */
  modalityCode: string;
}

/**
 * Mensaje mostrado cuando no se encuentra ninguna excursión-modaldiad con los
 * códigos indicados.
 */
const ExcursionNotFound: React.SFC<Props> = ({ classes, excursionCode, i18n, modalityCode }) => (
  <div className={classes.root}>
    <Icon color="action" className={classes.icon} />
    <Typography className={classes.text}>{i18n.formatMessage("excursionNotFound.excursionNotFound")}:</Typography>
    <Typography className={classes.code}>
      {excursionCode}-{modalityCode}
    </Typography>
  </div>
);

export default withI18n(withStyles(styles)(ExcursionNotFound));
