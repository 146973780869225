import Environment from "~/environment";

/** Nombre del Header que contiene el token de autenticación y autorización. */
/* Axios los pasa todos a minúsculas. */
export const AUTH_TOKEN_HAEDER = "auth-token";

/** Separador usado para los campos del token. */
export const TOKEN_SEPARATOR = ".";

/** Url del servicio webapi. */
export const BASE_URI = Environment.BASE_URI;
