import { LocalDate } from "js-joda";
import moize from "moize";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { bindActionCreators } from "redux";

import { AppState } from "~/state";
import { wrap } from "~/views/ViewWrapper";

import {
  createAddBookingAction,
  fetchFirstAvailableTicketOption,
  fetchTicketOption,
  fetchTicketOptions,
  loadSearchView,
} from "./actions";
import AddExcursionView, { PayloadProps, Props } from "./AddExcursionView";

/** Propiedades que se derivan del estado de Redux. */
type StateProps = Pick<Props, "excursionTicketOption" | "monthAvailability" | "ready" | "ticketTemplate">;
const mapStateToProps: MapStateToProps<StateProps, PayloadProps, AppState> = state => {
  return {
    excursionTicketOption: state.addExcursionView.excursionTicketOption,
    monthAvailability: state.addExcursionView.rangeAvailability,
    ready: state.addExcursionView.ready,
    ticketTemplate: state.bookingProcess.ticketTemplate,
  };
};

/** Propiedades que contienen acciones. */
type DispatchProps = Pick<
  Props,
  "findFirstAvailableTicketOption" | "getTicketOption" | "getTicketOptions" | "onBackToSearcher" | "onReservation"
>;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, PayloadProps> = (
  dispatch,
  { excursionCode, modalityCode }
) =>
  bindActionCreators(
    {
      findFirstAvailableTicketOption: () => fetchFirstAvailableTicketOption(excursionCode, modalityCode),
      getTicketOption: (date: LocalDate) => fetchTicketOption(excursionCode, modalityCode, date),
      getTicketOptions: (from: LocalDate, to: LocalDate) => fetchTicketOptions(excursionCode, modalityCode, from, to),
      onBackToSearcher: loadSearchView,
      onReservation: createAddBookingAction,
    },
    dispatch
  );

export default connect(mapStateToProps, moize.simple(mapDispatchToProps))(wrap(AddExcursionView));
