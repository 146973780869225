import React from "react";

import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { CodeName } from "~/services/webapi/types";

const styles = () =>
  createStyles({
    option: {
      "&:not(:last-child)": {
        // TODO: Color copiado de material-ui/src/NativeSelect/NativeSelect.js llevar a theme
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      },
      height: "auto",
      minHeight: 24,
      whiteSpace: "normal",
    },
    select: {
      whiteSpace: "normal",
    },
  });

/** Propiedades del componente */
export interface Props extends WithStyles<typeof styles> {
  /** Añade una opción para limpiar el valor */
  addEmpty?: boolean;

  /** Estilo a aplicar al <div> contenedor */
  className?: string;

  /** Indica si debe mostrarse desactivado. */
  disabled?: boolean;

  /** Indica si debe expandir a todo el acho */
  fullWidth?: FormControlProps["fullWidth"];

  /** Etiqueta del componente */
  label: string;

  /** El espaciado verticla del componente */
  margin?: FormControlProps["margin"];

  /** Callback para cuando se cambia de valor */
  onValueChange?: (newValue?: string) => void;

  /** Indica si es un valor obligatorio */
  required?: FormControlProps["required"];

  /** Valor seleccionado */
  selectedCode?: string | null;

  /** Conjunto de valores (opciones) */
  values?: CodeName[];
}

/**
 * Componente para la selección de un valor de entre una lista
 */
class CodeNameSelect extends React.PureComponent<Props, {}> {
  public render() {
    const {
      addEmpty,
      classes,
      className,
      disabled,
      fullWidth,
      label,
      margin,
      required,
      selectedCode,
      values,
    } = this.props;

    return (
      <FormControl className={className} margin={margin} fullWidth={fullWidth} required={required}>
        <InputLabel htmlFor={label}>{label}</InputLabel>
        <Select
          inputProps={{ id: label }}
          value={selectedCode || ""}
          onChange={this.handleValueChange}
          disabled={disabled || !values || values.length < 1}
          classes={{ select: classes.select }}
        >
          {addEmpty && (
            <MenuItem value={undefined} className={classes.option}>
              <Typography color="textSecondary">--- limpiar ---</Typography>
            </MenuItem>
          )}
          {values &&
            values.map((value, index) => (
              <MenuItem key={index} value={value.code} className={classes.option}>
                {value.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  /** Gestiona el evento onChange del componente select */
  private handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (this.props.onValueChange) {
      const value = event.target.value;
      if (value) {
        this.props.onValueChange(value);
      } else {
        this.props.onValueChange(undefined);
      }
    }
  };
}

export default withStyles(styles)(CodeNameSelect);
