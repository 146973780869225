import React from "react";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";

import { CustomTableCell } from "~/components/CustomTable";
import LongPressButton from "./LongPressButton";

/** Propieades del componente. */
interface Props {
  /** Clase css del div principal. */
  className?: string;

  /** Función invocada cuando se cambia de valor. */
  onValueChange?: (type: string, units: number) => void;

  /** El tipo de unidad. */
  type: string;

  /** El número de unidades. */
  units: number;
}

/**
 * Componente tipo spinner con dos botones para incrementar y decrementar
 * unidades, Si se realiza una "pulsación larga" el incremento/decremento se
 * hace de 10 en 10.
 *
 * Los botones para cambiar el valor únicamente se renderizan si hay un handler
 * asociado.
 */
class UnitsControl extends React.PureComponent<Props> {
  /** #render */
  public render() {
    const { units, onValueChange } = this.props;

    return (
      <CustomTableCell>
        <div className={this.props.className}>
          {onValueChange != null && (
            <LongPressButton onPress={this.addOne} onLongPress={this.addMultiple}>
              <AddIcon />
            </LongPressButton>
          )}
          {units}
          {onValueChange != null && (
            <LongPressButton onPress={this.removeOne} onLongPress={this.removeMultiple} disabled={units < 1}>
              <RemoveIcon />
            </LongPressButton>
          )}
        </div>
      </CustomTableCell>
    );
  }

  /** Dispara el evento para añadir N unidades. */
  private addMultiple = () => {
    const { type, units, onValueChange } = this.props;

    if (onValueChange != null) {
      onValueChange(type, units + 10);
    }
  };

  /** Dispara el evento para añadir una unidad. */
  private addOne = () => {
    const { type, units, onValueChange } = this.props;

    if (onValueChange != null) {
      onValueChange(type, units + 1);
    }
  };

  /** Dispara el evento para quitar N unidades. */
  private removeMultiple = () => {
    const { type, units, onValueChange } = this.props;

    if (onValueChange != null) {
      onValueChange(type, Math.max(units - 10, 0));
    }
  };

  /** Dispara el evento para quitar una unidad. */
  private removeOne = () => {
    const { type, units, onValueChange } = this.props;

    if (onValueChange != null) {
      onValueChange(type, units - 1);
    }
  };
}

export default UnitsControl;
