/* eslint-disable react/prop-types */ // TODO Fix
/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import React, { useState } from "react";

import DialogContentText from "@material-ui/core/DialogContentText";

import { useAsyncCancelableEffect as useAsyncEffect, useMountRef } from "~/hooks/asyncHooks";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { get as apiGetBooking } from "~/services/webapi/bookings";
import { Money } from "~/services/webapi/types";

import ConfirmDialog from "~/components/ConfirmDialog";

interface TicketCancelPanelProps {
  /** Número de reseva del ticket a anural. */
  bookingNumber: string;

  /**
   * Función para cancelar un ticket. Se pasa por ahora porque es una acción
   * redux.
   */
  cancelTicket: (bookingNumber: string, ticketNumber: string) => Promise<any>;

  /**
   * Handler invocado al cerrar el panel. Si se cierra debido a un error se
   * indicará en el parámetro error.
   */
  onClose: (error?: string) => void;

  /** Handler invocado cuando el usuario solicita la impersión del ticket. */
  onPrint: () => void;

  /** Número de ticket a anular. */
  ticketNumber: string;
}

/**
 * Panel de cancelación de un ticket.
 */
const TicketCancelPanel: React.FC<TicketCancelPanelProps & InjectedI18nProps> = props => {
  const {
    bookingNumber,
    cancelTicket,
    i18n: { formatCurrency, formatMessage },
    onClose,
    onPrint,
    ticketNumber,
  } = props;

  /* Estado. */
  const mountRef = useMountRef();
  const [step, setStep] = useState<"fetch" | "confirm" | "print" | "end">("fetch");
  const [cancellAmount, setCancellAmount] = useState<Money>();

  /* Acciones. */
  /* Carga de los gastos de cancelación. */
  useAsyncEffect(
    async () => {
      setStep("fetch");

      const booking = await apiGetBooking(bookingNumber).catch(() => {
        /* No hacer nada. */
      });
      const ticket = booking != null && booking.tickets.find(t => t.ticketNumber === ticketNumber);

      return ticket;
    },
    ticket => {
      if (ticket) {
        const price = ticket.price;
        if (price.cancellationAmount != null) {
          setCancellAmount({ amount: price.cancellationAmount, currency: price.currency });
        }
        setStep("confirm");
      } else {
        onClose(formatMessage("bookingView.error.fetchCancelCost"));
      }
    },
    () => {
      /* Nunca debería, tenemos un .catch arriba. */
    },
    [bookingNumber, ticketNumber, onClose, formatMessage]
  );

  /* Callbacks */
  // TODO: Debería creaer 3 callbacks y usar asyncCallback para la cancelación.
  const handleAction = async (actionConfirmed: boolean) => {
    if (step === "confirm") {
      if (actionConfirmed) {
        await cancelTicket(bookingNumber, ticketNumber);

        // ouch
        if (mountRef.current.isMounted) {
          setCancellAmount(undefined);
        }
        if (mountRef.current.isMounted) {
          setStep("print");
        }
      } else {
        setStep("end");
        onClose();
      }
    } else if (step === "print") {
      if (actionConfirmed) {
        onPrint();
      }
      setStep("end");
    }
  };

  /* Render. */
  let dialogTitle = "";
  let dialogContent: React.ReactNode;

  if (step === "confirm") {
    dialogTitle = formatMessage("bookingView.cancelTicket");
    dialogContent = (
      <>
        <DialogContentText>
          {formatMessage("bookingView.cancelTicketAlert")}
          <br />
          {formatMessage("bookingView.cancellationAmount")}
          {": "}
          <strong>
            {formatCurrency(cancellAmount && cancellAmount.amount, cancellAmount && cancellAmount.currency)}
          </strong>
        </DialogContentText>
        <DialogContentText>{formatMessage("bookingView.cancelContinue")}</DialogContentText>
      </>
    );
  } else if (step === "print") {
    dialogTitle = formatMessage("bookingView.cancelled");
    dialogContent = <DialogContentText>{formatMessage("bookingView.printCancelTicket")}</DialogContentText>;
  } else {
    /*
     * Las ventanillas de cargando se tratan a nivel general. No hace falta
     * generar nada.
     */
    return null;
  }

  return (
    <ConfirmDialog open={true} title={dialogTitle} onSelect={handleAction}>
      {dialogContent}
    </ConfirmDialog>
  );
};

export default withI18n(TicketCancelPanel);
