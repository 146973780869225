import { ExcursionTicket, PaxQuota, PriceBreakdown } from "~/services/webapi/types";

/** Conifiguración de distribución de pasajeros con importes. */
export interface Distribution {
  /** Cupos por tipo de pax. */
  paxes: PaxQuota[];

  /** Resumen del importe total de todos los paxes. */
  price: PriceBreakdown;

  /** Resumen del total de paxes seleccionados. */
  totalPaxCount: number;
}

/** Generación del objeto distribución a partir de PaxQuota */
export function paxQuotaToDistribution(paxQuotas?: PaxQuota[], skipEmpty?: boolean): Distribution {
  const result: Distribution = {
    paxes: [],
    price: {
      basePrice: 0,
      currency: "",
      totalPrice: 0,
    },
    totalPaxCount: 0,
  };

  if (paxQuotas != null) {
    for (const paxQuota of paxQuotas) {
      if (!skipEmpty || paxQuota.units > 0) {
        result.paxes.push(paxQuota);
        result.price.basePrice += paxQuota.units * paxQuota.paxType.price.amount;
        result.price.totalPrice = result.price.basePrice;
        result.price.currency = paxQuota.paxType.price.currency;
        result.totalPaxCount += paxQuota.units;
      }
    }
  }

  return result;
}

/** Generación del objeto distribución a partir del ticket */
export function ticketDistribution(ticket: ExcursionTicket): Distribution {
  const result: Distribution = {
    paxes: [],
    price: {
      ...ticket.price,
      cancellationAmount: ticket.status === "CANCELLED" ? ticket.price.cancellationAmount : undefined,
    },
    totalPaxCount: 0,
  };

  if (ticket.paxes != null) {
    for (const paxQuota of ticket.paxes) {
      if (paxQuota.units > 0) {
        result.paxes.push(paxQuota);
        result.totalPaxCount += paxQuota.units;
      }
    }
  }

  return result;
}
