/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import LabeledText from "~/components/LabeledText";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Payment } from "~/services/webapi/types";

interface BookingPaymentProps {
  payment?: Payment;
}

/**
 * Información de pago de la reserva.
 */
const BookingPayment: React.FC<BookingPaymentProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatMessage },
    payment,
  } = props;

  if (!payment?.type) {
    return null;
  }

  return (
    <div>
      <LabeledText
        label={formatMessage("bookingView.paymentType")}
        text={formatMessage(`bookingView.paymentType.${payment.type}`, { reference: payment.reference })}
        textSize="large"
      />
    </div>
  );
};

export default withI18n(BookingPayment);
