/* Etiquetas multiidoma en castellano. */
export default {
  "bookingSearcherForm.action.clear": "Clear",
  "bookingSearcherForm.action.search": "Search",
  "bookingSearcherForm.creationDate.label": "Booking creation date",
  "bookingSearcherForm.excursionDate.label": "Excursion date",
  "bookingSearcherForm.passenger.label": "Passenger",
  "bookingSearcherForm.passenger.placeholder": "Name and/or surnames",
  "bookingSearcherForm.reference.label": "Booking/Ticket number",
  "bookingSearcherForm.seller.label": "Seller",
};
