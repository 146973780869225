import React from "react";

import classNames from "classnames";

import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import ListItem from "@material-ui/core/ListItem";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Booking, BookingStatus } from "~/services/webapi/types";

import Seller from "./components/Seller";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    bookingNumber: {
      fontWeight: 500,
    },
    root: {
      width: "100%",
    },
    status: {},
    statusCancelled: {
      color: red[500],
    },
    statusConfirmed: {
      color: green[500],
    },
    summary: {},
    text: {
      fontSize: theme.typography.pxToRem(13),
    },
    ticket: {
      paddingLeft: 4,
    },
    title: {
      "& > div": {
        flex: "1 1 100px",
      },
      "& > div:first-child": {
        flexBasis: 200,
      },
      "& > div:last-child": {
        textAlign: "right",
      },
      display: "flex",
    },
  });

/** Las propiedades del componente. */
interface Props extends WithStyles<typeof styles> {
  /** La reserva a visualizar. */
  booking: Booking;

  /** Función invocada al selecionar una reserva. */
  onSelect?: (booking: Booking) => void;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Listado de reservas.
 */
class BookingsListItem extends React.PureComponent<ProvidedProps> {
  /** #constructor */
  public constructor(props: ProvidedProps) {
    super(props);
  }

  /** #render */
  public render() {
    const { booking, classes } = this.props;
    const { formatLocalDateString, formatCurrency } = this.props.i18n;

    const tickets = booking.tickets.filter(t => t.status !== "CANCELLED");

    const showContact = booking.status !== "CANCELLED";
    const showTotal = booking.status !== "CANCELLED" || Boolean(booking.totalPrice ? booking.totalPrice.amount : 0);

    return (
      <ListItem button disableGutters onClick={this.select}>
        <div className={classes.root}>
          <Typography className={classNames(classes.text, classes.title)} component="div">
            <div className={classes.bookingNumber}>{booking.bookingNumber}</div>
            <div
              className={classNames(classes.status, {
                [classes.statusCancelled]: booking.status === "CANCELLED",
                [classes.statusConfirmed]: booking.status === "CONFIRMED",
              })}
            >
              {this.getStatusTitle(booking.status)}
            </div>
            <div>{formatLocalDateString(booking.creationDate)}</div>
          </Typography>
          <Seller value={booking.seller} />
          <div>
            <Typography color="textSecondary" className={classes.text} component="div">
              {tickets.map((ticket, idx) => (
                <div key={idx} className={classes.ticket}>
                  - {ticket.ticketNumber}: {ticket.excursionName} ({formatLocalDateString(ticket.excursionDate)})
                </div>
              ))}
              <div className={classes.summary}>
                {showContact && booking.contact && `${booking.contact.name} - `}
                <Typography color="textPrimary" className={classes.text} component="span">
                  {showTotal &&
                    booking.totalPrice &&
                    formatCurrency(booking.totalPrice.amount, booking.totalPrice.currency)}
                </Typography>
              </div>
            </Typography>
          </div>
        </div>
      </ListItem>
    );
  }

  private getStatusTitle(status: BookingStatus) {
    switch (status) {
      case "CANCELLED":
      case "CONFIRMED":
        return this.props.i18n.formatMessage(`bookingListItem.${status}`);
      default:
        return `?${status}?`;
    }
  }

  private select = () => {
    const { booking, onSelect } = this.props;

    if (onSelect) {
      onSelect(booking);
    }
  };
}

export default withI18n(withStyles(styles)(BookingsListItem));
