import React from "react";

/** Propiedades del elemento que define cada vista disponible. */
export interface ViewDefProps<V> {
  /** El componente de la vista. */
  component: React.ComponentType;

  /** Identificador de la vista. */
  view: V;
}

/** Componente para definir las vistas dentro de ViewController. */
export default class ViewDef<V> extends React.PureComponent<ViewDefProps<V>> {
  /* No se renderiza directamente. */
  public render() {
    return null;
  }
}
