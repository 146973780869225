import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";
import InfoIcon from "@material-ui/icons/Info";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import logger from "~/services/logger";

/** Estilos definidos */
type ClassKey = "icon" | "root";

/** Propiedades que recibe el componente */
export interface Props extends WithStyles<ClassKey> {
  /** Condiciones de cancelación de un ticket */
  cancelConditions: string[];
}

/** Estado del componente */
interface State {
  /**
   * Indica si el diálogo que muestra el detalle de las condiciones de
   * cancelación se está mostrando
   */
  open: boolean;
}

/** Estado por defecto */
const DEFAULT_STATE: State = {
  open: false,
};

/** Definición de los estilos */
const styles: StyleRules<ClassKey> = {
  icon: {
    color: orange[500],
    marginLeft: 7,
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 8,
  },
};

interface ProvidedProps extends Props, InjectedI18nProps {}

/** Componente para mostrar la política de cancelación */
class CancelConditionsViewer extends React.PureComponent<ProvidedProps, State> {
  public constructor(props: ProvidedProps) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  public render() {
    const { formatMessage } = this.props.i18n;

    return (
      <div className={this.props.classes.root}>
        <Button onClick={this.handleClick} endIcon={<InfoIcon color="action" />}>
          {formatMessage("cancelConditionsViewer.title")}
        </Button>
        <Dialog disableBackdropClick disableEscapeKeyDown open={this.state.open}>
          <DialogTitle>{formatMessage("cancelConditionsViewer.dialog.title")}</DialogTitle>
          <DialogContent>
            {this.props.cancelConditions.map((cancelCondition, index) => [
              <Typography key={"T_" + index} variant="body2">
                {cancelCondition}
              </Typography>,
              <br key={"BR_" + index} />,
            ])}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  private handleClick = () => {
    logger.info("open");
    this.setState({ open: true });
  };

  private handleClose = () => {
    logger.info("close");
    this.setState({ open: false });
  };
}

export default withI18n(withStyles(styles)(CancelConditionsViewer));
