/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import EditIcon from "@material-ui/icons/Edit";

import { InjectedI18nProps, withI18n } from "~/services/i18n";

import { BookingViewMode } from "../types";

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      marginRight: 4,
    },
    root: {
      alignItems: "center",
      color: theme.palette.text.secondary,
      display: "flex",
      marginBottom: 2,
      marginTop: 4,
    },
    text: {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    },
  })
);

interface BookingViewStatusProps {
  mode: BookingViewMode;
}

/**
 * Renderiza el estado de la pantalla de visualización de reservas.
 *
 * TODO: [CTX/REDUX] Hooks de contexto y redux para las props correspondientes.
 */
const BookingViewStatus: React.FC<BookingViewStatusProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatMessage },
    mode,
  } = props;

  const classes = useStyles();

  let statusText: string | undefined;
  let StatusIcon: React.ComponentType<SvgIconProps> | undefined;

  switch (mode) {
    case "editing":
      StatusIcon = EditIcon;
      statusText = formatMessage(`bookingView.mode.${mode}`);
  }

  return (
    <div className={classes.root}>
      {StatusIcon && <StatusIcon className={classes.icon} fontSize="small" />}
      <span className={classes.text}>{statusText}</span>
    </div>
  );
};

export default withI18n(BookingViewStatus);
