/* Etiquetas multiidoma en castellano. */
export default {
  "searcherView.agency": "Agencia",
  "searcherView.destination": "Destino",
  "searcherView.duration": "Duración",
  "searcherView.excursion": "Excursión",
  "searcherView.excursionType": "Tipo de Excursión",
  "searcherView.feature": "Característica",
  "searcherView.modality": "Modalidad",
  "searcherView.retry": "Reintentar",
  "searcherView.search": "Buscar",
  "searcherView.segmentation": "Segmentación",
  "searcherView.unableToLoadDestinations": "No se han podido cargar los destinos",
};
