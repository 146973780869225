import React from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { setInjectedIntl } from "./i18nApiImpl";

import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";

/** Aquí hay que ir añadiendo todos los idiomas soportados */
addLocaleData(es);
addLocaleData(en);

/** Propiedades del componente */
interface Props extends InjectedIntlProps {
  /** Idioma seleccionado */
  locale: string;
}

/**
 * Inicializador del servicio de traducción (componente no visual)
 *
 * Recupera la instancia de react-intl inyectada en sus propiedades
 * durante la construcción y cambio de idioma y la setea en el
 * módulo de traducción.
 */
class IntlInitializer extends React.Component<Props, {}> {
  public constructor(props: Props) {
    super(props);
    setInjectedIntl(this.props.intl);
  }

  public componentDidUpdate() {
    setInjectedIntl(this.props.intl);
  }

  public render() {
    return null;
  }
}

export default injectIntl(IntlInitializer);
