/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import SellerIcon from "@material-ui/icons/Face";

import { Seller as SellerType } from "~/services/webapi/types";

const useStyles = makeStyles(theme =>
  createStyles({
    icon: {
      fontSize: "1rem",
      marginRight: 4,
    },
    root: {
      alignItems: "center",
      color: theme.palette.text.primary,
      display: "flex",
    },
  })
);

/**
 * Componente para mostrar la información del vendedor en el listado de
 * reservas.
 *
 * En caso de no informase el vendedor, no renderiza nada.
 */
const Seller: React.FC<{ value?: SellerType }> = props => {
  const classes = useStyles();

  if (!props.value) {
    return <div className={classes.root} />;
  }

  return (
    <div className={classes.root}>
      <SellerIcon className={classes.icon} />
      {props.value.name}
    </div>
  );
};

export default Seller;
