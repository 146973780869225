/* Etiquetas multiidoma en castellano. */
export default {
  "printTicket.agency": "Agency",
  "printTicket.agent": "Agent",
  "printTicket.annulation": "ANNULATION",
  "printTicket.cancellationPolicy": "Cancellation policy",
  "printTicket.contact": "Name",
  "printTicket.excursionDate": "Date",
  "printTicket.hotel": "Hotel",
  "printTicket.pickUpPointName": "Pick Up",
  "printTicket.pickUpPointTime": "Time",
  "printTicket.price.discount": "Discount",
  "printTicket.price.subtotal": "Subtotal",
  "printTicket.price.total": "TOTAL",
  "printTicket.printTime": "Printed: {time}",
  "printTicket.reservationDate": "Date",
  "printTicket.reservationNumber": "Loc.",
  "printTicket.payment": "Payment type: {payment}",
  "printTicket.paymentType.CARD": "Card",
  "printTicket.paymentType.CASH": "Cash",
  "printTicket.payment.reference": "Reference: {reference}",
};
