import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { AppState } from "~/state";

import { login, setLoginError } from "./actions";
import LoginView, { Props } from "./LoginView";

/** Propiedades derivadas del estado. */
type StateProps = Pick<Props, "hasLoginError">;
const mapStateToProps: MapStateToProps<StateProps, void, AppState> = state => ({
  hasLoginError: state.loginView.hasLoginError,
});

/** Función para "limpiar" el error de login. */
const clearLoginError = () => setLoginError(false);

/** Propiedades a partir de acciones. */
type DispatchProps = Pick<Props, "onDismissError" | "onLogin">;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, void> = {
  onDismissError: clearLoginError,
  onLogin: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
