/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix
/* eslint-disable react/prop-types */ // TODO Fix

import React, { useCallback } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import EditableLabeledText from "~/components/EditableLabeledText";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Contact } from "~/services/webapi/types";
import { noop } from "~/utils";

const useStyles = makeStyles(theme =>
  createStyles({
    row: {
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(),
      },
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface BookingContactProps {
  /** Los datos de contacto. */
  contact?: Contact;

  /** Indica si se muestra en modo edición. */
  editionEnabled?: boolean;

  /** Invocado cuando se modifica el valor de un campo. */
  onFieldChange?: (field: string, value: string) => void;
}

/**
 * Información de contacto de la reserva.
 * Permite edición.
 */
const BookingContact: React.FC<BookingContactProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatMessage },
    // TODO: Configurar ESLint para poder usar elvis y este default sobrará
    contact = props.editionEnabled ? ({} as Contact) : undefined,
    editionEnabled,
    onFieldChange = noop,
  } = props;

  const classes = useStyles();

  /*
   * Callbacks
   */
  const updateInputField = useCallback(
    (value: string, name?: string) => {
      onFieldChange(name!, value);
    },
    [onFieldChange]
  );

  /*
   * Render
   */
  if (!contact) {
    return null;
  }

  return (
    <div>
      <div className={classes.row}>
        <EditableLabeledText
          editionEnabled={editionEnabled}
          label={formatMessage("bookingView.contact")}
          name="name"
          text={contact.name}
          textSize={"large"}
          onChange={updateInputField}
        />

        <EditableLabeledText
          editionEnabled={editionEnabled}
          label={formatMessage("bookingView.phoneNumber")}
          name="phone"
          text={contact.phone}
          textSize={"large"}
          onChange={updateInputField}
        />
      </div>
    </div>
  );
};

export default withI18n(BookingContact);
