import "core-js";
import React from "react";
import ReactDOM from "react-dom";

import { handleError } from "./actions";
import AppWrapper from "./AppWrapper";
import { StateProvider } from "./state";

ReactDOM.render(
  <StateProvider onError={handleError}>
    <AppWrapper />
  </StateProvider>,
  document.getElementById("app-wrapper")
);
