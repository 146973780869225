import { Reducer } from "redux";

import { ConfigAction } from "./actions";
import { SET_APP_CONFIG } from "./actions";
import { ConfigState } from "./types";

/** Estado por defecto. */
const defaultState: ConfigState = {};

/** Reducer */
const reducer: Reducer<ConfigState, ConfigAction> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_APP_CONFIG:
      return { ...state, appConfig: action.appConfig };

    default:
      return state;
  }
};

export default reducer;
