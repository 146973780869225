import { applyMiddleware, createStore as createReduxStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import createThunkErrorHandlerMiddleware, { ErrorHandler } from "redux-thunk-error-handler";
import thunkMiddleware from "redux-thunk-recursion-detect";

import reducer from "./reducer";
import { AppState } from "./types";

export default function createStore<R>(onError: ErrorHandler<R, AppState, undefined>): Store<AppState> {
  return createReduxStore(
    reducer,
    composeWithDevTools(applyMiddleware(createThunkErrorHandlerMiddleware({ onError }), thunkMiddleware))
  );
}
