/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import clsx from "classnames";

import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  theme =>
    createStyles({
      label: {
        color: theme.palette.text.secondary,
        fontSize: "0.75rem",
        letterSpacing: "0.0080325em",
      },
      root: {
        marginBottom: 2,
        marginTop: 4,
      },
      // TODO: Por ahora se mapea contra estos hasta que se definan unos tamaños fijos a nivel de App.
      textL: theme.typography.subtitle1,
      textN: theme.typography.body2,
      textXL: theme.typography.h6,
    }),
  { name: "QkkLabeledText" }
);

export interface LabeledTextProps {
  /** Clase css a aplicar al componente principal. */
  className?: string;

  /** Etiqueta del texto. */
  label?: React.ReactNode;

  /** Indica si mostrar el componente aunque el texto sea vacío. */
  showEmpty?: boolean;

  /** Texto a mostrar. */
  text?: string;

  /** Clase a aplicar al texto. */
  textClass?: string;

  /** Tamaños de texto soportados. */
  textSize?: "normal" | "large" | "extraLarge";
}

/**
 * Componente para renderizar un texto con una etiqueta. Vendría a ser como un
 * TextFiel que es de solo lectura pero sin ser un input.
 */
const LabeledText: React.FC<LabeledTextProps> = props => {
  const { label, text = "", textSize, textClass: propTextClass, showEmpty } = props;

  const classes = useStyles();

  if (!text && !showEmpty) {
    return null;
  }

  const lines = text.split("\n");

  const textClass = clsx(propTextClass, {
    [classes.textN]: !textSize || textSize === "normal",
    [classes.textL]: textSize === "large",
    [classes.textXL]: textSize === "extraLarge",
  });

  return (
    <div className={clsx(classes.root, props.className)}>
      {label && <div className={classes.label}>{props.label}</div>}
      <div className={textClass}>
        {lines.map((line, idx) => (
          <div key={idx}>{line}</div>
        ))}
      </div>
    </div>
  );
};

export default LabeledText;
