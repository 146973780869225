import React from "react";

import { IntlProvider } from "react-intl";

import { noop } from "~/utils";
import logger from "../logger";
import {
  formatCurrency,
  formatLocalDate,
  formatLocalDateString,
  formatLocalDateTime,
  formatLocalDateTimeString,
  formatLocalTime,
  formatLocalTimeString,
  formatMessage,
  formatNumber,
  formatYearMonth,
  getLocale,
} from "./i18nApiImpl";
import IntlInitializer from "./IntlInitializer";
import { I18nAPI, I18nProviderProps } from "./types";

/** Idioma por defecto */
const DEFAULT_LOCALE = "es";

/**
 * Función para construir el valor del API del contexto para el módulo
 * multiidoma
 */
function buildContext(setLocale: (locale: string) => void): I18nAPI {
  return {
    formatCurrency,
    formatLocalDate,
    formatLocalDateString,
    formatLocalDateTime,
    formatLocalDateTimeString,
    formatLocalTime,
    formatLocalTimeString,
    formatMessage,
    formatNumber,
    formatYearMonth,
    getLocale,
    setLocale,
  };
}

/** Genero el contexto por defecto */
const I18nContextImpl = React.createContext(buildContext(noop));

/** Proveedor de contexto */
export const I18nContextProvider = I18nContextImpl.Provider;

/** Consumidor de contexto */
export const I18nContextConsumer = I18nContextImpl.Consumer;

/** Estado del Proveedor del servicio multiidioma */
interface State {
  /** Idioma seleccionado */
  locale: string;
}

/**
 * Proveedor del API Multiidioma
 *
 * Provee el API para la traducción de etiquetas y formateo de números y fechas
 * en función del idioma seleccionado.
 */
export class I18nProvider extends React.Component<I18nProviderProps, State> {
  public constructor(props: I18nProviderProps) {
    super(props);
    this.state = { locale: DEFAULT_LOCALE };
  }

  public render() {
    const locale = this.state.locale;
    const context = buildContext(this.setLocale);
    const messages = this.props.messages ? this.props.messages[locale] : undefined;

    return (
      <I18nContextProvider value={context}>
        <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={messages} key={locale}>
          <IntlInitializer locale={locale} key={locale} />
        </IntlProvider>
        {this.props.children}
      </I18nContextProvider>
    );
  }

  /** Handler para el cambio de idioma */
  private setLocale = (locale: string) => {
    logger.info("setLocale", locale);
    this.setState({ locale });
  };
}
