/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

/* Acciones de la App raíz. Principalmente inicialización. */
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { autoAuthenticate } from "~/services/auth";
import logger from "~/services/logger";
import { AppState } from "~/state";
import { noop } from "~/utils";
import { loadView, ViewId } from "~/views";

import { deauthenticate } from "./services/webapi/authentication";

/** Acción que indica un reset del estado, pero manteniendo autenticación. */
export const SOFT_RESET = "App/SOFT_RESET";

/** Acción que indica un reset de la aplicación entera. */
export const RESET_STATE = "App/RESET_APP";

/** Acción para resetear la App. */
export interface SoftReset extends Action {
  type: typeof SOFT_RESET;
}

/** Acción para resetear el estado de la App. */
export interface ResetState extends Action {
  type: typeof RESET_STATE;
}

/**
 * Thunk para autenticar al usuario automáticamente.
 */
export const autologin = (): ThunkAction<Promise<boolean>, AppState, void, Action> => async (dispatch, getState) => {
  /*
   * Si falla autologin no hacemos nada. Si el servidor está ko o cualquier
   * otra cosa ya se tratará desde la propia pantalla de login.
   */
  await dispatch(autoAuthenticate()).catch(noop);

  /*
   * Tambíen se podría sacar de la respuesta, pero se mira el estado para
   * asegurar que no solo la petición ha ido bien, sino que también nos
   * encontramostodo en un "estado"  de autenticado.
   */
  const user = getState().auth.user;

  if (user != null) {
    dispatch(loadView(ViewId.searcher));
    logger.info("Autologin: successful");

    return true;
  } else {
    dispatch(loadView(ViewId.login));
    logger.info("Autologin: no");

    return false;
  }
};

/**
 * Action creator que devuelve la acción a ejecutar si se llega a capturar un
 * error en las capas superiorse. Es un tratamiento genérico para los errores
 * no controlados.
 *
 * Tiene que devolver un thunk porque sino la librería no hace el dispatch. El
 * README que tienen miente. Tiene que ser una función, no vale una acción.
 *
 * @param error el error.
 */
export const handleError = (error: any): ThunkAction<Promise<void>, AppState, void, Action> => async dispatch => {
  dispatch(loadView(ViewId.error, { error }, true));
};

/**
 * Thunk para inicializar la app.
 *
 * @param cb  callback que se invocará una finalizada la inicialización.
 */
export const initialize = (): ThunkAction<Promise<void>, AppState, void, Action> => async dispatch => {
  await dispatch(autologin()).catch(noop);
};

/** Crea la acción de resetear la app. */
export const resetState = (): ThunkAction<Promise<void>, AppState, void, Action> => async dispatch => {
  /*
   * Al resetear la app entera, si hay algo de autenticación también hay que
   * notificar al servicio auth.
   */
  await dispatch(deauthenticate);
  dispatch({ type: RESET_STATE });
};

/** Crea la acción de reset por error. */
export const softReset = (): SoftReset => ({ type: SOFT_RESET });
