/* Etiquetas multiidoma en castellano. */
export default {
  "errorView.close": "Close",
  "errorView.error.applicationError": "Internal application error.",
  "errorView.error.connectionTimeout": "Connection timeout.",
  "errorView.error.hostUnreachable": "Unable to connect to the server.",
  "errorView.error.serverError": "Internal server error.",
  "errorView.errorTitle": "Error",
  "errorView.showDetails": "Show details",
};
