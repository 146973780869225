import React from "react";

import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import classNames from "classnames";
import { DayOfWeek, LocalDate, TemporalAdjusters, YearMonth } from "js-joda";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { ExcursionTicketOption, LocalDateString } from "~/services/webapi/types";
import { noop } from "~/utils";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    adjacentMonthCell: {
      backgroundColor: theme.palette.grey[200],
      opacity: 0.25,
    },
    /*
     * Este bloque tiene que ir en orden específico para precedencia de las
     * reglas css generadas.
     */
    availability: {
      alignItems: "center",
      borderRadius: "50%",
      boxShadow: theme.shadows[2],
      display: "flex",
      height: "2.5em",
      justifyContent: "center",
      width: "2.5em",
    },
    availabilityNone: {
      backgroundColor: grey["200"],
      color: theme.palette.common.black,
    },
    availabilityLow: {
      backgroundColor: red["700"],
      color: theme.palette.common.white,
    },
    availabilityMedium: {
      backgroundColor: orange["800"],
      color: theme.palette.common.white,
    },
    availabilityHigh: {
      backgroundColor: green["700"],
      color: theme.palette.common.white,
    },
    cell: {
      "&:first-child": {
        paddingLeft: theme.spacing(0.5),
      },
      "&:last-child": {
        paddingRight: theme.spacing(0.5),
      },
      height: "3.5em",
      padding: theme.spacing(0.5),
      position: "relative",
      textAlign: "center",
      width: "3.5em",
    },
    currentDayCell: {},
    currentDayOfMonth: {
      color: theme.palette.primary.main,
    },
    dayOfMonth: {
      fontSize: "0.6rem",
      left: 4,
      position: "absolute",
      top: 2,
    },
    pastDayCell: {
      opacity: 0.4,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 0,
      flexShrink: 0,
      margin: theme.spacing(1),
    },
    selectedDayOfMonth: {
      color: theme.palette.secondary.main,
    },
    topPane: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: "space-between",
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** La fecha actualmente seleccionada. */
  date: LocalDate;

  /** Función para recuperar la disponibilidad entre fechas. */
  getTicketOptions?: (from: LocalDate, to: LocalDate) => void;

  /** Función para tratar el evento de selección de fecha. */
  onSelect?: (date: LocalDate) => void;

  /** Mapa con los valores de disponibilidad para cada día. */
  values?: Map<LocalDateString, ExcursionTicketOption> | null;
}

interface ProviedProps extends Props, InjectedI18nProps {}

/** Estado del componente. */
interface State {
  /** Mes que se está visualizando. */
  month: YearMonth;
}

/**
 * Calendario para mostrar la disponibilidad de tickets para un mes.
 * Permite marcar una fecha seleccionada y, a su vez, seleccionar otra.
 */
class AvailabilityCalendar extends React.PureComponent<ProviedProps, State> {
  /** #constructor */
  public constructor(props: ProviedProps) {
    super(props);
    this.state = {
      month: YearMonth.of(props.date.year(), props.date.month()),
    };
  }

  /** #didMount */
  public componentDidMount() {
    this.fetch();
  }

  /** #didUpdate */
  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.values == null || // TODO: Controlar error de carga.
      !this.state.month.equals(prevState.month)
    ) {
      this.fetch();
    }
  }

  /** #render */
  public render() {
    const { classes } = this.props;
    const { month } = this.state;
    const { formatMessage, formatYearMonth } = this.props.i18n;

    return (
      <div className={classes.root}>
        <div className={classes.topPane}>
          <IconButton onClick={this.monthBack}>
            <NavigateBeforeIcon />
          </IconButton>
          <Typography>{formatYearMonth(month)}</Typography>
          <IconButton onClick={this.monthNext}>
            <NavigateNextIcon />
          </IconButton>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cell}>{formatMessage("availabilityCalendar.weekDayShort.moday")}</TableCell>
              <TableCell className={classes.cell}>
                {formatMessage("availabilityCalendar.weekDayShort.tuesday")}
              </TableCell>
              <TableCell className={classes.cell}>
                {formatMessage("availabilityCalendar.weekDayShort.wednesday")}
              </TableCell>
              <TableCell className={classes.cell}>
                {formatMessage("availabilityCalendar.weekDayShort.thursday")}
              </TableCell>
              <TableCell className={classes.cell}>
                {formatMessage("availabilityCalendar.weekDayShort.friday")}
              </TableCell>
              <TableCell className={classes.cell}>
                {formatMessage("availabilityCalendar.weekDayShort.saturday")}
              </TableCell>
              <TableCell className={classes.cell}>
                {formatMessage("availabilityCalendar.weekDayShort.sunday")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.buildRows()}</TableBody>
        </Table>
      </div>
    );
  }

  /**
   * Genera la celda para el día indicado.
   *
   * @param day el día a renderizar.
   */
  private buildDayCell = (day: LocalDate) => {
    const { date: currentSelectedDate, classes, values } = this.props;
    const { month } = this.state;

    const today = LocalDate.now();
    const option = values ? values.get(day.toString()) : undefined;
    const inMonth = month.monthValue() === day.monthValue();
    const currentDay = day.equals(today);
    const pastDay = day.isBefore(today);

    const available = option && option.available ? option.available : 0;
    const currentSelection = day.equals(currentSelectedDate);
    const selectable = option != null && (available > 0 || option.freeSale) && !pastDay;
    const handler = selectable ? () => this.selectDate(day) : noop;

    const freeSaleStr = this.props.i18n.formatMessage({ id: "availabilityCalendar.freeSale" });

    return (
      <TableCell
        key={day.toString()}
        className={classNames(classes.cell, {
          [classes.adjacentMonthCell]: !inMonth,
          [classes.currentDayCell]: currentDay,
          [classes.pastDayCell]: pastDay,
        })}
        onClick={handler}
      >
        <Typography
          variant="caption"
          className={classNames(classes.dayOfMonth, {
            [classes.currentDayOfMonth]: currentDay,
            [classes.selectedDayOfMonth]: currentSelection,
          })}
        >
          {day.dayOfMonth()}
        </Typography>
        <Typography
          variant="body1"
          className={classNames(
            { [classes.availability]: option != null },
            { [classes.availabilityNone]: option && available === 0 },
            { [classes.availabilityLow]: option && available > 0 },
            { [classes.availabilityMedium]: option && available > 10 },
            { [classes.availabilityHigh]: option && (option.freeSale || available > 50) }
          )}
        >
          {option && (option.freeSale ? freeSaleStr : option.available)}
        </Typography>
      </TableCell>
    );
  };

  /**
   * Genera los rows de las semanas del calendario.
   */
  private buildRows = () => {
    const { month } = this.state;

    const weeks: React.ReactNode[] = [];

    /*
     * Se empiezan a generar rows desde el primer día de la semana (lunes),
     * porque el mes en curso puede ser que empieze en cualquier otro día.
     */
    let firstDayOfWeek = month.atDay(1).with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY));
    do {
      weeks.push(<TableRow key={firstDayOfWeek.isoWeekOfWeekyear()}>{this.buildWeekCells(firstDayOfWeek)}</TableRow>);
      /* Iteramos hasta que la semana a generar ya no se encuentre en el mes. */
      firstDayOfWeek = firstDayOfWeek.plusWeeks(1);
    } while (firstDayOfWeek.monthValue() === month.monthValue());

    return weeks;
  };

  /**
   * Genera las celdas de la semana indicada.
   *
   * @param firstDayOfWeek el primer día de la semana a generar.
   */
  private buildWeekCells = (firstDayOfWeek: LocalDate) => {
    const cells: React.ReactNode[] = [];

    let day = firstDayOfWeek;
    for (let i = 1; i <= 7; i++) {
      cells.push(this.buildDayCell(day));
      day = day.plusDays(1);
    }

    return cells;
  };

  /** Lanza la carga de disponibilidad para el mes del día de las props. */
  private fetch = () => {
    const { getTicketOptions } = this.props;
    const { month } = this.state;

    if (getTicketOptions) {
      const from = month.atDay(1);
      const to = from.with(TemporalAdjusters.lastDayOfMonth());

      return getTicketOptions(from, to);
    }
  };

  /** Carga el mes anterior del calendario. */
  private monthBack = () => {
    this.setState(state => ({
      month: state.month.minusMonths(1),
    }));
  };

  /** Carga el mes siguiente del calendario. */
  private monthNext = () => {
    this.setState(state => ({
      month: state.month.plusMonths(1),
    }));
  };

  /** Lanza el evento de selección de una fecha. */
  private selectDate = (date: LocalDate) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(date);
    }
  };
}

export default withI18n(withStyles(styles)(AvailabilityCalendar));
