import { Base64 } from "js-base64";

import client, { setReauthenticationHandler } from "./client";
import { AUTH_TOKEN_HAEDER, TOKEN_SEPARATOR } from "./client/config";
import { ApiError, Unauthorized } from "./client/types";
import { AuthToken, AuthUser } from "./types";

/*
 * Establecemos la función que permite al cliente volver a autenticarse si el
 * token ya no es válido.
 */
let cachedPassword: string | null = null;
let cachedUsername: string | null = null;

setReauthenticationHandler(async () => {
  if (cachedUsername && cachedPassword) {
    const user = await authenticate(cachedUsername, cachedPassword);

    if (user != null) {
      return Promise.resolve();
    }
  }

  return Promise.reject();
});

/** Invalida la autenticación del usuario. */
export const deauthenticate = () => {
  cachedPassword = null;
  cachedUsername = null;
};

/**
 * Autentica al usuario obteniendo un token de autenticación.
 *
 * @param username    el nombre de usuario
 * @param password    la contraseña del usuario
 */
export async function authenticate(username: string, password: string): Promise<AuthUser | null> {
  let response;
  try {
    response = await client.get("auth", { auth: { password, username } });
  } catch (error) {
    if (error === Unauthorized || (error.type === ApiError && error.status === 403)) {
      /*
       * Si en esta petición nos da 401 no es por error, es porque las
       * credenciales no son correctas. Si sucede en el login se devuelve null
       * en vez de propagar el error.
       *
       * Consideramos también 403 en el if por si acaso, aunque debería ser 401.
       */
      return null;
    }

    throw error;
  }

  const token = response.headers[AUTH_TOKEN_HAEDER];

  /* Descomposición del token para extraer el objeto de autenticación. */
  if (token != null) {
    const idx = token.indexOf(TOKEN_SEPARATOR);
    if (idx !== -1) {
      const encoded = token.substring(0, idx);

      /* Ciertamente esto es un acto de fe. */
      // TODO: Validación de lo que devolvemos.
      const authUser = (JSON.parse(Base64.decode(encoded)) as AuthToken).user;

      if (authUser) {
        cachedUsername = username;
        cachedPassword = password;
      }

      return authUser;
    } else {
      throw new Error("Invalid token");
    }
  }

  /*
   * Si llegamos aquí no me lo explico. Si no autentica debería ser un 401 y
   * haber fallado la petición axios. Que responda 2xx y sin token es que algo
   * va muy mal.
   */
  throw new Error("Unexpected server response");
}
