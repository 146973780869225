/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import roundTo from "round-to";

import {
  addTicket as apiAddTicket,
  confirm as apiConfirm,
  create as apiCreateBooking,
  removeTicket as apiRemoveTicket,
  updateBooking as apiUpdateBooking,
} from "~/services/webapi/bookings";
import { Agency, Booking, Discount, ExcursionTicket, PriceBreakdown } from "~/services/webapi/types";
import { AppState } from "~/state";

import { BookingProcessState } from "./types";

/*
 * Definición de acciones
 */
export const REFRESH_BOOKING = "Booking/REFRESH_BOOKING";
export const SET_AGENCY = "Booking/SET_AGENCY";
export const SET_BOOKING = "Booking/SET_BOOKING";
export const SET_BOOKING_DISCOUNT = "Booking/SET_BOOKING_DISCOUNT";
export const SET_BOOKING_DISCOUNTS = "Booking/SET_BOOKING_DISCOUNTS";
export const SET_TICKET_DISCOUNT = "Booking/SET_TICKET_DISCOUNT";
export const SET_TICKET_DISCOUNTS = "Booking/SET_TICKET_DISCOUNTS";
export const START_NEW_BOOKING = "Booking/START_NEW_BOOKING";
export const UPDATE_TEMPLATE = "Booking/UPDATE_TEMPLATE";

/**
 * Acción para notificar de la modificación de una reserva. Fuera del proceso
 * de reserva.
 */
interface RefreshBooking extends Action {
  booking: Booking | null;
  type: typeof REFRESH_BOOKING;
}

/** Acción para actualizar la agencia. */
interface SetAgency extends Action {
  agency: Agency;
  type: typeof SET_AGENCY;
}

/** Acción para actualizar la reserva. */
interface SetBooking extends Action {
  booking: Booking | null;
  type: typeof SET_BOOKING;
}

/** Acción para actualizar el descuento establecido a nivel de reserva. */
interface SetBookingDiscount extends Action {
  discount: Discount | null;
  type: typeof SET_BOOKING_DISCOUNT;
}

/** Acción para establecer los descuentos disponibles a nivel de reserva. */
interface SetBookingDiscounts extends Action {
  discounts: Discount[] | null;
  type: typeof SET_BOOKING_DISCOUNTS;
}

/** Acción para actualizar el descuento establecido por ticket. */
interface SetTicketDiscount extends Action {
  discount: BookingProcessState["ticketDiscount"] | null;
  type: typeof SET_TICKET_DISCOUNT;
}

/** Acción para establecer los descuentos disponibles por ticket. */
interface SetTicketDiscounts extends Action {
  discounts: BookingProcessState["ticketDiscounts"] | null;
  type: typeof SET_TICKET_DISCOUNTS;
}

/** Acción para iniciar el proceso de una reserva nueva. */
interface StartNewBooking extends Action {
  type: typeof START_NEW_BOOKING;
}

/** Acción para actualizar la plantilla de reserva. */
interface UpdateTemplate extends Action {
  ticket: Partial<ExcursionTicket>;
  type: typeof UPDATE_TEMPLATE;
}

/** Acciones del módulo. */
export type BookingAction =
  | RefreshBooking
  | SetAgency
  | SetBooking
  | SetBookingDiscount
  | SetBookingDiscounts
  | SetTicketDiscount
  | SetTicketDiscounts
  | StartNewBooking
  | UpdateTemplate;

/*
 * Aciones finales y thunks
 */
const refreshBooking = (booking: Booking | null): RefreshBooking => ({ type: REFRESH_BOOKING, booking });

const setBooking = (booking: Booking | null): SetBooking => ({ type: SET_BOOKING, booking });

const setBookingDiscount = (discount: SetBookingDiscount["discount"]): SetBookingDiscount => ({
  discount,
  type: SET_BOOKING_DISCOUNT,
});

const setBookingDiscounts = (discounts: SetBookingDiscounts["discounts"]): SetBookingDiscounts => ({
  discounts,
  type: SET_BOOKING_DISCOUNTS,
});

const setTicketDiscount = (discount: SetTicketDiscount["discount"]): SetTicketDiscount => ({
  discount,
  type: SET_TICKET_DISCOUNT,
});

const setTicketDiscounts = (discounts: SetTicketDiscounts["discounts"]): SetTicketDiscounts => ({
  discounts,
  type: SET_TICKET_DISCOUNTS,
});

/** Crea la acción para inicializar un nuevo proceso de reserva. */
export const startNewBooking = (): StartNewBooking => ({
  type: START_NEW_BOOKING,
});

/** Crea la acción para establecer la agencia. */
export const setAgency = (agency: Agency): SetAgency => ({
  agency,
  type: SET_AGENCY,
});

const updateTemplate = (ticket: Partial<ExcursionTicket>): UpdateTemplate => ({
  ticket,
  type: UPDATE_TEMPLATE,
});

/**
 * Devuelve una nueva instancia de la reserva aplicando los descuentos
 * seleccionados por el usuario.
 */
// TODO: Añadir un check para indicar si se puede reutilizar la instancia.
const withDiscounts = (booking: Booking, bookingState: BookingProcessState): Booking => {
  const { bookingDiscount, ticketDiscount } = bookingState;

  /*
   * Actualizamos los tickets añadiendo el nuevo descuento establecido. Con ojo
   * de no generar más instancias de las necesarias y no modificar las que
   * recibimos.
   */
  const newBooking = { ...booking };
  newBooking.tickets = booking.tickets.map(ticket => {
    const newPrice: PriceBreakdown = { ...ticket.price };

    const discount = bookingDiscount || (ticketDiscount && ticketDiscount[ticket.ticketNumber]);

    if (discount) {
      newPrice.discount = discount!;
      newPrice.discountAmount = roundTo((ticket.price.basePrice * discount!.percent) / 100, 2);
      newPrice.totalPrice = newPrice.basePrice - newPrice.discountAmount!;
    } else {
      newPrice.discount = undefined;
      newPrice.discountAmount = undefined;
      newPrice.totalPrice = ticket.price.basePrice;
    }

    return { ...ticket, price: newPrice };
  });

  return newBooking;
};

/**
 * Genera la acción para añadir un ticket a la reserva en curso. En caso de no
 * haber reserva, se crea una para añadir el ticket.
 *
 * Si es el primer ticket que se añade, entonces se carga la lista de
 * descuentos. Sino se mantiene, no puede cambiar durante la reserva.
 *
 * En caso de ir bien, actualizará la reserva en el estado.
 *
 * @throws Error si falla la petición.
 */
export const addTicket = (
  ticket: Partial<ExcursionTicket>
): ThunkAction<Promise<Booking | null>, AppState, void, Action> => async (dispatch, getState) => {
  const state = getState();

  const {
    config: { appConfig },
    bookingProcess: { booking, bookingDiscounts },
  } = state;

  let newBooking = null;
  if (booking != null) {
    newBooking = await apiAddTicket(booking.bookingNumber, ticket);
  } else {
    newBooking = await apiCreateBooking(ticket);
  }

  dispatch(setBooking(withDiscounts(newBooking, state.bookingProcess)));
  dispatch(updateTemplate(ticket));

  /*
   * Si los tickets van por reserva, entonces se pone únicamente el primer
   * grupo de descuentos recibido (el del primer ticket) pero sin asociarlo
   * a ningún número de ticket. Se aplicará a todos. (1)
   * Si van por ticket, se guardaran únicamente los nuevos. No se
   * sobreescriben cada vez por si cambian. Una vez aplicado el descuento se
   * debería mantener.
   *
   * (1) - Si todos los tickets no traen los mismos descuentos, entonces puede
   * ser que a un ticket se le aplique un descuento que en teoría no debería
   * tener. Es un agujero funcional que hay. Realmente, para ir bien, no
   * debería haber descuentos "por reserva" y únicamente aplicarlos por
   * ticket.
   */
  const discountPerTicket = appConfig!.bookingConfig.discountPerTicket;

  const availableDiscounts = newBooking.availableDiscounts;
  if (discountPerTicket) {
    /* Descuentos por ticket. Únicamente cargar los nuevos. */
    if (availableDiscounts) {
      const nextDiscounts = availableDiscounts.reduce(
        (accumulator, group) => {
          if (group.ticketNumber && group.discounts && !accumulator[group.ticketNumber]) {
            accumulator[group.ticketNumber] = group.discounts || [];
          }

          return accumulator;
        },
        { ...bookingDiscounts }
      );

      dispatch(setTicketDiscounts(nextDiscounts));
    }
  } else {
    /* Descuentos por reseva. */
    if (bookingDiscounts == null) {
      if (availableDiscounts && availableDiscounts.length >= 1 && availableDiscounts[0]) {
        dispatch(setBookingDiscounts(availableDiscounts[0].discounts || []));
      } else {
        dispatch(setBookingDiscounts([]));
      }
    }
  }
  // TODO: Tratamiento de error.

  return newBooking;
};

/**
 * Anula el ticket de la reserva indicada.
 *
 * @param bookingNumber el número de reserva
 * @param ticketNumber el número de ticket
 */
export const cancelTicket = (
  bookingNumber: string,
  ticketNumber: string
): ThunkAction<Promise<Booking | null>, AppState, void, Action> => async dispatch => {
  const booking = await apiRemoveTicket(bookingNumber, ticketNumber);

  dispatch(refreshBooking(booking));

  return booking;
};

/**
 * Actualiza los datos de la reserva.
 *
 * @param bookingData objeto con la nueva reserva.
 */
export const updateBooking = (
  bookingData: Booking
): ThunkAction<Promise<Booking | null>, AppState, void, Action> => async dispatch => {
  const booking = await apiUpdateBooking(bookingData);

  dispatch(refreshBooking(booking));

  return booking;
};

/**
 * Confirma la reserva indicada.
 *
 * @throws Error si falla la petición.
 */
/*
 * TODO: Cambiar para pasar únicamente los datos de cierre y sacar el resto
 * de la reserva en curso.
 */
export const confirm = (
  booking: Partial<Booking>
): ThunkAction<Promise<Booking | null>, AppState, void, Action> => async dispatch => {
  const newBooking = await apiConfirm(booking);

  dispatch(setBooking(newBooking));
  // TODO: Tratamiento de error.

  return newBooking;
};

/**
 * Elimina el ticket indicado de la reserva en curso.
 *
 * @param ticketNumber el número de ticket
 */
export const removeTicket = (
  ticketNumber: string
): ThunkAction<Promise<Booking | null>, AppState, void, Action> => async (dispatch, getState) => {
  const state = getState();
  const {
    bookingProcess: { booking, ticketDiscount, ticketDiscounts },
  } = state;

  let newBooking = null;
  if (booking != null) {
    newBooking = await apiRemoveTicket(booking.bookingNumber, ticketNumber);
    newBooking = withDiscounts(newBooking, state.bookingProcess);
  } else {
    // TODO: No puede ser.
  }

  dispatch(setBooking(newBooking));

  /*
   * Si los descuentos van por ticket, se elmina también el listado de
   * descuentos asociados, si es que había alguno.
   */
  if (ticketDiscount != null && ticketDiscount[ticketNumber]) {
    dispatch(setTicketDiscount({ ...ticketDiscount, [ticketNumber]: undefined }));
  }
  if (ticketDiscounts != null && ticketDiscounts[ticketNumber]) {
    dispatch(setTicketDiscounts({ ...ticketDiscounts, [ticketNumber]: undefined }));
  }

  // TODO: Tratamiento de error.

  return newBooking;
};

/**
 * Aplica el descuento al ticket indicado o a toda la reserva si no se
 * especifica ninguno.
 *
 * @param discount el descuento a aplicar
 * @param ticketNumber el ticket al que aplicar el descuento
 */
export const setDiscount = (
  discount: Discount | null,
  ticketNumber?: string
): ThunkAction<Promise<Booking>, AppState, void, Action> => async (dispatch, getState) => {
  const {
    bookingProcess: { booking: prevBooking, ticketDiscount },
  } = getState();

  if (ticketNumber) {
    dispatch(setTicketDiscount({ ...ticketDiscount, [ticketNumber]: discount || undefined }));
  } else {
    dispatch(setBookingDiscount(discount));
  }

  if (prevBooking) {
    const booking = withDiscounts(prevBooking, getState().bookingProcess);
    dispatch(setBooking(booking));

    return booking;
  } else {
    throw new Error("Invalid state. Booking cannot be empty");
  }
};
