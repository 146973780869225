import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { Booking } from "~/services/webapi/types";
import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";
import { wrap } from "~/views/ViewWrapper";

import { searchBookings, selectPage } from "./actions";
import BookingsSearchView, { Props } from "./BookingsSearchView";
import { SearchCriteria } from "./components/BookingSearcherForm";

/** Acción de cargar la vista de reserva. */
const openBooking = (booking: Booking) => loadView(ViewId.booking, { booking });

/** Propiedades derivadas del estado. */
type StateProps = Pick<
  Props,
  | "bookings"
  | "contactName"
  | "creationDateFrom"
  | "creationDateTo"
  | "excursionDateFrom"
  | "excursionDateTo"
  | "page"
  | "pageCount"
  | "pageSize"
  | "reference"
  | "totalCount"
>;
const mapStateToProps: MapStateToProps<StateProps, void, AppState> = state => ({
  bookings: state.bookingSearchView.bookings,
  contactName: state.bookingSearchView.contactName,
  creationDateFrom: state.bookingSearchView.creationFrom,
  creationDateTo: state.bookingSearchView.creationTo,
  page: state.bookingSearchView.page,
  pageCount: state.bookingSearchView.pageCount,
  pageSize: state.bookingSearchView.pageSize,
  reference: state.bookingSearchView.reference,
  sellerCode: state.bookingSearchView.sellerCode,
  totalCount: state.bookingSearchView.totalCount,
});

/** Función para crear la acción de lanzar la búsqueda. */
const searchAction = (criteria: SearchCriteria) => {
  const {
    contactName,
    creationDateFrom,
    creationDateTo,
    excursionDateFrom,
    excursionDateTo,
    reference,
    sellerCode,
  } = criteria;

  return searchBookings(
    reference,
    sellerCode,
    contactName,
    creationDateFrom,
    creationDateTo,
    excursionDateFrom,
    excursionDateTo
  );
};

/** Propiedades a partir de acciones. */
type DispatchProps = Pick<Props, "onSearch" | "onSelect" | "onSelectPage">;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, void> = {
  onSearch: searchAction,
  onSelect: openBooking,
  onSelectPage: selectPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(wrap(BookingsSearchView));
