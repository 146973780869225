import React from "react";

import { withStyles } from "@material-ui/core/styles";

import { CodeName } from "~/services/webapi/types";
import CodeNameSelector, { Props as CodeNameSelectorProps } from "./CodeNameSelector";

interface Props extends CodeNameSelectorProps {
  /** Función filtro */
  filterFunction: (element: CodeName) => boolean;
}

interface State {
  /**
   * Instancia de la función filtro para comprobar si nos la cambian por
   * parámetro
   */
  filterFunction?: (element: CodeName) => boolean;
  /** Valores filtrados */
  values?: CodeName[];
}

/**
 * Selector de CodeName con función de filtrado de la lista de valores externo
 */
class FilteredCodeNameSelector extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { ...FilteredCodeNameSelector.getDerivedStateFromProps(props, {}) };
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
    if (nextProps.filterFunction !== prevState.filterFunction || nextProps.values !== prevState.values) {
      let values: CodeName[] | undefined;
      if (nextProps.filterFunction && nextProps.values) {
        values = nextProps.values.filter(nextProps.filterFunction);
      } else {
        values = nextProps.values;
      }

      return {
        filterFunction: nextProps.filterFunction,
        values,
      };
    }

    return null;
  }

  public render() {
    const { filterFunction, values, ...childProps } = this.props;

    return <CodeNameSelector values={this.state.values} {...childProps} />;
  }
}

export default withStyles({})(FilteredCodeNameSelector);
