import React from "react";

import TextField from "@material-ui/core/TextField";

import LabeledText, { LabeledTextProps } from "../LabeledText";

export interface EditableLabeledTextProps extends LabeledTextProps {
  /** Indica si se muestra en modo edición. */
  editionEnabled?: boolean;

  /** Si expandir todo el espacio disponible en modo edición. */
  fullWidth?: boolean;

  /** Nombre del campo. */
  name?: string;

  /** Invocado cuando se modifica el valor del campo. */
  onChange: (value: string, name: string | undefined) => void;
}

/**
 * Componente para renderizar un texto con una LabeledText o un TextField en
 * función de si está activado que se pueda editar o no. Es un LabeledText
 * editable.
 */
const EditableLabeledText: React.FC<EditableLabeledTextProps> = props => {
  const { editionEnabled, fullWidth, name, onChange: onChangeProp, ...labeledTextProps } = props;

  if (editionEnabled) {
    const { label, text: value = "" } = labeledTextProps;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      onChangeProp(event.target.value, event.target.name);

    return <TextField label={label} name={name} value={value} fullWidth={fullWidth} onChange={onChange} />;
  } else {
    return <LabeledText {...labeledTextProps} />;
  }
};

export default EditableLabeledText;
