/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import React from "react";

import { LocalDate, LocalTime } from "js-joda";
import moize from "moize";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";

import BottomButtonsPane, { TwoButtonsBottomPane as ButtonsPane } from "~/components/BottomButtonsPane";
import CalendarDatePicker from "~/components/CalendarDatePicker";
import CancelConditionsViewer from "~/components/CancelConditionsViewer";
import CodeNameSelector from "~/components/CodeNameSelector";
import DistributionTable from "~/components/DistributionTable";
import { paxQuotaToDistribution } from "~/components/DistributionTable/types";
import LabeledText from "~/components/LabeledText";
import { TicketTemplate } from "~/services/booking/types";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import {
  CodeName,
  ExcursionTicket,
  ExcursionTicketOption,
  Hotel,
  PaxQuota,
  PickupPoint,
} from "~/services/webapi/types";
import { RecursivePartial } from "~/utils";

import AvailabilityCalendar, { AvailabilityCalendarProps } from "./components/AvailabilityCalendar";
import ExcursionNotFound from "./components/ExcursionNotFound";

import Clipboard from "~/icons/ClipboardArrowDown";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    dateAndCancelPolicyPanel: {
      alignItems: "center",
      display: "flex",
      flexGrow: 0,
      flexShrink: 0,
      justifyContent: "space-between",
    },
    fill: {
      flexGrow: 1,
    },
    fixedSize: {
      flexGrow: 0,
      flexShrink: 0,
    },
    noAvail: {
      "& > :first-child": {
        marginRight: theme.spacing(1),
      },
      display: "flex",
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
    },
  });

/** Propiedades de entrada que acepta la vista cuando se carga. */
export interface PayloadProps {
  excursionCode: string;
  modalityCode: string;
}

/**
 * Propiedades del componente. Tanto las que vienen directas como las derivadas.
 */
export interface Props extends PayloadProps, WithStyles<typeof styles> {
  /**
   * La opción del ticket seleccionado.
   * Si no existe la opción, entonces vendrá vacío.
   * Si existe pero no se puede vener ese día, vendrá sin avaiablitiy.
   */
  excursionTicketOption?: ExcursionTicketOption;

  /** Función para buscar el primer día disponible. */
  findFirstAvailableTicketOption: () => void;

  /** Función para recuperar la disponibilidad de un día en concreto. */
  getTicketOption: (date: LocalDate) => void;

  /** Función para recuperar la disponibilidad entre fechas. */
  getTicketOptions: (from: LocalDate, to: LocalDate) => void;

  /** Disponibilida del mes en curso. */
  monthAvailability?: AvailabilityCalendarProps["values"];

  /** Función invocada para volver al buscador */
  onBackToSearcher: () => void;

  /**
   * Función invocada cuando se pulsa sobre el botón para realizar la reserva.
   */
  onReservation: (ticket: RecursivePartial<ExcursionTicket>) => void;

  /**
   * Indica que las acciones de inicialización de la vista ya se han ejecutado.
   */
  ready: boolean;

  /** Plantilla para copiar los datos del ticket. */
  ticketTemplate?: TicketTemplate;
}

/** Estado del componente. */
interface State {
  /** Indica si el calendario de disponibilidad está abierto. */
  calendarOpen: boolean;

  /** El código de hotel seleccionado. */
  hotelCode?: string;

  /** La referencia del hotel introducida. */
  hotelReference?: string;

  /** La zona del hotel seleccionada. */
  hotelZoneCode?: string;

  /** El idioma seleccionado. */
  languageCode?: string;

  /** La selección de paxes. */
  paxQuotas: PaxQuota[];

  /** El pickup seleccionado. */
  pickUpPointCode?: string;

  /** Las observaciones introducidas. */
  remarks?: string;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/*
 * Esto corrige que al poner el atributo fullWidth el paper sige manteniendo el
 * margin, mientras que no lo hace si usamos fullScreen.
 */
// TODO: Verificar si es un error de Material o si hay que pasar clases. Abrir issue en github.
const paperFix = { style: { margin: 8 } };

/**
 * Pantalla para añadir una excursión - modalidad.
 */
class AddExcursionView extends React.PureComponent<ProvidedProps, State> {
  /** Función para transformar de paxQuota a distribution. */
  /*
   * TODO: Ahora aí porque simplemente sumamos precios.
   * Si cambia la valoración con descuentos y otras cosas, habrá que pasarlo a
   * las funciones de reserva y tener otra estructura.
   */
  private asDistribution = moize.simple(paxQuotaToDistribution);

  /** Filtro con memoria para recupera los hoteles seleccionables. */
  private hotelsFilter = moize.simple((hasPickUpService?: boolean, hotelZoneCode?: string, hotels?: Hotel[]) => {
    if (hasPickUpService && hotelZoneCode && hotels) {
      return hotels.filter(hotel => hotel.zoneCode === hotelZoneCode);
    }

    /* Si no se ha podido devolver nada se quedará vacío. */
    return [];
  });

  /** Filtro con memoria para recupera los puntos de recogida. */
  private pickUpPointsFilter = moize.simple(
    (hasPickUpService?: boolean, hotels?: Hotel[], hotelCode?: string, pickUpPoints?: PickupPoint[]) => {
      if (hasPickUpService && hotels && hotelCode && pickUpPoints) {
        const hotel = hotels!.find(h => h.code === hotelCode);

        /*
         * Siempre debería existir porque no puede ser que haya seleccionado en
         * el combo un hotel y que no exista. Pero no cuesta nada controlarlo.
         * Y de paso ya controlamos también que tenga pickups. No queremos
         * falle por errores de carga.
         */
        if (hotel && hotel.pickUpPoints) {
          return hotel.pickUpPoints.map(point => ({
            ...point,
            name: point.name + (point.time ? ` - ${this.props.i18n.formatLocalTime(LocalTime.parse(point.time))}` : ""),
          }));
        }
      }

      /* Si no se ha podido devolver nada se quedará vacío. */
      return [];
    }
  );

  /** #constructor */
  public constructor(props: ProvidedProps) {
    super(props);
    this.state = {
      calendarOpen: false,
      paxQuotas: [],
    };
  }

  /**
   * #didMount
   *
   * Una vez se ha montado el componente se debe lanzar la búsqueda para tener
   * la inormación del primer día disponible para el ticket.
   * NOTA: Esto cambiará y se llevará a acciones.
   */
  public componentDidMount() {
    /*
     * TODO: Esto volará a acciones y no hará falta lanzar la carga aquí.
     * Entonces se deberá comprobar que esté o no ready.
     */
    this.props.findFirstAvailableTicketOption();
  }

  /**
   * #didlUpdate
   *
   * Una vez se recarga la información del ticket, entonces hay que actualizar
   * el componente. Al mismo tiempo, se aprovecha para preseleccionar algunos
   * elementos.
   */
  public componentDidUpdate(prevProps: Props) {
    /*
     * Hay que ir con ojo de actualizar únciamente cuando se ha cargado la
     * excursión por primera vez o si ha cambiado el día de la misma.
     */
    /*
     * Ambos casos se pueden detectar controlando que la instancia de
     * excursionTicketOption ha cambiado. No debería llegar otra instancia si no
     * es el caso. Y mucho menos, llegar la misma instancia con valores
     * cambiados.
     */
    const { excursionTicketOption } = this.props;

    if (excursionTicketOption !== prevProps.excursionTicketOption) {
      if (excursionTicketOption != null) {
        const { languages, hotelZones, paxTypes } = excursionTicketOption;
        /* Hay que inicializar los paxQuota con los paxType soportados. */
        if (paxTypes) {
          const paxQuotas = paxTypes.map(paxType => ({
            /*
             * No se crea una instancia nueva de paxType porque nadie debería
             * cambiar la que viene.
             */
            paxType,
            units: 0,
          }));

          this.setState({
            paxQuotas,
          });
        }

        /*
         * Si hay ticket se mira si hay valores que se puedan auto seleccionar.
         * Son combos con una única opción.
         */
        if (languages && languages.length === 1) {
          this.changeLanguage(languages[0].code);
        }

        if (hotelZones && hotelZones.length === 1) {
          /*
           * Basta con mirar la zona de hotel. La selección de hotel y pickup
           * ya se hace en cascada en los handlers de cada combo en caso de
           * únicamente tengan una opción tras seleccionar la zona.
           */
          this.changeHotelZone(hotelZones[0].code);
        }
      }
    }
  }

  /**
   * #render
   */
  public render() {
    const { excursionCode, modalityCode, excursionTicketOption, ready } = this.props;

    const { formatMessage } = this.props.i18n;

    /* Los casos excepcionales se renderizan aparte. */
    if (!ready) {
      /*
       * Está cargando. No debería llegar a este estado, pues se debería cambiar
       * a la vista una vez realizada la carga. En cualquier caso, se control
       * para que no falle y se deja la vista en blanco.
       */
      return null;
    } else if (excursionTicketOption == null) {
      /*
       * La excursión no existe. No es que no exista disponibilidad, es que no
       * se encuentra ni la excursión. Esto sería un caso de error si se escanea
       * un QR con formato válido pero datos que no son correctos.
       */
      return <ExcursionNotFound {...{ excursionCode, modalityCode }} />;
    }

    /*
     * Render del caso normal, tenemos excursión (con o sin dispo). Pero la
     * tenemos.
     */
    const {
      calendarOpen,
      languageCode,
      hotelCode,
      hotelReference,
      hotelZoneCode,
      paxQuotas,
      pickUpPointCode,
      remarks,
    } = this.state;

    const { classes, monthAvailability, ticketTemplate } = this.props;

    const {
      excursionName,
      modalityName,
      cancelConditions,
      hotels,
      hotelZones,
      languages,
      paxMax,
      paxMin,
      pickUpPoints,
      pickUpService: hasPickUpService,
    } = excursionTicketOption;

    const available = excursionTicketOption.freeSale || excursionTicketOption.available! > 0;

    /*
     * Si no hay dispo, no hay fecha. Así que se pone la de hoy para inicializar
     * el calendaro.
     */
    const date = excursionTicketOption.date ? LocalDate.parse(excursionTicketOption.date) : LocalDate.now();

    /*
     * Se precalculan los listados que van a alimentar los combos. Todos los
     * filtros llevan memoria para evitar crear listas nuevas cuando cambian
     * valores no asociados. El caso espcial son las zonas que se pueden
     * seleccionar, es el combo padre y por lo tanto siempre son toedas.
     */
    const selectableHotelsZones = hotelZones;
    const selectableHotels = this.hotelsFilter(hasPickUpService, hotelZoneCode, hotels);
    const selectablePickUpPoints = this.pickUpPointsFilter(hasPickUpService, hotels, hotelCode, pickUpPoints);

    const distribution = this.asDistribution(paxQuotas);
    const paxTotal = distribution.totalPaxCount;

    const addButtonEnabled = Boolean(
      languageCode &&
        paxTotal > 0 &&
        (paxMax == null || paxMax >= paxTotal) &&
        (paxMin == null || paxMin <= paxTotal) &&
        (!hasPickUpService || (pickUpPointCode && hotelCode))
    );

    const copyButtonEnabled = ticketTemplate != null;

    return (
      <div className={classes.root}>
        <LabeledText
          className={classes.fixedSize}
          label={formatMessage("addExcursionView.excursion")}
          text={excursionName}
          textSize="extraLarge"
        />
        <LabeledText
          className={classes.fixedSize}
          label={formatMessage("addExcursionView.modality")}
          text={modalityName}
          textSize="large"
        />
        <div className={classes.dateAndCancelPolicyPanel}>
          <CalendarDatePicker
            label={formatMessage("addExcursionView.calendar.label")}
            date={date}
            onOpen={this.calendarOpen}
          />

          <Dialog onClose={this.calendarClose} open={calendarOpen} PaperProps={paperFix}>
            <AvailabilityCalendar
              date={date}
              onSelect={this.changeSelectedDate}
              getTicketOptions={this.props.getTicketOptions}
              values={monthAvailability}
            />
          </Dialog>
          {copyButtonEnabled && (
            <Button startIcon={<Clipboard color="action" />} onClick={this.copyTemplate}>
              {formatMessage("addExcursionView.copyTemplate")}
            </Button>
          )}
        </div>
        {available && (
          <>
            <DistributionTable
              distribution={distribution}
              onPaxQuotaChange={this.changePaxQuota}
              paxMin={paxMin}
              paxMax={paxMax}
            />

            {cancelConditions && <CancelConditionsViewer cancelConditions={cancelConditions} />}

            <CodeNameSelector
              fullWidth
              label={formatMessage("addExcursionView.language")}
              margin="dense"
              onValueChange={this.changeLanguage}
              required
              selectedCode={languageCode}
              values={languages}
            />

            {hasPickUpService && (
              <>
                <CodeNameSelector
                  fullWidth
                  label={formatMessage("addExcursionView.hotelZone")}
                  onValueChange={this.changeHotelZone}
                  required
                  selectedCode={hotelZoneCode}
                  values={selectableHotelsZones}
                  margin="dense"
                />

                <CodeNameSelector
                  fullWidth
                  label={formatMessage("addExcursionView.hotel")}
                  onValueChange={this.changeHotel}
                  required
                  selectedCode={hotelCode}
                  values={selectableHotels}
                  margin="dense"
                />

                <TextField
                  fullWidth
                  label={formatMessage("addExcursionView.hotelReference")}
                  margin="dense"
                  onChange={this.changeHotelReference}
                  value={hotelReference || ""}
                  disabled={hotelCode == null}
                />

                <CodeNameSelector
                  fullWidth
                  label={formatMessage("addExcursionView.pickUpPoint")}
                  margin="dense"
                  onValueChange={this.changePickUp}
                  required
                  selectedCode={pickUpPointCode}
                  values={selectablePickUpPoints}
                />
              </>
            )}

            <TextField
              fullWidth
              label={formatMessage("addExcursionView.remarks")}
              margin="dense"
              multiline
              onChange={this.changeRemarks}
              value={remarks || ""}
            />

            <ButtonsPane
              backLabel={formatMessage("addExcursionView.back")}
              nextEnabled={addButtonEnabled}
              nextLabel={formatMessage("addExcursionView.book")}
              onBack={this.props.onBackToSearcher}
              onNext={this.addTicket}
            />
          </>
        )}
        {!available && (
          <>
            <div className={classes.noAvail}>
              <InfoIcon color="action" />
              <Typography>{formatMessage("addExcursionView.withoutAvailability")}</Typography>
            </div>
            <BottomButtonsPane>
              <Button variant="outlined" color="default" onClick={this.props.onBackToSearcher}>
                {formatMessage("addExcursionView.back")}
              </Button>
            </BottomButtonsPane>
          </>
        )}
      </div>
    );
  }

  /**
   * Añade el ticket seleccionad a la reserva con los datos introducidos en el
   * formulario.
   */
  private addTicket = () => {
    const excursionTicket = this.createExcursionTicket();
    if (excursionTicket) {
      this.props.onReservation(excursionTicket);
    }
  };

  /**
   * Función para cerrar el calenario cuando el usuario pulsa sobre el selector
   * de fecha.
   */
  private calendarClose = () => {
    this.setState({ calendarOpen: false });
  };

  /**
   * Función para abrir el calenario cuando el usuario pulsa sobre el selector
   * de fecha.
   */
  private calendarOpen = () => {
    this.setState({ calendarOpen: true });
  };

  /** Handler para guardar en estado los valores introducidos en los inputs. */
  private changeHotel = (value?: string) => {
    const hotelCode = this.state.hotelCode;

    /* Únicamente se propaga el cambio si se selecciona otro valor. */
    if (hotelCode !== value) {
      this.setState({
        hotelCode: value || "",
      });

      let pickUpCode;
      const hotels = this.props.excursionTicketOption!.hotels;
      const hotel = hotels != null && hotels.find(h => h.code === value);

      /*
       * Si tenemos hotel seleccionado se mira si se puede encontrar un pickup
       * para seleccionarlo también automáticamente.
       */
      if (value && hotel && hotel.pickUpPoints && hotel.pickUpPoints.length === 1) {
        /*
         * TODO: Ojo que aquí se supone que un pickUpPoint no vendrá repetido
         * para un hotel. Nunca debería darse el caso, pero sabemos que el
         * modelo puede permitirlo y que DMT no lo contorla.
         */
        pickUpCode = hotel.pickUpPoints[0].code;
      }

      this.changePickUp(pickUpCode);
    }
  };

  /** Actualizar el cambio en el input de referencia del hotel. */
  private changeHotelReference: React.ChangeEventHandler<HTMLInputElement> = event => {
    const { value } = event.currentTarget;

    this.setState({
      hotelReference: value || "",
    });
  };

  /** Handler para guardar en el estado la zona de hotel seleccionada. */
  private changeHotelZone = (value?: string) => {
    const hotelZoneCode = this.state.hotelZoneCode;

    /*
     * Si el valor seleccionado es el mismo, entonces no hace falta actualizar.
     * Aquí se controla porque se hacen actualizaciones en cascada.
     */
    if (hotelZoneCode !== value) {
      this.setState({
        hotelZoneCode: value || "",
      });

      let hotelCode;
      const hotels = this.props.excursionTicketOption!.hotels;

      /*
       * Si nos han seleccionado un valor y el ticket tiene hoteles, entonces se
       * mira la lista de hoteles de la zona para autoseleccionar el hotel en
       * caso de que solo exista una opción.
       */
      if (value && hotels) {
        const zoneHotels = hotels.filter(h => h.zoneCode === value);
        if (zoneHotels.length === 1) {
          hotelCode = zoneHotels[0].code;
        }
      }

      /*
       * Se propaga el cambio al combo de hotel, con opción por defecto o sin
       * ella.
       */
      this.changeHotel(hotelCode);
    }
  };

  /** Handler para guardar en estado los valores introducidos en los inputs. */
  private changeLanguage = (value?: string) => {
    this.setState({
      languageCode: value || "",
    });
  };

  /** Handler para guardar las modificación de la selección de paxes. */
  private changePaxQuota = (paxTypeCode: string, units: number) => {
    this.setState({
      /*
       * Hay que crear una instancia nueva del array, de todo el array. No
       * modificar únicamente el elemento afectado.
       */
      paxQuotas: this.state.paxQuotas.map(paxQuota => {
        if (paxTypeCode === paxQuota.paxType.code) {
          return {
            paxType: paxQuota.paxType,
            units,
          };
        } else {
          /*
           * Los elementos no modificados sí pueden (y deben) seguir siendo la
           * misma instancia.
           */
          return paxQuota;
        }
      }),
    });
  };

  /** Handler para guardar en estado los valores introducidos en los inputs. */
  private changePickUp = (value?: string) => {
    this.setState({
      pickUpPointCode: value || "",
    });
  };

  /** Actualizar el cambio de observaciones. */
  private changeRemarks = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    this.setState({
      remarks: value || "",
    });
  };

  /**
   * Función para tratar cuando el usuario cambia de fecha seleccionada.
   */
  private changeSelectedDate = (date: LocalDate) => {
    this.calendarClose();
    this.props.getTicketOption(date);
  };

  /**
   * Función para rellenar el ticket a partir de los datos de la plantilla.
   */
  /*
   * TODO: Pedir confirmación antes de copiar si ya se han introducido datos.
   * Para ello hay que hacer antes el hook de confirmación. Sino es enmerdarlo
   * más aún... y ya da miedo.
   */
  private copyTemplate = () => {
    const template = this.props.ticketTemplate;
    if (template != null) {
      const { languageCode, hotelZoneCode, hotelCode, pickupPointCode, hotelReference, paxes, remarks } = template;

      const newState: State = {
        calendarOpen: this.state.calendarOpen,
        hotelCode: undefined,
        hotelReference: undefined,
        hotelZoneCode: undefined,
        languageCode: undefined,
        paxQuotas: [],
        pickUpPointCode: undefined,
        remarks,
      };

      const quotas: { [k: string]: number } = {};

      if (paxes) {
        paxes.forEach(pq => (quotas[pq.paxType.code] = pq.units));
      }

      newState.paxQuotas = this.state.paxQuotas.map(paxQuota => ({
        ...paxQuota,
        units: quotas[paxQuota.paxType.code] || 0,
      }));

      const languages = this.props.excursionTicketOption!.languages;
      if (languages && languageCode && this.isValid(languageCode, languages)) {
        newState.languageCode = languageCode;
      }

      if (this.props.excursionTicketOption!.pickUpService) {
        const hotelZones = this.props.excursionTicketOption!.hotelZones;
        if (hotelZones && hotelZoneCode && this.isValid(hotelZoneCode, hotelZones)) {
          newState.hotelZoneCode = hotelZoneCode;

          const hotels = this.props.excursionTicketOption!.hotels;
          if (hotels) {
            const zoneHotels = hotels.filter(h => h.zoneCode === hotelZoneCode);

            if (zoneHotels && hotelCode && this.isValid(hotelCode, zoneHotels)) {
              newState.hotelCode = hotelCode;
              newState.hotelReference = hotelReference;

              const hotel = zoneHotels.find(h => h.code === hotelCode);
              if (hotel && hotel.pickUpPoints && pickupPointCode && this.isValid(pickupPointCode, hotel.pickUpPoints)) {
                newState.pickUpPointCode = pickupPointCode;
              }
            }
          }
        }
      }

      this.setState(newState);
    }
  };

  /**
   * Crea el objeto Ticket a reservar. Únicamnte se deben informar los campos
   * necesarios para añadir el ticket a la reserva.
   */
  private createExcursionTicket = (): RecursivePartial<ExcursionTicket> | null => {
    const { excursionTicketOption } = this.props;

    const { hotelCode, hotelReference, hotelZoneCode, languageCode, paxQuotas, pickUpPointCode, remarks } = this.state;

    const { agencyCode, date, excursionCode, modalityCode } = excursionTicketOption!;

    return {
      agencyCode,
      excursionCode,
      excursionDate: date,
      hotel: { code: hotelCode, zoneCode: hotelZoneCode },
      hotelReference,
      language: { code: languageCode },
      modalityCode,
      /*
       * Se mapea para retornar únicamente los códigos. Cierto que el servidor
       * debería ignorar los datos que sobran si se envían... pero quedará un
       * poco más limpio así.
       */
      paxes: paxQuotas!.map(p => ({
        paxType: { code: p.paxType.code },
        units: p.units,
      })),
      pickupPoint: { code: pickUpPointCode },
      remarks,
    };
  };

  /** Comprueba si el valor es válido para la lista indicada. */
  private isValid = (value: string, options: CodeName[]) => options.findIndex(({ code }) => value === code) !== -1;
}

export default withI18n(withStyles(styles)(AddExcursionView));
