import React from "react";

import WaitingLayer from "~/components/WaitingLayer";

import AppViewController, { AppViewDef, ViewId } from "~/views";
import AddExcurionsView from "~/views/AddExcursionView";
import BookingSearchView from "~/views/BookingSearchView";
import BookingView from "~/views/BookingView";
import ConfirmBookingView from "~/views/ConfirmBookingView";
import ErrorView from "~/views/ErrorView";
import LoginView from "~/views/LoginView";
import QRScannerView from "~/views/QRScannerView";
import SearcherView from "~/views/SearcherView";

/** Componente principal. */
export default class App extends React.PureComponent {
  /** #render */
  public render() {
    return (
      <>
        {/* Aunque hay un  defaultView, realmente no se utilizará porque el intento de autologin ya pone uno. */}
        <AppViewController defaultView={ViewId.login}>
          <AppViewDef view={ViewId.login} component={LoginView} />
          <AppViewDef view={ViewId.addExcursion} component={AddExcurionsView} />
          <AppViewDef view={ViewId.searcher} component={SearcherView} />
          <AppViewDef view={ViewId.booking} component={BookingView} />
          <AppViewDef view={ViewId.confirmBooking} component={ConfirmBookingView} />
          <AppViewDef view={ViewId.bookingSearch} component={BookingSearchView} />
          <AppViewDef view={ViewId.qrScann} component={QRScannerView} />

          <AppViewDef view={ViewId.error} component={ErrorView} />
        </AppViewController>
        <WaitingLayer />
      </>
    );
  }
}
