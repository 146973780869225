/* Etiquetas multiidoma en castellano. */
export default {
  "errorView.close": "Cerrar",
  "errorView.error.applicationError": "Error interno de la aplicación.",
  "errorView.error.connectionTimeout": "Tiempo de espera superado.",
  "errorView.error.hostUnreachable": "No se ha podido establecer conexión con el servidor.",
  "errorView.error.serverError": "Error interno del servidor.",
  "errorView.errorTitle": "Error",
  "errorView.showDetails": "Ver detalles",
};
