import React from "react";

import Button from "@material-ui/core/Button";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import AlertDialog from "~/components/AlertDialog";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { noop } from "~/utils";

/** Estilos por defecto. */
const styles = () =>
  createStyles({
    button: {
      marginTop: 40,
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    root: {
      alignItems: "center",
      alignSelf: "center",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    textField: {
      marginTop: 20,
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** Indica si se ha producido un login erróneo anteriormente. */
  hasLoginError?: boolean;

  /** Función invocada para descartar el error de login. */
  onDismissError?: () => void;

  /**
   * Función invocada cuando se solicita hacer la autenticación.
   *
   * @param username    el nombre de usuario.
   * @param password    la contraseña del usuario.
   */
  onLogin?: (username: string, password: string) => void;
}

/** Estado interno del componente. */
interface State {
  /** El nombre de usuario. */
  password: string;

  /** La contraseña del usuario. */
  username: string;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Pantalla de login.
 */
class LoginView extends React.PureComponent<ProvidedProps, State> {
  /** Constructor */
  public constructor(props: ProvidedProps) {
    super(props);

    this.state = { password: "", username: "" };
  }

  /** # render */
  public render() {
    const { classes, hasLoginError, onDismissError: dismissError } = this.props;
    const { password, username } = this.state;

    const loginBtnDisabled = password.length === 0 || username.length === 0;

    const { formatMessage } = this.props.i18n;

    return (
      <div className={classes.root}>
        <Typography variant="h5">{formatMessage("loginView.title")}</Typography>
        <form className={classes.form} name="login" onSubmit={this.handleSubmit}>
          <TextField
            label={formatMessage("loginView.username")}
            name="tpvusername"
            onChange={this.handleChangeInputText}
            value={username}
            className={classes.textField}
            autoComplete="username"
          />
          <TextField
            label={formatMessage("loginView.password")}
            name="tpvpassword"
            onChange={this.handleChangeInputText}
            type="password"
            value={password}
            className={classes.textField}
            autoComplete="current-password"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loginBtnDisabled}
            className={classes.button}
          >
            {formatMessage("loginView.submit")}
          </Button>
        </form>
        <AlertDialog
          onClose={dismissError || noop}
          open={!!hasLoginError}
          title="Error"
          text={formatMessage("loginView.error")}
        />
      </div>
    );
  }

  /**
   * Handler para tratar la introducción de datos del usuario en el formulario.
   */
  private handleChangeInputText: React.ChangeEventHandler<HTMLInputElement> = event => {
    const { name, value } = event.currentTarget;

    if (name === "tpvusername") {
      this.setState({ username: value || "" });
    } else if (name === "tpvpassword") {
      this.setState({ password: value || "" });
    }
  };

  /** Handler para tratar el envío del formulario. */
  private handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    event.preventDefault();

    const { username, password } = this.state;
    const { onLogin } = this.props;

    if (onLogin) {
      onLogin(username, password);
    }
  };
}

export default withI18n(withStyles(styles)(LoginView));
