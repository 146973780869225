import React from "react";

import ViewWrapper from "./index";

function wrap<P>(component: React.ComponentType<P>): React.ComponentType<P> {
  class Wrapped extends React.PureComponent<P> {
    public render() {
      const Component = component;

      return (
        <ViewWrapper>
          <Component {...this.props} />
        </ViewWrapper>
      );
    }
  }

  return Wrapped;
}

export default wrap;
