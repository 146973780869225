/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import LabeledText from "~/components/LabeledText";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Booking } from "~/services/webapi/types";

const useStyles = makeStyles(() =>
  createStyles({
    row: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface BookingHeaderProps {
  booking: Booking;
}

/**
 * Información principal de la reserva.
 */
const BookingHeader: React.FC<BookingHeaderProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatLocalDateString, formatMessage },
    booking: { bookingNumber, creationDate, status },
  } = props;

  const classes = useStyles();

  const statusText = formatMessage("bookingView.bookingStatus", {
    status: formatMessage(`bookingView.bookingStatus.${status}`),
  });

  return (
    <div>
      <LabeledText text={statusText} textSize="extraLarge" />

      <div className={classes.row}>
        <LabeledText label={formatMessage("bookingView.bookingNumber")} text={bookingNumber} textSize="large" />

        <LabeledText
          label={formatMessage("bookingView.creationDate")}
          text={formatLocalDateString(creationDate)}
          textSize="large"
        />
      </div>
    </div>
  );
};

export default withI18n(BookingHeader);
