/*
 * Servicio que gestiona todo el proceso de reserva. Desde la disponibilidad
 * hasta el cierre.
 *
 * En este módulo se guardará todo el estado realtivo al proceso de reserva, que
 * será consultado por distintos componentes.
 *
 * La mayoría se delega a webapi, pero se pasa por aquí para poderlo tener
 * preparado para el offline.
 */
export * from "./availability";
export * from "./booking";
export { default as reducer } from "./reducer";

import { BookingProcessState as TBookingProcessState } from "./types";
export type BookingProcessState = TBookingProcessState;
