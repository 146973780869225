import React from "react";

// import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

import { LocalDate } from "js-joda";
import CustomDatePicker from "~/components/CustomDatePicker";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

/** Estilos por defecto. */
const styles = () =>
  createStyles({
    input: {
      flex: "1 0 auto",
    },
    root: {
      display: "flex",
    },
    row: {
      alignItems: "baseline",
      display: "flex",
      justifyContent: "space-between",
    },
    separator: {
      flex: "1 0 auto",
      minWidth: 25,
      textAlign: "center",
    },
  });

/** Las propiedades del componente. */
interface Props extends WithStyles<typeof styles> {
  /** La fecha desde. */
  dateFromValue?: LocalDate;

  /** La fecha hasta. */
  dateToValue?: LocalDate;

  /** El nombre del campo. */
  label?: string;

  /** Handler para tratar cuando cambia el valor. */
  onChange?: (dateFrom?: LocalDate, dateTo?: LocalDate) => void;
}

interface ProviedProps extends Props, InjectedI18nProps {}

/**
 * Componente para seleccionar un rango de fechas.
 * Agrupa los inputs para la feche desde y hasta.
 * También controla que no se introduzca una fecha hasta menor que la mínima.
 */
class DateRangePicker extends React.PureComponent<ProviedProps> {
  /** #constructor */
  public constructor(props: ProviedProps) {
    super(props);
  }

  /** #render */
  public render() {
    const { classes, dateFromValue, dateToValue, label } = this.props;

    const { formatMessage } = this.props.i18n;

    return (
      <FormControl component="div" className={classes.root} margin="normal">
        {label && <FormLabel component="span">{label}</FormLabel>}

        <div className={classes.row}>
          <CustomDatePicker
            date={dateFromValue}
            label={formatMessage("dateRangePicker.dateFrom.label")}
            onChange={newDate => {
              this.handleDateChange("dateFrom", newDate);
            }}
          />
          <div className={classes.separator}>-</div>
          <CustomDatePicker
            date={dateToValue}
            label={formatMessage("dateRangePicker.dateTo.label")}
            onChange={newDate => {
              this.handleDateChange("dateTo", newDate);
            }}
            minDate={dateFromValue}
          />
        </div>
      </FormControl>
    );
  }

  private handleDateChange = (name: string, value: LocalDate | string | undefined) => {
    const { onChange } = this.props;
    let { dateFromValue, dateToValue } = this.props;

    if (onChange != null) {
      if (name === "dateFrom") {
        dateFromValue = value ? (typeof value === "string" ? LocalDate.parse(value) : value) : undefined;
      } else if (name === "dateTo") {
        dateToValue = value ? (typeof value === "string" ? LocalDate.parse(value) : value) : undefined;
      }

      /* Se valida que la fecha hasta no sea anterior a la fecha desde. */
      if (dateFromValue && dateToValue && !dateFromValue.isBefore(dateToValue)) {
        dateToValue = dateFromValue;
      }

      onChange(dateFromValue, dateToValue);
    }
  };
}

export default withI18n(withStyles(styles)(DateRangePicker));
