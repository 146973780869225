import React from "react";

import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

import { Booking } from "~/services/webapi/types";

import BookingItem from "./BookingListItem";
import PaginationControls from "./PaginationControls";

/** Estilos por defecto. */
const styles = () =>
  createStyles({
    root: {},
  });

/** Las propiedades del componente. */
interface Props extends WithStyles<typeof styles> {
  /** El listado de reservas a mostar. */
  bookings: Booking[];

  /** Función invocada al selecionar una reserva. */
  onSelect?: (booking: Booking) => void;

  /** Función invocada al seleccionar una página */
  onSelectPage: (page: number) => void;

  /** Número de página */
  page: number;

  /** Número de páginas */
  pageCount: number;

  /** Resultados por página */
  pageSize: number;

  /** Número total de resultados */
  totalCount: number;
}

/**
 * Listado de reservas.
 */
class BookingsList extends React.PureComponent<Props> {
  /** #constructor */
  public constructor(props: Props) {
    super(props);
  }

  /** #render */
  public render() {
    const { bookings, onSelect, onSelectPage, page, pageCount, pageSize, totalCount } = this.props;

    return (
      <List dense>
        {bookings.length === 0 ? (
          <ListItem>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="Sin resultados" />
          </ListItem>
        ) : (
          <React.Fragment>
            {bookings.map((booking, idx) => (
              <React.Fragment key={`${idx}:${booking.bookingNumber}`}>
                {idx > 0 && <Divider />}
                <BookingItem booking={booking} onSelect={onSelect} />
              </React.Fragment>
            ))}
            <PaginationControls
              onSelectPage={onSelectPage}
              page={page}
              pageCount={pageCount}
              pageSize={pageSize}
              totalCount={totalCount}
            />
          </React.Fragment>
        )}
      </List>
    );
  }
}

export default withStyles(styles)(BookingsList);
