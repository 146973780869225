/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { Reducer } from "redux";

import { LOAD_PREVIOUS_VIEW, LOAD_VIEW, ViewControllerAction } from "./actions";
import { ViewState } from "./types";

/** Estado por defecto. */
const defaultState: ViewState = { current: null, payload: null, stack: [] };

/** Reducer */
const reducer: Reducer<ViewState, ViewControllerAction> = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_PREVIOUS_VIEW: {
      const history = state.stack;

      if (history.length > 0) {
        const { view, payload } = history[history.length - 1]!;

        return {
          current: view,
          payload,
          stack: history.slice(0, -1),
        };
      } else {
        return state;
      }
    }
    case LOAD_VIEW: {
      let stack = state.stack;

      if (state.current != null && !action.clearHistory) {
        /*
         * state.current únicamente puede ser null al inciar la app.
         * a partir de ahí las acciones fuerzan indicar una vista.
         */
        stack = [...state.stack, { view: state.current, payload: state.payload }];
      } else if (action.clearHistory && stack.length > 0) {
        /*
         * solo cambiar la instancia del array si es necesario. Si ya estaba
         * vacío no hace falta crear uno nuevo.
         */
        stack = [];
      }

      return { current: action.view, payload: action.payload, stack };
    }
    default:
      return state;
  }
};

export default reducer;
