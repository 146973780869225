import { Reducer } from "redux";

import { BookingAction, REFRESH_BOOKING } from "~/services/booking";

import { LOAD_VIEW, ViewControllerAction } from "../actions";
import { BookingViewState } from "./types";

/** Estado por defecto. */
const defaultState: BookingViewState = {};

/** Reducer */
const reducer: Reducer<BookingViewState, BookingAction | ViewControllerAction> = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_VIEW: {
      return defaultState;
    }

    case REFRESH_BOOKING:
      if (action.booking != null) {
        return {
          ...state,
          booking: action.booking,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default reducer;
