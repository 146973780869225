/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import React from "react";

import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import logger from "~/services/logger";
import ErrorView from "~/views/ErrorView";
import SplashView from "~/views/SplashView";

import { initialize } from "./actions";
import App from "./App";
import { registerOnAuth } from "./services/auth/actions";
import { onAuthHandler as configOnAuthHandler } from "./services/config";
import { AppState } from "./state";

/** Definición de las propiedades. */
export interface AppWrapperProps {
  initialize: () => Promise<void>;
}

/** Definición del estado. */
export interface AppWrapperState {
  ready: boolean;
  uiError: any;
}

declare global {
  interface Window {
    __GLOBAL_ERROR_HANDLER__: (
      event: string | Event,
      source?: string | undefined,
      fileno?: number | undefined,
      columnNumber?: number | undefined,
      error?: Error | undefined
    ) => void;
  }
}

/**
 * Wrapper de la aplicación.
 * Aquí registramos la captura de ventos del entorno cordova para encapsular
 * la App de los mismos.
 */
export class AppWrapper extends React.PureComponent<AppWrapperProps, AppWrapperState> {
  /** #constructor */
  public constructor(props: AppWrapperProps) {
    super(props);

    this.state = {
      ready: false,
      uiError: undefined,
    };
  }

  /** Actualiza el estado cuando llega a este nivel un error de renderizado. */
  public static getDerivedStateFromError(error: any) {
    return { uiError: error };
  }

  /**
   * Registra el error producido en el logger.
   */
  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    logger.errorCatch(error, info);
    // eslint-disable-next-line no-console
    console.error(error);
  }

  /** #componentDidMount */
  public componentDidMount() {
    document.addEventListener("deviceready", this.onDeviceReady, false);
    document.addEventListener("toggleSplashScreen", this.onToggleSplashScreen, false);
  }

  /** #componentWillUnmount */
  public componentWillUnmount() {
    document.removeEventListener("deviceready", this.onDeviceReady, false);
    document.removeEventListener("backbutton", this.handleBackEvent, false);
    document.removeEventListener("pause", this.handlePauseEvent, false);
    document.removeEventListener("resume", this.handleResumeEvent, false);
  }

  /** #render */
  public render() {
    const { ready, uiError } = this.state;

    if (uiError == null) {
      return ready ? <App /> : <SplashView />;
    } else {
      /*
       * Si llegamos con uiError no es algo de lo que nos podamos recuperar sin
       * más. La acción es reniciar todo el contexto cordova.
       */
      return <ErrorView error={uiError} action={() => window.location.reload(true)} />;
    }
  }

  /**
   * Listener para el evento lanzado al pulsar el botón atrás del dispositivo.
   */
  private handleBackEvent = () => {
    // TODO Tratar evento
    logger.info("Back button event");
  };

  /**
   * Listener para cuando la aplicación pasa a segundo plano.
   */
  private handlePauseEvent = () => {
    // TODO Tratar evento
    logger.info("Pause event");
  };

  /**
   * Listener para cuando la aplicación vuelve a primer plano.
   */
  private handleResumeEvent = () => {
    // TODO Tratar evento
    logger.info("Resume event");
  };

  /**
   * Listener para el evento onDeviceReady que inicializa el componente.
   */
  private onDeviceReady = () => {
    logger.info("Received deviceReady Event");

    window.__GLOBAL_ERROR_HANDLER__ = (event, source, fileno, columnNumber, error) => {
      logger.debug("__GLOBAL_ERROR_HANDLER__", {
        columnNumber,
        error,
        event,
        fileno,
        source,
        stack: error ? error.stack : undefined,
      });

      if (!(error as any)._suppressLogging) {
        logger.error("Unhandled error" + JSON.stringify({ event, error }));
      }

      return true;
    };

    document.addEventListener("backbutton", this.handleBackEvent, false);
    document.addEventListener("pause", this.handlePauseEvent, false);
    document.addEventListener("resume", this.handleResumeEvent, false);

    /** Registro de handlers */
    registerOnAuth(configOnAuthHandler);

    // El timeout es solo para ver el cambio mientras probamos. Esto no tiene sentido en real.
    window.setTimeout(this.preStart, 2000);
  };

  private onToggleSplashScreen = () => {
    this.setState({ ready: !this.state.ready });
  };

  /**
   * Método que se invoca una vez se ha recibido el evento "deviceready" donde
   * se pueden ejecutar las acciones necesarias antes de montar la App.
   */
  private preStart = async () => {
    await this.props.initialize();

    logger.info("App ready");

    this.setState({ ready: true });
  };
}

/*
 * Se le pasa la porpiedad "initialize" para poder hacer dispatch de las
 * acciones necesarias antes de montar la App.
 */
export default connect(null, (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  initialize: () => dispatch(initialize()),
}))(AppWrapper);
