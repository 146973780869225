import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

/** Propiedades del componente. */
export interface Props {
  /** Handler invocado para cerrar el diálogo.  */
  onClose: React.ReactEventHandler<{}>;

  /** Si se muestra o no. */
  open: boolean;

  /** Texto de la alerta. */
  text?: React.ReactNode;

  /** Título del diálogo. */
  title?: string;
}

/**
 * Composición de Dialog para informar sobre una alerta.
 * Simplemente muestra el texto y un botón para cerrar.
 */
class AlertDialog extends React.PureComponent<Props> {
  /** #constructor */
  public constructor(props: Props) {
    super(props);
  }

  /** #render */
  public render() {
    const { onClose, open, text, title } = this.props;

    return (
      <Dialog open={open} onClose={onClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          {text && <DialogContentText>{text}</DialogContentText>}
          {this.props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;
