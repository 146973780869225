import { i18n as cancelConditionsViewer } from "~/components/CancelConditionsViewer";
import { i18n as confirmDialog } from "~/components/ConfirmDialog";
import { i18n as distributionTable } from "~/components/DistributionTable";
import { i18n as excursionTicketView } from "~/components/ExcursionTicket";
import { i18n as waitingLayer } from "~/components/WaitingLayer";

import { i18n as addExcursionView } from "~/views/AddExcursionView";
import { i18n as bookingSearchView } from "~/views/BookingSearchView";
import { i18n as bookingView } from "~/views/BookingView";
import { i18n as confirmBookingView } from "~/views/ConfirmBookingView";
import { i18n as errorView } from "~/views/ErrorView";
import { i18n as loginView } from "~/views/LoginView";
import { i18n as searcherView } from "~/views/SearcherView";
import { i18n as viewWrapper } from "~/views/ViewWrapper";

const labelSources = [
  addExcursionView,
  bookingSearchView,
  bookingView,
  cancelConditionsViewer,
  confirmBookingView,
  confirmDialog,
  distributionTable,
  errorView,
  excursionTicketView,
  loginView,
  searcherView,
  viewWrapper,
  waitingLayer,
];

const i18n = {
  en: {},
  es: {},
};

for (const languageCode of Object.keys(i18n)) {
  for (const labelSource of labelSources) {
    Object.assign(i18n[languageCode], labelSource[languageCode]);
  }
}

export default i18n;
