import React from "react";
import BottomButtonsPane from "./BottomButtonsPane";

import Button from "@material-ui/core/Button";
import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";

type ClassKeys = "justifyRight";

interface Props extends WithStyles<ClassKeys> {
  nextEnabled?: boolean;
  nextLabel: string;
  onNext: () => void;
}

const styles: StyleRules<ClassKeys> = {
  justifyRight: {
    justifyContent: "flex-end",
  },
};

class OneButtonBottomPane extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <BottomButtonsPane buttonPaneClassName={this.props.classes.justifyRight}>
        <Button variant="contained" color="primary" onClick={this.handleNextClick} disabled={!this.props.nextEnabled}>
          {this.props.nextLabel}
        </Button>
      </BottomButtonsPane>
    );
  }

  private handleNextClick = () => {
    this.props.onNext();
  };
}

export default withStyles(styles)(OneButtonBottomPane);
