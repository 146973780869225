import React from "react";

import { Contact } from "~/views/ConfirmBookingView/types";

import ContactView from "./ContactView";

interface Props {
  contact?: Contact;
  formControlClassName?: string;
  onChangeContact: (contact?: Contact) => void;
}

interface State {
  name?: string;
  phone?: string;
}

class ContactContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = ContactContainer.getDerivedStateFromProps(props);
  }

  public static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.contact) {
      return {
        name: nextProps.contact.name,
        phone: nextProps.contact.phone,
      };
    }

    return {
      name: undefined,
      phone: undefined,
    };
  }

  public render() {
    return (
      <ContactView
        name={this.state.name || ""}
        formControlClassName={this.props.formControlClassName}
        phone={this.state.phone || ""}
        onChangeName={this.handleChangeName}
        onChangePhone={this.handleChangePhone}
      />
    );
  }

  private createContact = (name?: string, phone?: string) => {
    if (!name && !phone) {
      return undefined;
    }

    return { name: name === "" ? undefined : name, phone: phone === "" ? undefined : phone };
  };

  private handleChangeName = (name: string) => {
    this.props.onChangeContact(this.createContact(name, this.state.phone));
  };

  private handleChangePhone = (phone: string) => {
    this.props.onChangeContact(this.createContact(this.state.name, phone));
  };
}

export default ContactContainer;
