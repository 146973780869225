/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";
import { useCallback } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { InjectedI18nProps, withI18n } from "~/services/i18n";

/** Propiedades del componente. */
export interface Props {
  /** Handler invocado con las respuesta.  */
  onSelect: (confirm: boolean) => void;

  /** Si se muestra o no. */
  open: boolean;

  /** Texto de la alerta. */
  text?: React.ReactNode;

  /** Título del diálogo. */
  title?: string;
}

const ConfirmDialog: React.FC<Props & InjectedI18nProps> = props => {
  const {
    children,
    i18n: { formatMessage },
    onSelect,
    open,
    text,
    title,
  } = props;

  const accept = useCallback(() => onSelect(true), [onSelect]);
  const cancel = useCallback(() => onSelect(false), [onSelect]);

  return (
    <Dialog open={open} onClose={cancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color="primary">
          {formatMessage("ConfirmDialog.cancel")}
        </Button>
        <Button onClick={accept} color="primary">
          {formatMessage("ConfirmDialog.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withI18n(ConfirmDialog);
