import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";

import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { PaymentType } from "~/services/webapi/types";

interface Props {
  formControlClassName?: string;
  onPaymentReferenceChange: (paymentReference?: string) => void;
  onPaymentTypeChange: (paymentType: PaymentType) => void;
  paymentReference?: string;
  paymentType?: PaymentType;
}

type ClassKey = "radioGroup";

interface ProvidedProps extends Props, WithStyles<ClassKey>, InjectedI18nProps {}

const styles: StyleRules<ClassKey> = {
  radioGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
};

class PaymentView extends React.PureComponent<ProvidedProps, {}> {
  public render() {
    const { formatMessage } = this.props.i18n;

    return (
      <div className={this.props.formControlClassName}>
        <FormControl margin="none" fullWidth>
          <FormLabel>{formatMessage("paymentView.paymentType")}</FormLabel>
          <RadioGroup
            className={this.props.classes.radioGroup}
            value={this.props.paymentType}
            onChange={(event: React.ChangeEvent, value: string) => {
              this.props.onPaymentTypeChange(value as PaymentType);
            }}
          >
            <FormControlLabel value="CASH" control={<Radio />} label={formatMessage("paymentView.paymentType.CASH")} />
            <FormControlLabel value="CARD" control={<Radio />} label={formatMessage("paymentView.paymentType.CARD")} />
          </RadioGroup>
        </FormControl>
        <TextField
          fullWidth
          label={formatMessage("paymentView.paymentReference")}
          value={this.props.paymentReference}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            this.props.onPaymentReferenceChange(event.target.value);
          }}
          disabled={this.props.paymentType !== "CARD"}
          margin="dense"
        />
      </div>
    );
  }
}

export default withI18n(withStyles(styles)(PaymentView));
