import React from "react";

// import logger from "~/services/logger";

import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";

import { Booking } from "~/services/webapi/types";

import { LocalDate } from "js-joda";
import BookingSearcherForm, { SearchCriteria } from "./components/BookingSearcherForm";
import BookingsList from "./components/BookingsList";

/** Clases JSS del componente. */
type ClassKey = "root";

/** Estilos por defecto. */
const styles: StyleRules<ClassKey> = {
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
  },
};

/** Propiedades del componente. */
export interface Props extends WithStyles<ClassKey> {
  /** Los resultados de la última búsqueda. */
  bookings?: Booking[] | null;

  /** Filtro nombre de pax. */
  contactName?: string;

  /** Fecha de creación mínima. */
  creationDateFrom?: LocalDate;

  /** Fecha de creación máxima. */
  creationDateTo?: LocalDate;

  /** Fecha de excursión mínima. */
  excursionDateFrom?: LocalDate;

  /** Fecha de excursión máxima. */
  excursionDateTo?: LocalDate;

  /** Función invocada al lanzar la búsqueda. */
  onSearch?: (criteria: SearchCriteria) => void;

  /** Función invocada al selecionar una reserva. */
  onSelect?: (booking: Booking) => void;

  /** Función invocada al seleccionar una página */
  onSelectPage: (page: number) => void;

  /** Número de página */
  page: number;

  /** Número de páginas */
  pageCount: number;

  /** Resultados por página */
  pageSize: number;

  /** Localizador / ticket a buscar. */
  reference?: string;

  /** Número de resultados total aplicando los criterios */
  totalCount: number;
}

/**
 * Vista con el listado de reservas realizadas.
 */
class BookingsListView extends React.PureComponent<Props, {}> {
  /** #render */
  public render() {
    const {
      bookings,
      creationDateFrom,
      creationDateTo,
      contactName,
      excursionDateFrom,
      excursionDateTo,
      classes,
      onSearch,
      onSelect,
      onSelectPage,
      page,
      pageCount,
      pageSize,
      reference,
      totalCount,
    } = this.props;

    return (
      <div className={classes.root}>
        <BookingSearcherForm
          creationDateFrom={creationDateFrom}
          creationDateTo={creationDateTo}
          contactName={contactName}
          excursionDateFrom={excursionDateFrom}
          excursionDateTo={excursionDateTo}
          onSearch={onSearch}
          reference={reference}
        />
        {bookings && (
          <BookingsList
            bookings={bookings}
            onSelect={onSelect}
            onSelectPage={onSelectPage}
            page={page}
            pageCount={pageCount}
            pageSize={pageSize}
            totalCount={totalCount}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BookingsListView);
