import { Reducer } from "redux";

import { AuthState } from "./types";

import { AuthAction } from "./actions";
import { SET_USER } from "./actions";

/** Estado por defecto. */
const defaultState: AuthState = { user: null };

/** Reducer */
const reducer: Reducer<AuthState, AuthAction> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.user };

    default:
      return state;
  }
};

export default reducer;
