/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { authenticate } from "~/services/auth";
import { ApiError, ConnectionTimeout, HostUnreachable } from "~/services/webapi/client/types";
import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";

/*
 * Constantes (no acciones)
 */
const KEY_USERNAME = "LoginView/USERNAME";
const KEY_PASSWORD = "LoginView/PASSWORD";

/*
 * Definición de acciones
 */
export const SET_LOGIN_ERROR = "LoginView/SET_LOGIN_ERROR";

/** Acción para indicar que hay error en el login. */
interface LoginError extends Action {
  hasLoginError: boolean;
  type: typeof SET_LOGIN_ERROR;
}

/** Acciones del módulo. */
export type LoginViewAction = LoginError;

/**
 * Tratamiento genérico para los errores producidos en los thunks del módulo.
 *
 * @param error el error producido
 * @param dispatch instancia del dispatch de redux
 */
const handleError = (error: any, dispatch: ThunkDispatch<AppState, void, Action>) => {
  if (error === HostUnreachable || error === ConnectionTimeout || error.type === ApiError) {
    /* Si es un error conocido, entonces lo tratamos. */
    dispatch(
      loadView(
        ViewId.error,
        {
          action: () => dispatch(loadView(ViewId.login, undefined, true)),
          error,
        },
        true
      )
    );
  } else {
    /*
     * Cualquier otro error se propaga. Creo que no deberíamos llegar aquí salvo
     * por errores de programación.
     */
    throw error;
  }
};

/**
 * Acción para inciar si hay error en login por credenciales erróneas.
 */
export const setLoginError = (hasLoginError: boolean) => ({ hasLoginError, type: SET_LOGIN_ERROR });

/**
 * Genera la acción para autenticar al usuario y, de ser el login correcto,
 * redirige a la página correspondiente.
 *
 * @param username   el nombre de usuario
 * @param password   la constraseña del usuario
 */
export const login = (username: string, password: string): ThunkAction<Promise<void>, AppState, void, Action> => async (
  dispatch,
  getState
) => {
  try {
    await dispatch(authenticate(username, password));
  } catch (error) {
    handleError(error, dispatch);

    /* Si vamos a error, hay que finalizar la ejecución. */
    return;
  }

  /*
   * Tambíen se podría sacar de la respuesta, pero se mira el estado para
   * asegurar que no solo la petición ha ido bien, sino que también nos
   * encontramostodo en un "estado"  de autenticado.
   */
  const user = getState().auth.user;

  if (user != null) {
    /* Se guardan las credenciales para lanzar en autologin en el futuro. */
    if (localStorage) {
      localStorage.setItem(KEY_USERNAME, username);
      localStorage.setItem(KEY_PASSWORD, password);
    }

    dispatch(setLoginError(false));
    dispatch(loadView(ViewId.searcher));
  } else {
    /* Esto es error en las credenciales, no es un error pete. */
    dispatch(setLoginError(true));
  }

  return;
};
