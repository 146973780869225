export default {
  "excursionTicket.cancellation": "Cancelación",
  "excursionTicket.cancelledLabel": "anulado",
  "excursionTicket.distribution": "Distribución",
  "excursionTicket.excursion": "Excursión",
  "excursionTicket.excursionDate": "Fecha Exc.",
  "excursionTicket.hotel": "Hotel",
  "excursionTicket.hotelReference": "Habitación",
  "excursionTicket.language": "Idioma",
  "excursionTicket.modality": "Modalidad",
  "excursionTicket.pickUpPoint": "Recogida",
  "excursionTicket.remarks": "Observaciones",
  "excursionTicket.ticket": "Ticket",
  "excursionTicket.total": "Total",
  "excursionTicket.totalWithDiscount": "Total (dto.)",
};
