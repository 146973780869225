import React from "react";

import { PaymentType } from "~/services/webapi/types";
import { Payment } from "~/views/ConfirmBookingView/types";
import PaymentView from "./PaymentView";

interface Props {
  formControlClassName?: string;
  onPaymentChange: (payment?: Payment) => void;
  payment?: Payment;
}

interface State {
  paymentReference?: string;
  paymentType?: PaymentType;
}

class PaymentContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = PaymentContainer.getDerivedStateFromProps(props);
  }

  public static getDerivedStateFromProps(nextProps: Props) {
    if (nextProps.payment) {
      return {
        paymentReference: nextProps.payment.reference,
        paymentType: nextProps.payment.type,
      };
    }

    return {
      paymentReference: undefined,
      paymentType: undefined,
    };
  }

  public render() {
    return (
      <PaymentView
        formControlClassName={this.props.formControlClassName}
        paymentReference={this.state.paymentReference || ""}
        paymentType={this.state.paymentType}
        onPaymentReferenceChange={this.handlePaymentReferenceChange}
        onPaymentTypeChange={this.handlePaymentTypeChange}
      />
    );
  }

  private createPayment(paymentType?: PaymentType, paymentReference?: string): Payment | undefined {
    if (!paymentType) {
      return undefined;
    }

    return { type: paymentType, reference: paymentReference ? paymentReference : undefined };
  }

  private handlePaymentReferenceChange = (paymentReference?: string) => {
    this.props.onPaymentChange(this.createPayment(this.state.paymentType, paymentReference));
  };

  private handlePaymentTypeChange = (paymentType?: PaymentType) => {
    this.props.onPaymentChange(this.createPayment(paymentType, this.state.paymentReference));
  };
}

export default PaymentContainer;
