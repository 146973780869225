/* Etiquetas multiidoma en castellano. */
export default {
  "searcherView.agency": "Agency",
  "searcherView.destination": "Destiantion",
  "searcherView.duration": "Duration",
  "searcherView.excursion": "Excursion",
  "searcherView.excursionType": "Excursion type",
  "searcherView.feature": "Feature",
  "searcherView.modality": "Modality",
  "searcherView.retry": "Retry",
  "searcherView.search": "Search",
  "searcherView.segmentation": "Segmentation",
  "searcherView.unableToLoadDestinations": "Unable to load destinations data",
};
