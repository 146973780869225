/* eslint-disable react/prop-types */ // TODO Fix

import React, { useCallback, useState } from "react";

import clsx from "classnames";

import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PrintIcon from "@material-ui/icons/Print";

import { ExcursionTicket } from "~/services/webapi/types";

import TicketDetails from "./components/TicketDetails";
import TicketHeader from "./components/TicketHeader";
import TicketSummary from "./components/TicketSummary";

const useStyles = makeStyles(() =>
  createStyles({
    actionsPanel: {
      float: "right",
    },
    cancelled: {
      // TODO: Cuando tengamos tema personalizado para fuentes, tb tendremos colores.
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    root: {
      margin: "4px 0px",
      padding: 4,
    },
  })
);

interface TicketProps {
  /** Indica si se muestra en modo edición. */
  editionEnabled?: boolean;

  /** Invocado cuando se queire cancelar un ticket. */
  onCancelTicket?: (ticketNumber: string) => void;

  /** Invocado cuando se modifica un dato del ticket. */
  onFieldChange?: (ticketNumber: string, field: string, value: string) => void;

  /** Invocado cuando se queire imprimir un ticket. */
  onPrintTicket?: (ticketNumber: string) => void;

  /** Ticket a visualizar. */
  ticket: ExcursionTicket;
}

/**
 * componente para visualizar y editar un ticket.
 */
const Ticket: React.FC<TicketProps> = props => {
  const { editionEnabled, onCancelTicket, onFieldChange, onPrintTicket, ticket } = props;

  const { ticketNumber } = ticket;

  /* Estado */
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();

  /* Callbacks */
  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);

  const cancel = useCallback(() => onCancelTicket && onCancelTicket(ticketNumber), [onCancelTicket, ticketNumber]);
  const print = useCallback(() => onPrintTicket && onPrintTicket(ticketNumber), [onPrintTicket, ticketNumber]);

  /* Renderizado */
  const allowCancel = onCancelTicket != null && !editionEnabled;
  const allowPrint = onPrintTicket != null && !editionEnabled;
  const cancelled = ticket.status === "CANCELLED";

  let ticketBody: React.ReactNode;
  if (expanded) {
    ticketBody = <TicketDetails editionEnabled={editionEnabled} onFieldChange={onFieldChange} ticket={ticket} />;
  } else {
    ticketBody = <TicketSummary ticket={ticket} />;
  }

  return (
    <Paper className={clsx(classes.root, { [classes.cancelled]: cancelled })}>
      <div className={classes.actionsPanel}>
        {allowCancel && (
          <IconButton onClick={cancel}>
            <DeleteIcon />
          </IconButton>
        )}
        {allowPrint && (
          <IconButton onClick={print}>
            <PrintIcon />
          </IconButton>
        )}
        <IconButton onClick={toggleExpanded}>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
      </div>

      <TicketHeader ticket={ticket} />
      {ticketBody}
    </Paper>
  );
};

export default Ticket;
