import React from "react";

import IconButton from "@material-ui/core/IconButton";

/** El estado del componente. */
interface State {
  /** Guardará el tiempo de inicio de la pulsación click */
  mouseDownTime: number;
  /**
   * Límite en milisegundos para ditinguir entre una pulsación normal y una
   * larga
   */
  threshold: number;
  /** Guarda el tiempo de inicio de la pulsación touch */
  touchDownTime: number;
}

/** Porpiedades del componente. */
interface Props {
  /** Indica si el componente está deshabilitado */
  disabled?: boolean;
  /**
   * Límite (en milisegundos) a partir de que una pulsación se interpreta com
   * larga, si no se informa son 1000
   */
  longPressThreshold?: number;
  /** Callback para la pulsación larga */
  onLongPress: () => void;
  /** Callback para la pulsación normal */
  onPress: () => void;
}

/**
 * Encapsula un IconButton (hay que pasar el icono como children) y se capturan
 * los eventos de MouseDown y MouseUp. En función del intervalo de tiempo que
 * pasa entre eventos se compara con el valor de la propiedad
 * longPressThreshold, si es superior se llama al callback onLongPress, en caso
 * contrario se llamará al callback onPress.
 */
class LongPressButton extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      mouseDownTime: 0,
      threshold: props.longPressThreshold || 1000,
      touchDownTime: 0,
    };
  }

  /**
   * Chapu: onMouseUp / onTouchEnd -> el primero se lanza en el escritorio y en
   * el dispositivo, el segundo se lanza en el dispositivo pero no en el
   * escritorio. Lo que se hace es capturar los dos y en los tap events hacer un
   * preventDefault para que no salten los mouse events.
   */
  public render() {
    return (
      <IconButton
        disabled={this.props.disabled}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        style={{ touchAction: "none" }}
      >
        {this.props.children}
      </IconButton>
    );
  }

  /**
   * Trata el evento pulsación larga.
   *
   * @param elapsed el tiempo de la pulsación
   */
  private handleLongPress = (elapsed: number) => {
    if (elapsed > this.state.threshold) {
      this.props.onLongPress();
    } else {
      this.props.onPress();
    }
  };

  /**
   * Trata el evento de inicio de pulsación.
   */
  private handleMouseDown = () => {
    this.setState({ mouseDownTime: new Date().getTime() });
  };

  /**
   * Trata el evento de finalización de pulsación.
   */
  private handleMouseUp = () => {
    const elapsed = new Date().getTime() - this.state.mouseDownTime;
    this.handleLongPress(elapsed);
  };

  /**
   * Trata el evento de finalización de pulsación.
   */
  private handleTouchEnd = (event: React.TouchEvent) => {
    event.preventDefault();
    const elapsed = new Date().getTime() - this.state.touchDownTime;
    this.handleLongPress(elapsed);
  };

  /**
   * Trata el evento de inicio de pulsación.
   */
  private handleTouchStart = (event: React.TouchEvent) => {
    event.preventDefault();
    this.setState({ touchDownTime: new Date().getTime() });
  };
}

export default LongPressButton;
