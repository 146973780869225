export default {
  "excursionTicket.cancellation": "Cancellation",
  "excursionTicket.cancelledLabel": "cancelled",
  "excursionTicket.distribution": "Distribution",
  "excursionTicket.excursion": "Excursion",
  "excursionTicket.excursionDate": "Tix. Date",
  "excursionTicket.hotel": "Hotel",
  "excursionTicket.hotelReference": "Room",
  "excursionTicket.language": "Language",
  "excursionTicket.modality": "Modality",
  "excursionTicket.pickUpPoint": "Pick Up",
  "excursionTicket.remarks": "Remarks",
  "excursionTicket.ticket": "Ticket",
  "excursionTicket.total": "Total",
  "excursionTicket.totalWithDiscount": "Total (disc.)",
};
