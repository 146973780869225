import client from "./client";
import * as types from "./types";

/**
 * Recupera el listado de vendedores.
 */
export async function find(): Promise<types.Seller[]> {
  const response = await client.get("/sellers");

  return response.data;
}
