import { Reducer } from "redux";

import { BookingAction, REFRESH_BOOKING } from "~/services/booking";

import { BookingSearchAction, SET_SEARCH_CRITERIA, SET_SEARCH_RESULT, SET_SELLERS } from "./actions";
import { BookingSearchViewState } from "./types";

/** Estado por defecto. */
const defaultState: BookingSearchViewState = {
  page: 0,
  pageCount: 0,
  pageSize: 0,
  totalCount: 0,
};

/** Reducer */
const reducer: Reducer<BookingSearchViewState, BookingSearchAction | BookingAction> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case REFRESH_BOOKING:
      if (state.bookings && action.booking) {
        const bookingNumber = action.booking.bookingNumber;
        const index = state.bookings.findIndex(booking => booking.bookingNumber === bookingNumber);
        if (index !== -1) {
          const newState = { ...state, bookings: [...state.bookings] };
          newState.bookings[index] = action.booking;

          return newState;
        }
      }

      break;

    case SET_SEARCH_CRITERIA:
      return {
        ...state,
        contactName: action.contactName,
        creationFrom: action.creationFrom,
        creationTo: action.creationTo,
        excursionFrom: action.excursionFrom,
        excursionTo: action.excursionTo,
        page: action.page,
        pageSize: action.pageSize,
        reference: action.reference,
        sellerCode: action.sellerCode,
      };

    case SET_SEARCH_RESULT:
      return {
        ...state,
        bookings: action.bookings,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
        searchId: action.searchId,
        totalCount: action.totalCount,
      };

    case SET_SELLERS:
      return {
        ...state,
        sellers: action.sellers,
      };
  }

  return state;
};

export default reducer;
