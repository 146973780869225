/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { AppState } from "~/state";
import { loadView } from "~/views";

import { logout } from "./actions";
import { default as ViewWrapper, Props } from "./ViewWrapper";

/** Propiedades derivadas del estado. */
type StateProps = Pick<Props, "bookedItems" | "currentView" | "user">;
const mapStateToProps: MapStateToProps<StateProps, void, AppState> = state => ({
  agency: state.bookingProcess.agency && state.bookingProcess.agency.name,
  bookedItems: state.bookingProcess.booking != null ? state.bookingProcess.booking.tickets.length : 0,
  currentView: state.appView.current!,
  user: state.auth.user && state.auth.user.username,
});

/** Propiedades a partir de acciones. */
type DispatchProps = Pick<Props, "onViewSelect" | "onLogout">;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, void> = {
  onLogout: logout,
  onViewSelect: loadView,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewWrapper);
