import React from "react";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

import { ViewId } from "~/views";

import { HeaderBar } from "./components/HeaderBar";

/**
 * Definición de los estilos por defecto.
 */
const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    wrappedView: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "auto",
      padding: "10px 10px 10px 10px",
      position: "relative",
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** El nombre de la agencia seleccionada. */
  agency?: string | null;

  /** Indica si el número de items en la cesta. */
  bookedItems?: number;

  /** La vista actual. */
  currentView: ViewId;

  /** Handler para el vento cerrar sesión. */
  onLogout: () => void;

  /** Handler para tratar el evento de cuando el usuario escoge una vista. */
  onViewSelect: (view: ViewId) => void;

  /** El nombre de usuario a mostrar. */
  user?: string | null;
}

/**
 * Wrapper para añadir el menú de la aplicación a las vistas.
 */
class ViewWrappwer extends React.PureComponent<Props, {}> {
  /** #render */
  public render() {
    const { agency, bookedItems, classes, currentView, onLogout, onViewSelect, user } = this.props;

    return (
      <div className={classes.root}>
        <HeaderBar
          agency={agency}
          bookedItems={bookedItems}
          currentView={currentView}
          onLogout={onLogout}
          onViewSelect={onViewSelect}
          user={user}
        />
        <div className={classes.wrappedView}>{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(ViewWrappwer);
