import { Reducer } from "redux";

import { ConfirmBookingViewAction, RESET, SET_CONTACT, SET_PAYMENT } from "./actions";
import { ConfirmBookingViewState } from "./types";

const defaultState: ConfirmBookingViewState = {};

/** Reducer */
const reducer: Reducer<ConfirmBookingViewState, ConfirmBookingViewAction> = (state = defaultState, action) => {
  switch (action.type) {
    case RESET:
      return defaultState;
    case SET_CONTACT:
      return { ...state, contact: action.contact };
    case SET_PAYMENT:
      return { ...state, payment: action.payment };
    default:
      return state;
  }
};

export default reducer;
