import client from "./client";
import * as types from "./types";

import logger from "../logger";

/**
 * Recupera las opciones de impresión del ticket
 */
export async function getAppConfig(): Promise<types.AppConfig> {
  const response = await client.get("/config");
  logger.info("getAppConfig", response);

  if (response.data) {
    return response.data;
  } else {
    throw new Error("Unable to get app config.");
  }
}
