import { Reducer } from "redux";

import { LoginViewAction, SET_LOGIN_ERROR } from "./actions";
import { LoginViewState } from "./types";

/** Estado por defecto. */
const defaultState: LoginViewState = {};

/** Reducer */
const reducer: Reducer<LoginViewState, LoginViewAction> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOGIN_ERROR:
      if (state.hasLoginError !== action.hasLoginError) {
        return { ...state, hasLoginError: action.hasLoginError };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default reducer;
