import React from "react";

import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Highlight from "@material-ui/icons/Highlight";
import HighlightOff from "@material-ui/icons/HighlightOff";

const styles = (theme: Theme) =>
  createStyles({
    controls: {
      left: 0,
      position: "absolute",
      top: 0,
    },
    frame: {
      borderColor: theme.palette.secondary.main,
      borderRadius: "35px",
      borderStyle: "dashed",
      height: 200,
      width: 200,
    },
    frameContainer: {
      alignItems: "center",
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    root: {
      display: "flex",
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    scanner: {
      alignItems: "center",
      background: "none !important",
      backgroundColor: "none",
      display: "flex",
      flex: 1,
    },
  });

interface Props extends WithStyles<typeof styles> {
  isLightAvailable: boolean;
  isLightOn: boolean;
  turnLightOff: () => void;
  turnLightOn: () => void;
}

/** Pantalla de escaneo de códigos QR */
class QRScannerView extends React.PureComponent<Props, {}> {
  public handleSwitchLight = () => {
    if (this.props.isLightOn) {
      this.props.turnLightOff();
    } else {
      this.props.turnLightOn();
    }
  };

  public render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.scanner}>
          <div className={this.props.classes.frameContainer}>
            <div className={this.props.classes.frame} />
          </div>
        </div>
        ,
        <div className={this.props.classes.controls}>
          <IconButton onClick={this.handleSwitchLight}>
            {this.props.isLightOn ? <HighlightOff /> : <Highlight />}
          </IconButton>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(QRScannerView);
