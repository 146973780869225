import React from "react";

import { FormControl, IconButton } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import CalendarIcon from "@material-ui/icons/Today";

import { LocalDate } from "js-joda";

import LabeledText from "~/components/LabeledText";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    dialogPaper: {
      margin: theme.spacing(1),
    },
    root: {
      alignContent: "content-base",
      display: "flex",
      flexDirection: "row",
      flexGrow: 0,
      flexShrink: 0,
    },
  });

/** Propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** La fecha inicial del calendario. */
  date?: LocalDate;

  /** Etiqueta para mostrar en el componente */
  label?: string;

  /** Handler para tratar el evento de abrir calenario. */
  onOpen?: () => void;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

class CalendarDatePicker extends React.PureComponent<ProvidedProps> {
  /** #render */
  public render() {
    const { classes, date, onOpen, label } = this.props;
    const { formatLocalDate } = this.props.i18n;

    const dateText = date != null ? formatLocalDate(date) : "";

    return (
      <FormControl className={classes.root}>
        <LabeledText label={label} text={dateText} />
        <IconButton onClick={onOpen}>
          <CalendarIcon />
        </IconButton>
      </FormControl>
    );
  }
}

export default withI18n(withStyles(styles)(CalendarDatePicker));
