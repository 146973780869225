import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { resetState } from "~/actions";
import { deauthenticate } from "~/services/auth";
import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";

/**
 * Genera la acción para desautenticar al usuario y volver a la vista de login.
 */
export const logout = (): ThunkAction<Promise<void>, AppState, void, Action> => async dispatch => {
  /*
   * Se cambia dos veces a la vista de login. En primer lugar porque antes
   * de eliminar la info de usuario queremos estar en una vista que no vaya
   * a dar problemas si no hay usuario.
   * Pero después, se hace un reset del estado y volvemos a poner la vista en
   * la que queremos acabar. (Sabemos que el reset nos deja en login, pero
   * mejor dejarlo atado por si no fuera así).
   */
  await dispatch(loadView(ViewId.login));
  await dispatch(deauthenticate());
  await dispatch(resetState());
  await dispatch(loadView(ViewId.login));
};
