import React from "react";

import IconButton from "@material-ui/core/IconButton";
import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import BeforeIcon from "@material-ui/icons/NavigateBefore";
import NextIcon from "@material-ui/icons/NavigateNext";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

interface Props {
  onSelectPage: (page: number) => void;
  page: number;
  pageCount: number;
  pageSize: number;
  totalCount: number;
}

type ClassKey = "root";

const styles: StyleRules<ClassKey> = {
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
};

interface ProviedProps extends Props, WithStyles<ClassKey>, InjectedI18nProps {}

class PaginationController extends React.PureComponent<ProviedProps, {}> {
  public render() {
    const { page, pageCount, pageSize, totalCount } = this.props;
    const { formatMessage } = this.props.i18n;
    const first = (page - 1) * pageSize + 1;
    const last = Math.min(page * pageSize, totalCount);

    return (
      <div className={this.props.classes.root}>
        <Typography>{formatMessage("paginationControls.summary", { first, last, totalCount })}</Typography>
        <div>
          <IconButton disabled={page < 2} onClick={this.onPreviousPage}>
            <BeforeIcon />
          </IconButton>
          <IconButton disabled={!(page < pageCount)} onClick={this.onNextPage}>
            <NextIcon />
          </IconButton>
        </div>
      </div>
    );
  }

  private onNextPage = () => {
    const { page, onSelectPage } = this.props;
    onSelectPage(page + 1);
  };

  private onPreviousPage = () => {
    const { page, onSelectPage } = this.props;
    onSelectPage(page - 1);
  };
}

export default withI18n(withStyles(styles)(PaginationController));
