import React from "react";

import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import __ENVIRONMENT__ from "~/environment";

const style = createStyles({
  "@global": {
    "@keyframes SplashView-fade": {
      "50%": { opacity: 0.4 },
      from: { opacity: 1.0 },
      to: { opacity: 1.0 },
    },
  },
  blink: {
    animation: "SplashView-fade 3000ms infinite",
  },
  bottomPane: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  fill: {
    flexGrow: 1,
  },
  listening: {
    backgroundColor: "#333333",
    borderRadius: 4,
    color: "#FFFFFF",
    fontSize: 12,
    margin: 2,
    marginBottom: 50,
    minWidth: 200,
    padding: 2,
  },
  logo: {
    marginTop: 100,
    maxWidth: "90%",
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    textAlign: "center",
  },
  version: {
    padding: "0 6px 6px 0",
  },
});

interface Classes {
  blink: string;
  bottomPane: string;
  fill: string;
  listening: string;
  logo: string;
  root: string;
  version: string;
}

interface Props {
  classes: Classes;
}

/**
 * Splash screen, para mostrar al arrancar la aplicación
 */
class SplashView extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <div className={this.props.classes.root}>
        <img className={this.props.classes.logo} src="./img/Logo_Splash.png" />
        <div className={this.props.classes.fill} />
        <Typography variant="h5">TPV Service</Typography>
        <div className={this.props.classes.blink}>
          <Typography variant="subtitle1" className={this.props.classes.listening}>
            Initializing...
          </Typography>
        </div>
        <div className={this.props.classes.bottomPane}>
          <div className={this.props.classes.version}>
            <Typography variant="caption">
              v{__ENVIRONMENT__.VERSION} ({__ENVIRONMENT__.TARGET})
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(style)(SplashView);
