/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Payment } from "~/services/webapi/types";
import { AppState } from "~/state";
import { wrap } from "~/views/ViewWrapper";
import { loadView } from "../actions";
import { ViewId } from "../types";
import { cancelBooking, postBookingConfirm, postTicketCancel, setContact, setDiscount, setPayment } from "./actions";
import ConfirmBookignView, { Props } from "./ConfirmBookingView";

type StateProps = Pick<
  Props,
  | "booking"
  | "bookingDiscount"
  | "bookingDiscounts"
  | "canConfirm"
  | "contact"
  | "payment"
  | "ticketDiscount"
  | "ticketDiscounts"
>;

const mapStateToProps: MapStateToProps<StateProps, void, AppState> = state => {
  const booking = state.bookingProcess.booking!;
  const { payment, contact } = state.confirmBookingView;
  const canConfirm =
    !!payment &&
    (payment.type === "CASH" || payment.type === "CARD") &&
    !!contact &&
    !!contact.name &&
    booking &&
    booking.tickets &&
    booking.tickets.length > 0;

  return {
    booking,
    bookingDiscount: state.bookingProcess.bookingDiscount || undefined,
    bookingDiscounts: state.bookingProcess.bookingDiscounts || undefined,
    canConfirm,
    contact: contact || undefined,
    payment: payment as Payment | undefined,
    ticketDiscount: state.bookingProcess.ticketDiscount || undefined,
    ticketDiscounts: state.bookingProcess.ticketDiscounts || undefined,
  };
};

type DispatchProps = Pick<
  Props,
  | "onAddMoreTickets"
  | "onBackToSearch"
  | "onCancel"
  | "onContactChange"
  | "onConfirm"
  | "onDiscountChange"
  | "onPaymentChange"
>;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, void> = dispatch =>
  bindActionCreators(
    {
      onAddMoreTickets: () => loadView(ViewId.searcher),
      onBackToSearch: cancelBooking,
      onCancel: postTicketCancel,
      onConfirm: postBookingConfirm,
      onContactChange: setContact,
      onDiscountChange: setDiscount,
      onPaymentChange: setPayment,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(wrap(ConfirmBookignView));
