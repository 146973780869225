import { Action } from "redux";

import { ViewPayload } from "~/components/ViewController";
import { ViewId } from "./types";

/*
 * Definición de acciones
 */
export const LOAD_PREVIOUS_VIEW = "ViewController/LOAD_PREVIOUS_VIEW";
export const LOAD_VIEW = "ViewController/LOAD_VIEW";

/** Acción para ir atrás en el historial de vistas. */
interface LoadPreviousView extends Action {
  type: typeof LOAD_PREVIOUS_VIEW;
}

/** Acción para ir a una vista determinada. */
interface LoadView extends Action {
  clearHistory?: boolean;
  payload: ViewPayload;
  type: typeof LOAD_VIEW;
  view: ViewId;
}

/** Acciones del módulo. */
export type ViewControllerAction = LoadPreviousView | LoadView;

/*
 * Aciones finales
 */

/**
 * Genera la acción para ir atrás en el historial.
 */
export const loadPreviousView = () => ({ type: LOAD_PREVIOUS_VIEW });

/**
 * Genera la acción para ir a una vista determinada.
 *
 * @param view          la vista a mostrar
 * @param payload       el payload a pasar a la vista
 * @param clearHistory  si debe vaciar el historial al cambiar la vista
 */
export const loadView = (view: ViewId, payload?: ViewPayload | null, clearHistory?: boolean) => ({
  clearHistory,
  view,
  payload,
  type: LOAD_VIEW,
});
