import React from "react";

import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const QRIcon: React.ComponentType<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g fill="none" strokeWidth="2" strokeLinecap="butt" stroke="currentColor">
      <path stroke="none" fill="none" d="M0 0h24v24H0z" />
      <path id="A" d="M2,2 h10 v10 h-10 v-10 z" />
      <path id="B" d="M6,6 h2 v2 h-2 z" />
      <path id="C" d="M15,2 h8" />
      <path id="D" d="M16,16 h6 v6 h-6 v-6 z" />
      <path id="B2" d="M6,15 h2 v2 h-2 z" />
      <path id="B3" d="M2,19 h2 v2 h-2 z" />
      <path id="B4" d="M29,10 h2 v2 h-2 z" />
      <path id="B5" d="M18,3 h2 v2 h-2 z" />
      <path id="B6" d="M10,18 h2 v2 h-2 z" />
      <path id="C2" d="M15,7 h8" />
    </g>
  </SvgIcon>
);

export default QRIcon;
