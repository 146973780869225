import React from "react";

import { ViewPayload } from "./types";
import { ViewDefProps } from "./ViewDef";

/**
 * Propiedades del componente.
 */
export interface Props<V> {
  /** Listado con la definición de vistas. (o una única vista) */
  children: Array<React.ReactElement<ViewDefProps<V>>> | React.ReactElement<ViewDefProps<V>>;

  /** Identificador de la vista actual. */
  currentView: V | null;

  /** Identificador de la vista por defecto. */
  defaultView: V;

  /** Funcional invocada cuando se debe navegar atrás. */
  loadPreviousView: () => void;

  /** Funcion invocada para cargar una vista. */
  loadView: (view: V) => void;

  /** Parámetros a pasar a la vista. */
  viewPayload?: ViewPayload | null;
}

/**
 * Controlador de las vistas de la aplicación.
 *
 * Se encarga de redirigir las acciones a las vistas correspondientes.
 */
export default class ViewController<V> extends React.PureComponent<Props<V>> {
  /**
   * Registra la navegación atrás por defecto y carga la vista por defecto si no
   * hay ninguna especificada.
   */
  public componentDidMount() {
    document.addEventListener("backbutton", this.handleBackEvent, false);

    if (this.props.currentView == null) {
      this.props.loadView(this.props.defaultView);
    }
  }

  /** Elimina los handlers registrados. */
  public componentWillUnmount() {
    document.removeEventListener("backbutton", this.handleBackEvent, false);
  }

  /** #render */
  public render() {
    return React.Children.map(this.props.children, child => {
      if (React.isValidElement(child)) {
        const { component: Component, view } = (child as React.ReactElement<ViewDefProps<V>>).props;

        if (view === this.props.currentView) {
          return <Component {...this.props.viewPayload} />;
        }
      }

      return null;
    });
  }

  /**
   * Listener para el evento lanzado al pulsar el botón atrás del dispositivo.
   */
  private handleBackEvent = (ev: Event) => {
    /* No propagar ni continuar con el evento. */
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    this.props.loadPreviousView();
  };
}
