import { withStyles } from "@material-ui/core";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const CustomTableCell = withStyles(() => ({
  body: {
    "&:last-child": {
      paddingRight: 4,
    },
    padding: "2px 4px 2px 4px",
  },
  footer: {
    "&:last-child": {
      paddingRight: 4,
    },
    padding: "2px 4px 2px 4px",
  },
  head: {
    "&:last-child": {
      paddingRight: 4,
    },
    padding: "2px 4px 2px 4px",
  },
}))(TableCell);

export const CustomTableRow = withStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.grey[200],
  },
  head: {
    backgroundColor: theme.palette.grey[200],
  },
  root: {
    height: 30,
  },
}))(TableRow);
