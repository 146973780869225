import React from "react";

import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";

type ClassKeys = "root" | "buttonPane" | "fill";

interface Props extends WithStyles<ClassKeys> {
  buttonPaneClassName?: string;
}

const styles: StyleRules<ClassKeys> = {
  buttonPane: {
    display: "flex",
    justifyContent: "space-between",
  },
  fill: {
    flexGrow: 1,
    flexShrink: 1,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    paddingTop: 10,
  },
};

class BottomButtonsPane extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.fill} />
        <div className={[this.props.classes.buttonPane, this.props.buttonPaneClassName || ""].join(" ")}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BottomButtonsPane);
