import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { LocalDate } from "js-joda";

import { findSellers } from "~/services/booking";
import logger from "~/services/logger";
import { find as findBookings } from "~/services/webapi/bookings";
import { ApiError, ConnectionTimeout, HostUnreachable } from "~/services/webapi/client/types";
import { Booking, Seller } from "~/services/webapi/types";
import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";

/*
 * Definición de acciones
 */
export const SET_SEARCH_CRITERIA = "BookingSearch/SET_SEARCH_CRITERIA";
export const SET_SEARCH_RESULT = "BookingSearch/SET_SEARCH_RESULT";
export const SET_SELLERS = "BookingSearch/SET_SELLERS";

/** Acción para indicar que establecer el resultado de la búsqueda. */
interface SetSearchResult extends Action {
  bookings: Booking[] | null;
  page: number;
  pageCount: number;
  pageSize: number;
  searchId: string;
  totalCount: number;
  type: typeof SET_SEARCH_RESULT;
}

/** Acción para establecer el criterio de búsqueda */
interface SetSearchCriteria extends Action {
  contactName?: string;
  creationFrom?: LocalDate;
  creationTo?: LocalDate;
  excursionFrom?: LocalDate;
  excursionTo?: LocalDate;
  page: number;
  pageSize: number;
  reference?: string;
  sellerCode?: string;
  type: typeof SET_SEARCH_CRITERIA;
}

/** Acción para establecer el criterio de búsqueda */
interface SetSellers extends Action {
  sellers?: Seller[];
  type: typeof SET_SELLERS;
}

/** Acciones del módulo. */
export type BookingSearchAction = SetSearchCriteria | SetSearchResult | SetSellers;

/**
 * Tratamiento genérico para los errores producidos en los thunks del módulo.
 *
 * @param error el error producido
 * @param dispatch instancia del dispatch de redux
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (error: any, dispatch: ThunkDispatch<AppState, void, Action>) => {
  if (error === HostUnreachable || error === ConnectionTimeout || error.type === ApiError) {
    /*
     * Si es un error conocido, entonces lo tratamos. Simplemente nos quedamos
     * en la misma página tras ver el error.
     */
    dispatch(
      loadView(
        ViewId.error,
        {
          action: () => dispatch(loadView(ViewId.bookingSearch, undefined, true)),
          error,
          message: error.type === ApiError && error.details ? error.details.message : undefined,
        },
        true
      )
    );
  } else {
    /*
     * Cualquier otro error se propaga. Creo que no deberíamos llegar aquí salvo
     * por errores de programación.
     */
    throw error;
  }
};

/** */
export const setSearchCriteria = (
  reference?: string,
  sellerCode?: string,
  contactName?: string,
  creationFrom?: LocalDate,
  creationTo?: LocalDate,
  excursionFrom?: LocalDate,
  excursionTo?: LocalDate,
  page?: number,
  pageSize?: number
) => ({
  contactName,
  creationFrom,
  creationTo,
  excursionFrom,
  excursionTo,
  page,
  pageSize,
  reference,
  sellerCode,
  type: SET_SEARCH_CRITERIA,
});

/**
 * Acción para establecer el resultado de la búsqueda.
 */
export const setSearchResult = (
  bookings: Booking[] | null,
  searchId: string,
  page: number,
  pageCount: number,
  pageSize: number,
  totalCount: number
) => ({
  bookings,
  page,
  pageCount,
  pageSize,
  searchId,
  totalCount,
  type: SET_SEARCH_RESULT,
});

/**
 * Acción para establecer los vendedores disponibles.
 */
export const setSellers = (sellers?: Seller[]) => ({ sellers, type: SET_SELLERS });

/**
 * Genera la acción para buscar las reservas según el criterio especificado.
 * Una vez realizada
 */
export const searchBookings = (
  reference?: string,
  sellerCode?: string,
  contactName?: string,
  creationFrom?: LocalDate,
  creationTo?: LocalDate,
  excursionFrom?: LocalDate,
  excursionTo?: LocalDate,
  page = 1,
  pageSize = 10
): ThunkAction<Promise<void>, AppState, void, Action> => async dispatch => {
  let bookings = null;
  let pageCount = 1;
  let totalCount = 1;

  try {
    const findResult = await findBookings(
      reference,
      sellerCode,
      contactName,
      creationFrom,
      creationTo,
      excursionFrom,
      excursionTo,
      undefined,
      page,
      pageSize
    );
    bookings = findResult.values;
    page = findResult.page;
    pageCount = findResult.pageCount;
    pageSize = findResult.pageSize;
    totalCount = findResult.totalCount;

    dispatch(
      setSearchCriteria(
        reference,
        sellerCode,
        contactName,
        creationFrom,
        creationTo,
        excursionFrom,
        excursionTo,
        page,
        pageSize
      )
    );
    dispatch(setSearchResult(bookings, "id:" + Date.now(), page, pageCount, pageSize, totalCount));
  } catch (error) {
    logger.error("Error on searchBookings", error);
    handleError(error, dispatch);
  }
};

/**
 * Genera la acción para importar los vendedores.
 */
export const fetchSellers = (): ThunkAction<Promise<void>, AppState, void, Action> => async dispatch => {
  const sellers = (await findSellers()) || [];
  dispatch(setSellers(sellers));
};

/**
 * Genera la acción para recuperar una página de resultados de la búsqueda de
 * reserva
 */
export const selectPage = (page: number): ThunkAction<void, AppState, void, Action> => async (dispatch, getState) => {
  const {
    contactName,
    creationFrom,
    creationTo,
    excursionFrom,
    excursionTo,
    reference,
    sellerCode,
  } = getState().bookingSearchView;

  await dispatch(
    searchBookings(reference, sellerCode, contactName, creationFrom, creationTo, excursionFrom, excursionTo, page)
  );
};
