/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix
import React from "react";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddMoreIcon from "@material-ui/icons/AddShoppingCart";
import CancelIcon from "@material-ui/icons/Clear";
import ConfirmIcon from "@material-ui/icons/DoneOutline";

import BottomButtonsPane from "~/components/BottomButtonsPane";
import CodeNameSelector from "~/components/CodeNameSelector";
import ExcurstionTicket from "~/components/ExcursionTicket";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Booking, Payment, PriceBreakdown } from "~/services/webapi/types";
import { Contact as WebapiContact, Discount, Payment as WebapiPayment } from "~/services/webapi/types";
import ContactView from "./components/ContactView";
import PaymentView from "./components/PaymentView";
import { Contact } from "./types";

const styles = () =>
  createStyles({
    discountPanel: {
      marginBottom: 8,
      padding: "4px 12px 8px",
    },
    fixedSize: {
      flexGrow: 0,
      flexShrink: 0,
    },
    paymentView: {
      flexGrow: 0,
      flexShrink: 0,
      marginTop: 20,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
    },
    totalSummary: {
      marginBottom: 8,
      padding: 8,
    },
    totalSummaryItem: {
      "& :last-child": {
        minWidth: 80,
      },
      "& :not(:last-child)": {
        marginRight: 8,
      },
      alignItems: "baseline",
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 2,
      textAlign: "right",
    },
    totalSummaryPrice: {
      fontWeight: 500,
    },
  });

export interface Props extends WithStyles<typeof styles> {
  booking: Booking;
  bookingDiscount?: Discount | null;
  bookingDiscounts?: Discount[] | null;
  canConfirm?: boolean;
  contact?: Contact;
  onAddMoreTickets: () => void;
  onBackToSearch: () => void;
  onCancel: (ticketNumber: string) => void;
  onConfirm: (booking: Booking) => void;
  onContactChange: (contact?: Contact) => void;
  onDiscountChange: (discount: Discount | null, ticketNumber?: string) => void;
  onPaymentChange: (payment?: Payment) => void;
  payment?: Payment;
  ticketDiscount?: { [key: string]: Discount | undefined };
  ticketDiscounts?: { [key: string]: Discount[] | undefined } | null;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Pantalla de cierre de reserva.
 */
class ConfirmBookingView extends React.PureComponent<ProvidedProps, {}> {
  /** #render */
  public render() {
    const {
      bookingDiscount,
      bookingDiscounts,
      booking: { tickets },
      onCancel,
      ticketDiscount,
      ticketDiscounts,
    } = this.props;

    const totalSummary = tickets.reduce(
      (accumulator, { price: { basePrice, discountAmount, totalPrice, currency } }) => {
        accumulator.basePrice += basePrice;
        accumulator.totalPrice += totalPrice;
        accumulator.currency = currency;
        if (discountAmount != null) {
          accumulator.discountAmount = (accumulator.discountAmount || 0) + discountAmount;
        }

        return accumulator;
      },
      { basePrice: 0, totalPrice: 0 } as PriceBreakdown
    );

    const { formatCurrency, formatMessage } = this.props.i18n;

    return (
      <div className={this.props.classes.root}>
        {tickets.map((ticket, index) => {
          const discounts = ticketDiscounts && ticketDiscounts[ticket.ticketNumber];
          const discount = ticketDiscount && ticketDiscount[ticket.ticketNumber];

          return (
            <React.Fragment key={index}>
              <ExcurstionTicket ticket={ticket} onCancelTicket={tickets.length > 1 ? onCancel : undefined} />
              {discounts && discounts.length > 0 && (
                <Paper elevation={1} className={this.props.classes.discountPanel}>
                  <CodeNameSelector
                    addEmpty
                    fullWidth
                    label={formatMessage("confirmBookingView.discount")}
                    margin="none"
                    onValueChange={this.getOnDiscountHandler(ticket.ticketNumber)}
                    selectedCode={discount ? discount.code : null}
                    values={discounts}
                  />
                </Paper>
              )}
            </React.Fragment>
          );
        })}
        {bookingDiscounts && bookingDiscounts.length > 0 && (
          <Paper elevation={1} className={this.props.classes.discountPanel}>
            <CodeNameSelector
              addEmpty
              fullWidth
              label={formatMessage("confirmBookingView.discount")}
              margin="none"
              onValueChange={this.getOnDiscountHandler()}
              selectedCode={bookingDiscount ? bookingDiscount.code : null}
              values={bookingDiscounts}
            />
          </Paper>
        )}
        {(tickets.length > 1 || totalSummary.discountAmount != null) && (
          <Paper elevation={1} className={this.props.classes.totalSummary}>
            {totalSummary!.discountAmount != null && (
              <div className={this.props.classes.totalSummaryItem}>
                <Typography variant="body2" color="textSecondary">
                  {formatMessage("confirmBookingView.subtotal")}:
                </Typography>
                <Typography variant="body2">{formatCurrency(totalSummary.basePrice, totalSummary.currency)}</Typography>
              </div>
            )}
            {totalSummary.discountAmount != null && (
              <div className={this.props.classes.totalSummaryItem}>
                <Typography variant="body2" color="textSecondary">
                  {formatMessage("confirmBookingView.discount")}:
                </Typography>
                <Typography variant="body2">
                  {formatCurrency(-(totalSummary.discountAmount || 0), totalSummary.currency)}
                </Typography>
              </div>
            )}
            <div className={this.props.classes.totalSummaryItem}>
              <Typography variant="body2" color="textSecondary">
                {formatMessage("confirmBookingView.total")}:
              </Typography>
              <Typography variant="body2" classes={{ root: this.props.classes.totalSummaryPrice }}>
                {formatCurrency(totalSummary.totalPrice, totalSummary.currency)}
              </Typography>
            </div>
          </Paper>
        )}
        <ContactView
          contact={this.props.contact}
          formControlClassName={this.props.classes.fixedSize}
          onChangeContact={this.props.onContactChange}
        />
        <PaymentView
          formControlClassName={this.props.classes.paymentView}
          payment={this.props.payment}
          onPaymentChange={this.props.onPaymentChange}
        />
        <div style={{ flexGrow: 1 }} />
        <BottomButtonsPane>
          <Button color="default" onClick={this.props.onBackToSearch} variant="outlined">
            <CancelIcon />
          </Button>
          <Button color="primary" onClick={this.props.onAddMoreTickets} variant="contained">
            <AddMoreIcon />
          </Button>
          <Button color="primary" disabled={!this.props.canConfirm} onClick={this.handleOnConfirm} variant="contained">
            <ConfirmIcon />
          </Button>
        </BottomButtonsPane>
      </div>
    );
  }

  // TODO: Añadir moize para evitar crear siempre instancias nuevas.
  private getOnDiscountHandler = (ticketNumber?: string) => (code: string) => {
    const { bookingDiscounts, ticketDiscounts, onDiscountChange } = this.props;

    const discounts = ticketNumber ? ticketDiscounts && ticketDiscounts[ticketNumber] : bookingDiscounts;

    if (!code) {
      onDiscountChange(null, ticketNumber);
    } else {
      onDiscountChange(discounts!.find(discount => code === discount.code) || null, ticketNumber);
    }
  };

  private handleOnConfirm = () => {
    /*
     * TODO: Tampoco hace falta mandar la reserva entera.
     * Mejor sería sacarla del estado en el servicio
     */
    this.props.onConfirm({
      ...this.props.booking,
      contact: this.props.contact as WebapiContact,
      payment: this.props.payment as WebapiPayment,
    });
  };
}

export default withI18n(withStyles(styles)(ConfirmBookingView));
