/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import React from "react";

import ErrorStackParser from "error-stack-parser";
import JSONPretty from "react-json-pretty";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

/** Estilos por defecto. */
const styles = () =>
  createStyles({
    stack: {
      whiteSpace: "nowrap",
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** El error producido. */
  error: any;
}

/**
 * Pantalla genérica de error.
 */
class ErrorDetails extends React.PureComponent<Props> {
  /** #render */
  public render() {
    const error = this.props.error;

    if (typeof error === "string") {
      return (
        <React.Fragment>
          {error.split("\n").map((line, index) => (
            <p key={index}>{line.trim()}</p>
          ))}
        </React.Fragment>
      );
    } else if (error instanceof Error) {
      return this.renderError(error);
    } else {
      return this.renderErrorAsObject(error);
    }
  }

  /** Renderiza los detalles del error y la traza del mismo. */
  private renderError(error: any) {
    return (
      <div>
        <p>{error.message}</p>
        <div className={this.props.classes.stack}>
          {ErrorStackParser.parse(error).map((frame, idx) => (
            <div key={idx}>{`${frame.functionName} @ ${frame.fileName}:${frame.lineNumber}`}</div>
          ))}
        </div>
      </div>
    );
  }

  /**
   * Renderiza el error mostrando las propiedades como si fuera un objeto en
   * json.
   */
  private renderErrorAsObject(error: any) {
    return <JSONPretty json={error} />;
  }
}

export default withStyles(styles)(ErrorDetails);
