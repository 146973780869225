import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { startNewBooking } from "~/services/booking";
import logger from "~/services/logger";
import { get as apiGetBooking } from "~/services/webapi/bookings";
import { Booking, TicketConfig } from "~/services/webapi/types";
import { AppState } from "~/state";

import { loadView } from "../actions";
import { ViewId } from "../types";
import { printTicket } from "./printTicket";

export const onNewBooking = (): ThunkAction<void, AppState, void, Action> => async dispatch => {
  dispatch(loadView(ViewId.searcher, undefined, true));
  dispatch(startNewBooking());
};

export const onPrintAllTickets = async (booking: Booking, config?: TicketConfig | null): Promise<void> => {
  for (const ticket of booking.tickets) {
    /* No falla porque cada printTicket ya trata el error. */
    if (ticket.status === "CONFIRMED") {
      await printTicket(booking, ticket, config);
    }
  }
};

export const onPrintTicket = async (
  booking: Booking,
  ticketNumber: string,
  config?: TicketConfig | null
): Promise<void> => {
  let ticket = booking.tickets.find(tk => tk.ticketNumber === ticketNumber);

  if (ticket) {
    logger.info(`PrintTicket ${ticketNumber}`);
    if (ticket.status === "CANCELLED") {
      const cancellationBookingNumber = ticket.cancellationBookingNumber;
      const cancellationTicketNumber = ticket.cancellationTicketNumber;

      if (cancellationBookingNumber != null && cancellationTicketNumber != null) {
        logger.info(`PrintTicket cancellation: ${cancellationBookingNumber}/${cancellationTicketNumber}`);

        /*
         * Si el ticket está anulado, entonces debemos recueprar el ticket de
         * anulación e imprimir ese.
         */
        const annulation = await apiGetBooking(cancellationBookingNumber).catch(() => {
          /* No hacer nada, se trata más abajo. */
        });

        if (annulation != null) {
          booking = annulation;
          ticket = annulation.tickets.find(tk => tk.ticketNumber === cancellationTicketNumber);

          if (ticket == null) {
            logger.error(`Cancellation ticket not found: ${cancellationBookingNumber}/${cancellationTicketNumber}`);

            return;
          }
        } else {
          logger.error(`Unable to retrieve ticket: ${cancellationBookingNumber}`);

          return;
        }
      } else {
        logger.error(`Cancelled ticket without cancellation reference: ${booking.bookingNumber}/${ticketNumber}`);

        return;
      }
    }

    if (booking != null && ticket != null) {
      try {
        await printTicket(booking, ticket, config);

        return;
      } catch (error) {
        logger.error("Error on printTicket", error);

        // TODO: Si falla aquí (que no debería nunca) notificarlo en la propia vista. No ir a error.
        return;
      }
    }
  } else {
    logger.error(`Ticket not found on booking: ${booking.bookingNumber}/${ticketNumber}`);
  }
};
