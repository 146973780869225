/** Tipos de causas de error que se controlan. */
export const ApiError = "ApiError";
export const HostUnreachable = "HostUnreachable";
export const ConnectionTimeout = "ConnectionTimeout";
export const Unauthorized = "Unauthorized";

/** Error producido durante la ejeución de la petición en servidor. */
// TODO: Ver qué hacer con los 4xx, ahora solo diferenciamos 401
export interface ApiError {
  details?: ApiErrorDetails | undefined;
  status: number;
  type: typeof ApiError;
}

export interface ApiErrorDetails {
  exception?: string;
  message?: string;
  status?: number;
  timestamp?: string;
  trace?: string;
}

export type Failure = typeof HostUnreachable | typeof ConnectionTimeout | typeof Unauthorized | ApiError;
