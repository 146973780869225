/** Variables de entorno */
export interface Environment {
  /** Indica si estamos construyendo una apk para distribución */
  APK: boolean;

  /** Url base del servidor */
  BASE_URI: string;

  /** Indica si las opciones de debug están habilitadas */
  DEBUG: boolean;

  /** Versión de javascript del código transpilado */
  TARGET: string;

  /** COLOR PRIMARIO */
  THEME_PRIMARY_COLOR: string;

  /** COLOR SECUNDARIO */
  THEME_SECONDARY_COLOR: string;

  /** Título (o nombre) de la aplicaicón. */
  TITLE: string;

  /** Versión */
  VERSION: string;
}

declare let __ENVIRONMENT__: Environment;

export default __ENVIRONMENT__;
