/* Etiquetas multiidoma en castellano. */
export default {
  "availabilityCalendar.freeSale": "FS",
  "availabilityCalendar.weekDayShort.friday": "F",
  "availabilityCalendar.weekDayShort.moday": "M",
  "availabilityCalendar.weekDayShort.saturday": "S",
  "availabilityCalendar.weekDayShort.sunday": "U",
  "availabilityCalendar.weekDayShort.thursday": "R",
  "availabilityCalendar.weekDayShort.tuesday": "T",
  "availabilityCalendar.weekDayShort.wednesday": "W",
};
