/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { softReset } from "~/actions";
import { confirm, removeTicket, startNewBooking } from "~/services/booking";
import logger from "~/services/logger";
import { ApiError, ConnectionTimeout, HostUnreachable } from "~/services/webapi/client/types";
import { Booking } from "~/services/webapi/types";
import { AppState } from "~/state";

import { loadView } from "../actions";
import { ViewId } from "../types";
import { Contact, Payment } from "./types";

export const RESET = "ConfirmBookingView/RESET";
export const SET_CONTACT = "ConfirmBookingView/SET_CONTACT";
export const SET_PAYMENT = "ConfirmBookingView/SET_PAYMENT";

export interface Reset extends Action {
  type: typeof RESET;
}

export interface SetContact extends Action {
  contact?: Contact;
  type: typeof SET_CONTACT;
}

export interface SetPayment extends Action {
  payment?: Payment;
  type: typeof SET_PAYMENT;
}

export type ConfirmBookingViewAction = Reset | SetContact | SetPayment;

/**
 * Tratamiento genérico para los errores producidos en los thunks del módulo.
 *
 * @param error el error producido
 * @param resetState si se debe resetear el estado del a reserva
 * @param dispatch instancia del dispatch de redux
 */
const handleError = (error: any, resetState: boolean, dispatch: ThunkDispatch<AppState, void, Action>) => {
  if (resetState) {
    dispatch(softReset());
  }

  if (error === HostUnreachable || error === ConnectionTimeout || error.type === ApiError) {
    /* Si es un error conocido, entonces lo tratamos. */
    dispatch(
      loadView(
        ViewId.error,
        {
          action: () => dispatch(loadView(ViewId.searcher, undefined, true)),
          error,
          message: error.type === ApiError && error.details ? error.details.message : undefined,
        },
        true
      )
    );
  } else {
    /*
     * Cualquier otro error se propaga. Creo que no deberíamos llegar aquí salvo
     * por errores de programación.
     */
    throw error;
  }
};

export const cancelBooking = (): ThunkAction<void, AppState, void, Action> => async dispatch => {
  dispatch(loadView(ViewId.searcher, undefined, true));
  dispatch(startNewBooking());
  dispatch(reset());
};

export const setContact = (contact?: Contact): SetContact => ({ contact, type: SET_CONTACT });

/* Ahora miso únicament se propaga el método pues sirve el mismo tal cual. */
export { setDiscount } from "~/services/booking";

export const setPayment = (payment?: Payment): SetPayment => ({ payment, type: SET_PAYMENT });

export const postBookingConfirm = (booking: Booking): ThunkAction<void, AppState, void, Action> => async dispatch => {
  try {
    const confirmedBooking = await dispatch(confirm(booking));
    dispatch(loadView(ViewId.booking, { booking: confirmedBooking, allowStartNewBooking: true }, true));
    dispatch(startNewBooking());
    dispatch(reset());
  } catch (error) {
    /*
     * Si falla la confirmación, ha petado y punto. Se propaga para que se haga
     * reset del estado.
     */
    // TODO: Si es ApiError o timeout se podría hacer una consulta al servidor para ver si se ha cerrado o no.
    logger.error("Error on confirm booking", error);
    handleError(error, true, dispatch);
  }
};

export const postTicketCancel = (ticketNumber: string): ThunkAction<void, AppState, void, Action> => async dispatch => {
  try {
    await dispatch(removeTicket(ticketNumber));
    dispatch(loadView(ViewId.confirmBooking));
  } catch (error) {
    logger.error("Error on ticket cancel", error);
    /*
     * ¿Qué podemos hacer si falla? A saber cómo ha quedado en servidor y dmt.
     * A mí no se me ocurre nada mejor que propagar.
     */
    handleError(error, true, dispatch);
  }
};

export function reset(): Reset {
  return { type: RESET };
}
