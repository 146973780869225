import { Reducer } from "redux";

import { ExcursionHash, SearchViewState } from "./types";

import { SearchViewAction } from "./actions";

import { CodeName, Excursion } from "~/services/webapi/types";
import {
  SET_AGENCIES,
  SET_DESTINATION,
  SET_DESTINATIONS,
  SET_DURATION,
  SET_EXCURSION,
  SET_EXCURSION_TYPE,
  SET_EXCURSIONS,
  SET_FEATURE,
  SET_MODALITY,
  SET_SEGMENTATION,
} from "./actions";

/** Estado por defecto */
const defaultState: SearchViewState = {};

/**
 *  Conjunto de valores del estado que representan los filtros
 * seleccionados por el usuario
 */
type Filters = Pick<
  SearchViewState,
  "destination" | "duration" | "excursion" | "excursionType" | "feature" | "modality" | "segmentation"
>;

/**
 * Servirá para limpiar los filtros del estado
 */
const clearedFilters: Filters = {
  destination: undefined,
  duration: undefined,
  excursion: undefined,
  excursionType: undefined,
  feature: undefined,
  modality: undefined,
  segmentation: undefined,
};

/** Reducer */
const reducer: Reducer<SearchViewState, SearchViewAction> = (state = defaultState, action) => {
  const filters = { ...clearedFilters };
  switch (action.type) {
    case SET_AGENCIES:
      return { ...state, agencies: action.agencies };

    case SET_DESTINATION:
      if (state.destination === action.destination) {
        break;
      }

      return {
        ...defaultState,
        agencies: state.agencies,
        destination: action.destination,
        destinations: state.destinations,
      };

    case SET_DESTINATIONS:
      return {
        ...defaultState,
        agencies: state.agencies,
        destinations: action.destinations,
      };

    case SET_DURATION:
      if (state.duration === action.duration) {
        break;
      }
      filters.destination = state.destination;
      filters.excursionType = state.excursionType;
      filters.excursion = state.excursion;
      filters.segmentation = state.segmentation;
      filters.feature = state.feature;
      filters.duration = action.duration;

      return updateState(state, state.allExcursions, filters);
    case SET_EXCURSION:
      if (state.excursion === action.excursion) {
        break;
      }
      filters.destination = state.destination;
      filters.excursionType =
        state.excursionType || findExcursionType(state.allExcursions, state.destination, action.excursion);
      filters.excursion = action.excursion;

      return updateState(state, state.allExcursions, filters);
    case SET_EXCURSION_TYPE:
      if (state.excursionType === action.excursionType) {
        break;
      }
      filters.destination = state.destination;
      filters.excursionType = action.excursionType;

      return updateState(state, state.allExcursions, filters);
    case SET_EXCURSIONS: {
      const { destinationCode, excursions: excursionsList } = action;

      let allExcursions = {};
      if (state.allExcursions) {
        allExcursions = { ...state.allExcursions };
      } else {
        allExcursions = {};
      }
      allExcursions[destinationCode] = excursionsList || [];
      filters.destination = state.destination;

      return updateState(state, allExcursions, filters);
    }
    case SET_FEATURE:
      if (state.feature === action.feature) {
        break;
      }
      filters.destination = state.destination;
      filters.excursionType = state.excursionType;
      filters.excursion = state.excursion;
      filters.segmentation = state.segmentation;
      filters.feature = action.feature;

      return updateState(state, state.allExcursions, filters);
    case SET_MODALITY:
      if (state.modality === action.modality) {
        break;
      }

      return { ...state, modality: action.modality };
    case SET_SEGMENTATION:
      if (state.segmentation === action.segmentation) {
        break;
      }
      filters.destination = state.destination;
      filters.excursionType = state.excursionType;
      filters.excursion = state.excursion;
      filters.segmentation = action.segmentation;

      return updateState(state, state.allExcursions, filters);
    default:
      break;
  }

  return state;
};

/** "Map<String, CodeName>" */
type CodeNameHash = {
  [P in string]: CodeName;
};

/**
 * Recupera el tipo de una excursión
 *
 * @param allExcursions Contiene las excursiones por destino
 * @param destination código de destino
 * @param excursionCode código de excursión
 */
function findExcursionType(
  allExcursions: ExcursionHash | null | undefined,
  destination?: string | null,
  excursionCode?: string | null
): string | undefined {
  if (excursionCode && allExcursions && destination) {
    const excursions = allExcursions[destination];
    for (const excursion of excursions || []) {
      if (excursion.code === excursionCode) {
        return excursion.type.code;
      }
    }
  }

  return undefined;
}

/**
 * Devuelve un estado a partir de los filtros.
 *
 * Genera las opciones de selección (tipos de excursión, modalidades,
 * duraciones...) a partir de los filtros
 *
 * @param state estado
 * @param allExcursions Excursiones por destino
 * @param filters filtros
 */
function updateState(
  state: SearchViewState,
  allExcursions: ExcursionHash | null | undefined,
  filters: Filters
): SearchViewState {
  return { ...state, ...filters, allExcursions, ...getFilterOptions(allExcursions, filters) };
}

/**
 * Devuelve la lista de excursiones que cumplen los filtros
 *
 * @param excursions Excursiones por destino
 * @param filters filtros a aplicar
 */
function filterExcursions(excursions: ExcursionHash | undefined | null, filters: Filters): Excursion[] {
  let excursionList: Excursion[] | undefined;

  if (excursions) {
    if (filters) {
      if (filters.destination) {
        excursionList = excursions[filters.destination];
      } else {
        excursionList = excursions.values;
      }
      if (excursionList) {
        return excursionList.filter(excursion => {
          if (filters.excursionType && excursion.type.code !== filters.excursionType) {
            return false;
          }
          if (filters.excursion && excursion.code !== filters.excursion) {
            return false;
          }

          if (filters.duration || filters.feature || filters.segmentation || filters.modality) {
            let durationFound = !filters.duration;
            let featureFound = !filters.feature;
            let segmentationFound = !filters.segmentation;
            let modalityFound = !filters.modality;
            for (const modality of excursion.modalities) {
              if (filters.modality && modality.code === filters.modality) {
                modalityFound = true;
              }
              if (filters.duration && filters.duration === modality.duration) {
                durationFound = true;
              }
              if (!featureFound && filters.feature) {
                for (const feature of modality.features || []) {
                  if (feature.code === filters.feature) {
                    featureFound = true;
                    break;
                  }
                }
              }
              if (filters.segmentation) {
                for (const segment of modality.segments || []) {
                  if (segment.code === filters.segmentation) {
                    segmentationFound = true;
                    break;
                  }
                }
              }
            }

            return modalityFound && durationFound && featureFound && segmentationFound;
          }

          return true;
        });
      }
    }

    return excursions.values || [];
  }

  return [];
}

/**
 * Genera las listas de opciones (modalidades, duraciones, caractetísticas, ...)
 * a partir de los filtros seleccionados
 *
 * @param allExcursions Excursiones por destino
 * @param filters filtros
 */
function getFilterOptions(allExcursions: ExcursionHash | null | undefined, filters: Filters): Partial<SearchViewState> {
  const durations: CodeNameHash = {};
  const excursionTypes: CodeNameHash = {};
  const features: CodeNameHash = {};
  const modalities: CodeNameHash = {};
  const segmentations: CodeNameHash = {};
  if (allExcursions) {
    for (const excursionList of Object.values(allExcursions)) {
      for (const exc of excursionList || []) {
        excursionTypes[exc.type.code] = { code: exc.type.code, name: exc.type.name };
      }
    }
  }

  const excursions = filterExcursions(allExcursions, filters);

  if (excursions) {
    excursions.forEach(excursion => {
      if (filters.excursion && filters.excursion === excursion.code) {
        excursion.modalities.forEach(modality => {
          let featureFilterPass = !filters.feature;
          let segmentFilterPass = !filters.segmentation;

          if (modality.segments) {
            modality.segments.forEach(segment => {
              segmentations[segment.code] = { code: segment.code, name: segment.name };
              if (filters.segmentation && filters.segmentation === segment.code) {
                segmentFilterPass = true;
              }
            });
          }
          if (!segmentFilterPass) {
            return;
          }

          if (modality.features) {
            modality.features.forEach(feature => {
              features[feature.code] = { code: feature.code, name: feature.name };
              if (filters.feature && filters.feature === feature.code) {
                featureFilterPass = true;
              }
            });
          }

          if (!featureFilterPass) {
            return;
          }

          const durationFilterPass: boolean = !filters.duration || filters.duration === modality.duration;
          durations[modality.duration] = { code: modality.duration, name: modality.duration };

          if (durationFilterPass && segmentFilterPass && featureFilterPass) {
            modalities[modality.code] = { code: modality.code, name: modality.name };
          }
        });
      }
    });
  }

  const nameComparator = (a: { name: string }, b: { name: string }) => (a.name || "").localeCompare(b.name);

  const result = {
    durations: Object.values(durations).sort(nameComparator),
    excursionTypes: Object.values(excursionTypes).sort(nameComparator),
    excursions: filterExcursions(allExcursions, {
      destination: filters.destination,
      excursionType: filters.excursionType,
    }).sort(nameComparator),
    features: Object.values(features).sort(nameComparator),
    modalities: Object.values(modalities).sort(nameComparator),
    segmentations: Object.values(segmentations).sort(nameComparator),
  };

  return result;
}

export default reducer;
