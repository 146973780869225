/* eslint-disable react/prop-types */ // TODO Fix
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Booking, ExcursionTicket } from "~/services/webapi/types";

import TicketForm from "~/components/ExcursionTicket";

import BookingContact from "./BookingContact";
import BookingHeader from "./BookingHeader";
import BookingPayment from "./BookingPayment";

const useStyles = makeStyles(() =>
  createStyles({
    printAllButton: {
      marginBottom: 8,
      marginTop: 8,
    },
    root: {
      alignSelf: "stretch",
      display: "flex",
      flex: "1 0",
      flexDirection: "column",
    },
    showCancelledButton: {
      alignSelf: "center",
      marginBottom: 8,
      marginTop: 8,
    },
    spacer: { flexGrow: 1 },
  })
);

interface BookingFormProps {
  /** La reserva. */
  booking: Booking;

  /** Indica si se muestra en modo edición. */
  editionEnabled?: boolean;

  /** Invocado cuando se quiere eliminar un ticket. */
  onCancelTicket?: (bookingNumber: string, ticketNumber: string) => void;

  /** Invocado cuando se modifica un dato de contacto. */
  onChangeContact?: (field: string, value: string) => void;

  /** Invocado cuando se modifica un dato de contacto. */
  onChangeTicket?: (ticketNumber: string, field: string, value: string) => void;

  /** Invocado cuando se quiere imprimir todos los tickets. */
  onPrintAllTickets: (booking: Booking) => void;

  /** Invocado cuando se queire imprimir un ticket. */
  onPrintTicket: (booking: Booking, ticketNumber: string) => void;
}

/**
 * componente para visualizar o editar la reserva.
 */
const BookingForm: React.FC<BookingFormProps & InjectedI18nProps> = props => {
  const {
    booking,
    editionEnabled,
    i18n: { formatMessage },
    onCancelTicket,
    onChangeContact,
    onChangeTicket,
    onPrintAllTickets,
    onPrintTicket,
  } = props;

  /*
   * Estado.
   */
  const classes = useStyles();

  const [cancelledVisible, setCancelledVisible] = useState(false);

  /**
   * Efecto inicialización estado
   */
  useEffect(() => {
    setCancelledVisible(booking.tickets.find(tk => tk.status !== "CANCELLED") == null);
  }, [booking.tickets]);

  /*
   * Callbacks.
   */
  const cancelTicket = useCallback(
    (ticketNumber: string) => onCancelTicket && onCancelTicket(booking.bookingNumber, ticketNumber),
    [booking.bookingNumber, onCancelTicket]
  );

  const printTicket = useCallback((ticketNumber: string) => onPrintTicket(booking, ticketNumber), [
    booking,
    onPrintTicket,
  ]);

  const printAllTickets = useCallback(() => onPrintAllTickets(booking), [booking, onPrintAllTickets]);

  const toggleCancelled = useCallback(() => setCancelledVisible(visible => !visible), []);

  /*
   * Renderizado.
   */
  const { confirmedTickets, cancelledTickets } = useMemo(
    () =>
      booking.tickets.reduce(
        (accu, ticket) => {
          (ticket.status === "CANCELLED" ? accu.cancelledTickets : accu.confirmedTickets).push(ticket);

          return accu;
        },
        { cancelledTickets: [] as ExcursionTicket[], confirmedTickets: [] as ExcursionTicket[] }
      ),
    [booking.tickets]
  );

  const hasCancelledTickets = cancelledTickets.length > 0;
  const hasConfirmedTickets = confirmedTickets.length > 0;

  const showPrintAllButton = hasConfirmedTickets && confirmedTickets.length > 1 && !editionEnabled;
  const showCancelledToggleButton = hasCancelledTickets && hasConfirmedTickets;

  return (
    <div className={classes.root}>
      <BookingHeader booking={booking} />

      {confirmedTickets.map((ticket, index) => (
        <TicketForm
          key={index}
          editionEnabled={editionEnabled}
          onCancelTicket={ticket.cancellable ? cancelTicket : undefined}
          onFieldChange={onChangeTicket}
          onPrintTicket={printTicket}
          ticket={ticket}
        />
      ))}

      {showCancelledToggleButton && (
        <Button
          variant="text"
          className={classes.showCancelledButton}
          color="default"
          onClick={toggleCancelled}
          startIcon={cancelledVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        >
          {formatMessage(cancelledVisible ? "bookingView.hideCancelled" : "bookingView.showCancelled")}
        </Button>
      )}

      {cancelledVisible &&
        cancelledTickets.map((ticket, index) => <TicketForm key={index} onPrintTicket={printTicket} ticket={ticket} />)}

      <BookingContact contact={booking.contact} editionEnabled={editionEnabled} onFieldChange={onChangeContact} />
      <BookingPayment payment={booking.payment} />

      <div className={classes.spacer} />

      {showPrintAllButton && (
        <Button className={classes.printAllButton} color="primary" onClick={printAllTickets} variant="contained">
          {formatMessage("bookingView.printAllTickets")}
        </Button>
      )}
    </div>
  );
};

export default withI18n(BookingForm);
