import React from "react";

import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

//     <g fill="none" strokeWidth="2" strokeLinecap="butt" stroke="currentColor">
const ErrorFace: React.SFC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 1000 1000" {...props}>
    <g>
      {/* eslint-disable-next-line max-len */}
      <path d="M500,10C229.4,10,10,229.4,10,500s219.4,490,490,490s490-219.4,490-490S770.6,10,500,10z M231.1,570.6L170,509.5l81.5-81.5L170,346.4l61.2-61.2l81.5,81.5l81.5-81.5l61.1,61.1l-81.5,81.5l81.5,81.5l-61.1,61.1l-81.5-81.5L231.1,570.6z M500,845.9c-79.6,0-144.1-38.7-144.1-86.5c0-47.8,64.5-86.5,144.1-86.5s144.1,38.7,144.1,86.5C644.1,807.2,579.6,845.9,500,845.9z M830,509.5l-61.1,61.1l-81.5-81.5l-81.5,81.5l-61.1-61.1l81.5-81.5l-81.5-81.5l61.1-61.1l81.5,81.5l81.5-81.5l61.1,61.1l-81.5,81.5L830,509.5z" />
    </g>
  </SvgIcon>
);

export default ErrorFace;
