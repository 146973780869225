import React from "react";
import { I18nContextConsumer } from "./I18nProvider";
import { I18nAPI } from "./types";

/** Definición de propiedades que se inyectarán en el componente */
export interface InjectedI18nProps {
  /** API de traducción */
  i18n: I18nAPI;
}

/** Inyector del API de traducción */
export default function withI18n<P extends InjectedI18nProps>(
  component: React.ComponentType<P>
): React.ComponentType<Pick<P, Exclude<keyof P, keyof InjectedI18nProps>>> {
  /** Wrappwer del API de traducción */
  return class WithI18n extends React.PureComponent<Pick<P, Exclude<keyof P, keyof InjectedI18nProps>>> {
    public render() {
      const Component = component;

      // TODO: Quitar el casting "as P" cuando arreglen https://github.com/Microsoft/TypeScript/issues/28938
      return (
        <I18nContextConsumer>
          {(context: I18nAPI) => <Component {...(this.props as P)} i18n={context} />}
        </I18nContextConsumer>
      );
    }
  };
}
