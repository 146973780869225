import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import ViewController, { Props, ViewDef, ViewDefProps } from "~/components/ViewController";
import { AppState } from "~/state";

import { loadPreviousView, loadView } from "./actions";
import { ViewId } from "./types";

/** Propiedades derivadas del estado. */
type StateProps = Pick<Props<ViewId>, "currentView">;
const mapStateToProps: MapStateToProps<StateProps, void, AppState> = state => ({
  currentView: state.appView.current,
  viewPayload: state.appView.payload,
});

/** Propiedades a partir de acciones. */
type DispatchProps = Pick<Props<ViewId>, "loadView" | "loadPreviousView">;
const mapDispatchToProps: MapDispatchToProps<DispatchProps, void> = {
  loadPreviousView,
  loadView,
};

/**
 * Elemento para definir las vistas.
 * Simplement se reexporta ViewDef forzando el tipado.
 */
export const AppViewDef = ViewDef as React.ComponentClass<ViewDefProps<ViewId>>;

export default connect(mapStateToProps, mapDispatchToProps)(ViewController);
