import { Reducer } from "redux";

import { BookingProcessState, TicketTemplate } from "./types";

import { BookingAction, UPDATE_TEMPLATE } from "./booking";
import {
  SET_AGENCY,
  SET_BOOKING,
  SET_BOOKING_DISCOUNT,
  SET_BOOKING_DISCOUNTS,
  SET_TICKET_DISCOUNT,
  SET_TICKET_DISCOUNTS,
  START_NEW_BOOKING,
} from "./booking";

/** Estado por defecto. */
const defaultState: BookingProcessState = { booking: null };

/** Reducer */
const reducer: Reducer<BookingProcessState, BookingAction> = (state = defaultState, action) => {
  switch (action.type) {
    case SET_AGENCY:
      return { ...state, agency: action.agency };

    case SET_BOOKING:
      return { ...state, booking: action.booking };

    case SET_BOOKING_DISCOUNT:
      return { ...state, bookingDiscount: action.discount };

    case SET_BOOKING_DISCOUNTS:
      return { ...state, bookingDiscounts: action.discounts };

    case SET_TICKET_DISCOUNT:
      return { ...state, ticketDiscount: action.discount };

    case SET_TICKET_DISCOUNTS:
      return { ...state, ticketDiscounts: action.discounts };

    case START_NEW_BOOKING:
      return {
        ...state,
        booking: undefined,
        bookingDiscount: undefined,
        bookingDiscounts: undefined,
        ticketDiscount: undefined,
        ticketDiscounts: undefined,
        ticketTemplate: {
          ...state.ticketTemplate,
          hotelReference: undefined,
          paxes: undefined,
          remarks: undefined,
        },
      };

    case UPDATE_TEMPLATE: {
      const ticket = action.ticket;

      const ticketTemplate: TicketTemplate = {
        ...state.ticketTemplate,
        languageCode: ticket.language ? ticket.language.code : undefined,
        paxes: ticket.paxes,
        remarks: ticket.remarks,
      };

      /* Estos se actualizan únicamente si vienen informados. */
      if (ticket.hotel) {
        ticketTemplate.hotelCode = ticket.hotel.code;
        ticketTemplate.hotelZoneCode = ticket.hotel.zoneCode;
        ticketTemplate.hotelReference = ticket.hotelReference;
      }

      if (ticket.pickupPoint) {
        ticketTemplate.pickupPointCode = ticket.pickupPoint.code;
      }

      return {
        ...state,
        ticketTemplate,
      };
    }

    default:
      return state;
  }
};

export default reducer;
