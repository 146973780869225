/// <reference types="cordova-plugin-device" />

import client from "./client";
import { DeviceInfo, Level } from "./types";

function getDeviceInfo(): DeviceInfo {
  if (device != null) {
    const manufacturer = device.manufacturer;
    const model = device.model;
    const platform = device.platform;
    const serial = device.serial || "UNKNOWN";
    const version = device.version;

    return {
      manufacturer,
      model,
      platform,
      serial,
      version,
    };
  }

  return {
    serial: "UNKNOWN",
  };
}

/**
 * Log remoto
 *
 * @param level: Nivel de log
 * @param message: mensaje
 */
export async function remoteLog(level: Level, message: string): Promise<void> {
  const deviceInfo = getDeviceInfo();
  const endpoint = `/log/terminals/${deviceInfo.serial}`;

  await client.post(endpoint, { level, message, deviceInfo });
}
