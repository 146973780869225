import React from "react";

import TextField from "@material-ui/core/TextField";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

interface Props {
  formControlClassName?: string;
  name?: string;
  onChangeName: (value?: string) => void;
  onChangePhone: (value?: string) => void;
  phone?: string;
}

interface ProvidedProps extends Props, InjectedI18nProps {}

const inputProps = {
  name: { maxLength: 50 },
  phone: { maxLength: 20 },
};

class ContactView extends React.PureComponent<ProvidedProps, {}> {
  public render() {
    const { formControlClassName, name, phone } = this.props;
    const { formatMessage } = this.props.i18n;

    return (
      <>
        <TextField
          className={formControlClassName}
          label={formatMessage("contactView.name")}
          inputProps={inputProps.name}
          margin="dense"
          value={name}
          onChange={this.handleChangeName}
        />
        <TextField
          className={formControlClassName}
          label={formatMessage("contactView.phone")}
          margin="dense"
          inputProps={inputProps.phone}
          type="tel"
          value={phone}
          onChange={this.handleChangePhone}
        />
      </>
    );
  }

  private handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeName(event.currentTarget.value);
  };

  private handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangePhone(event.currentTarget.value);
  };
}

export default withI18n(ContactView);
