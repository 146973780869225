import React from "react";
import BottomButtonsPane from "./BottomButtonsPane";

import Button from "@material-ui/core/Button";
import withStyles, { StyleRules, WithStyles } from "@material-ui/core/styles/withStyles";

type ClassKeys = "fill";

interface Props extends WithStyles<ClassKeys> {
  backLabel: string;
  nextEnabled?: boolean;
  nextLabel: string;
  onBack: () => void;
  onNext: () => void;
}

const styles: StyleRules<ClassKeys> = {
  fill: {
    flexGrow: 1,
    flexShrink: 1,
  },
};

class TwoButtonsBottomPane extends React.PureComponent<Props, {}> {
  public render() {
    return (
      <BottomButtonsPane>
        <Button variant="outlined" color="default" onClick={this.handleBackClick}>
          {this.props.backLabel}
        </Button>
        <Button variant="contained" color="primary" onClick={this.handleNextClick} disabled={!this.props.nextEnabled}>
          {this.props.nextLabel}
        </Button>
      </BottomButtonsPane>
    );
  }

  private handleBackClick = () => {
    this.props.onBack();
  };
  private handleNextClick = () => {
    this.props.onNext();
  };
}

export default withStyles(styles)(TwoButtonsBottomPane);
