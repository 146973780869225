export { default } from "./ViewController";
export { default as ViewDef } from "./ViewDef";

import { Props as TProps } from "./ViewController";
export type Props<V> = TProps<V>;

import { ViewDefProps as TViewDefProps } from "./ViewDef";
export type ViewDefProps<V> = TViewDefProps<V>;

import { ViewPayload as TViewPayload } from "./types";
export type ViewPayload = TViewPayload;
