import client from "./client";
import * as types from "./types";

/**
 * Recupera el listado de excursiones cargadas en el sistema. Opcionalmente se
 * puede filtrar por código de destino.
 *
 * @param destinationCode   el código de destino
 * @param agencyCode        el código de agencia
 */
export async function find(destinationCode?: string, agencyCode?: string): Promise<types.Excursion[]> {
  const response = await client.get("/excursions", { params: { agencyCode, destinationCode } });

  return response.data;
}

/**
 * Recupera todas las opciones reservables de una modalidad en un rango de días.
 *
 * @param excursionCode   el código de la excursión
 * @param modalityCode    el código de la modalidad
 * @param agencyCode      el código de agencia
 * @param dateFrom        el primer día solicitado
 * @param dateTo          el último día solicitado
 */
export async function findTicketOptions(
  excursionCode: string,
  modalityCode: string,
  agencyCode: string | undefined,
  dateFrom: types.LocalDateString,
  dateTo: types.LocalDateString
): Promise<types.ExcursionTicketOption[]> {
  const response = await client.get(`/excursions/${excursionCode}/modalities/${modalityCode}/options`, {
    params: { agencyCode, dateFrom, dateTo },
  });

  return response.data;
}

/**
 * Recupera los datos para reservar el ticket en el día indicado.
 *
 * @param excursionCode   el código de la excursión
 * @param modalityCode    el código de la modalidad
 * @param agencyCode      el código de agencia
 * @param date            el día para el que se solicita
 */
export async function getTicketOption(
  excursionCode: string,
  modalityCode: string,
  agencyCode: string | undefined,
  date: types.LocalDateString
): Promise<types.ExcursionTicketOption> {
  const response = await client.get(`/excursions/${excursionCode}/modalities/${modalityCode}/options`, {
    params: { agencyCode, date },
  });

  return response.data;
}
