import { LocalDate } from "js-joda";
import logger from "../logger";
import client from "./client";
import { Booking, BookingStatus, ExcursionTicket, PageView } from "./types";

// TODO: Ver realmente los datos que se deben pasar a cada método

/**
 * Añade un ticket a la reserva indicada.
 *
 * @param bookingNumber   el localizador de la reserva
 * @param ticket          el ticket a añadir
 */
export async function addTicket(bookingNumber: string, ticket: Partial<ExcursionTicket>): Promise<Booking> {
  const response = await client.post(`/bookings/${bookingNumber}/tickets`, ticket);

  return response.data;
}

/**
 * Confirmación de la reserva. Se debe facilitar la reserva con la información
 * de contacto y pago.
 *
 * @param booking         la reserva a confirmar
 */
export async function confirm(booking: Partial<Booking>): Promise<Booking> {
  const response = await client.post(`/bookings/${booking.bookingNumber}/commands/confirm`, booking);

  return response.data;
}

/**
 * Crea una nueva reserva nueva para el ticket indicado.
 *
 * @param ticket    el ticket a reservar
 */
export async function create(ticket: Partial<ExcursionTicket>): Promise<Booking> {
  const response = await client.post("/bookings", ticket);

  return response.data;
}

const fld = (date?: LocalDate) => (date ? date.toString() : undefined);

const fls = (str?: string) => (str ? str : undefined);

/**
 * Busca las reservas del usuario según el criterio indicado.
 *
 * @param reference       el número de ticket o reserva
 * @param creationFrom    la fecha mínima de creación
 * @param creationTo      la fecha máxima de creación
 * @param excursionFrom   la fecha mínima de inicio
 * @param excursionTo     la fecha máxima de inicio
 * @param status          el estado de la reserva
 */
// TODO Crear un tipo criteria
export async function find(
  reference?: string,
  sellerCode?: string,
  contactName?: string,
  creationFrom?: LocalDate,
  creationTo?: LocalDate,
  excursionFrom?: LocalDate,
  excursionTo?: LocalDate,
  status?: BookingStatus,
  page?: number,
  pageSize?: number
): Promise<PageView<Booking>> {
  const response = await client.get("/bookings", {
    params: {
      contactName: fls(contactName),
      creationFrom: fld(creationFrom),
      creationTo: fld(creationTo),
      excursionFrom: fld(excursionFrom),
      excursionTo: fld(excursionTo),
      page,
      pageSize,
      reference: fls(reference),
      sellerCode: fls(sellerCode),
      status,
    },
  });

  logger.info("response: ", response);

  return {
    page: parseInt(response.headers["pagination-current-page"] || "1", undefined),
    pageCount: parseInt(response.headers["pagination-total-pages"], undefined),
    pageSize: parseInt(response.headers["pagination-page-size"], undefined),
    totalCount: parseInt(response.headers["pagination-total-count"], undefined),
    values: response.data || [],
  };
}

/**
 * Recupera la reserva indicada.
 *
 * @param bookingNumber   el localizador de la reserva
 */
export async function get(bookingNumber: string): Promise<Booking> {
  const response = await client.get(`/bookings/${bookingNumber}`);
  // TODO: Decidir qué hacer si no se encuentra y recibimos un 404

  return response.data;
}

/**
 * Elimina el ticket de la reserva indicada.
 *
 * @param bookingNumber   el localizador de la reserva
 * @param ticketNumber    el id del ticket a eliminar
 */
export async function removeTicket(bookingNumber: string, ticketNumber: string): Promise<Booking> {
  const response = await client.delete(`/bookings/${bookingNumber}/tickets/${ticketNumber}`);

  return response.data;
}

/**
 * Actualiza la reserva con los nuevos datos.
 */
export async function updateBooking(bookingData: Booking): Promise<Booking> {
  const response = await client.put(`/bookings/${bookingData.bookingNumber}`, bookingData);

  return response.data;
}
