import JsBarcode from "jsbarcode";

/** Configuración por defect. */
const DEFAULTS: JsBarcode.BaseOptions = {
  format: "CODE128",
  height: 150,
  width: 2,
};

/** Renderiza el código de barras en el canvas indicado.  */
export function renderBarcode(canvas: HTMLCanvasElement, data: string, options: JsBarcode.BaseOptions = DEFAULTS) {
  JsBarcode(canvas, data, { ...DEFAULTS, ...options });
}
