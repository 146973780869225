/*
 * Tipos y funciones genéricas "sueltas" que se usan con frecuencia en varios
 * sitios.
 */

/** Tipo para marcar como nullable a todos los miembros de un tipo. */
export type Nullable<T> = { [P in keyof T]: T[P] | null };

/**
 * Definición constante de una función que no hace nada.
 * Sirve cuando hace falta un callback obligatorio pero no queremos hacer nada.
 */
export const noop = () => undefined;

/** Tipo para crear un tipo Partial recursivo. */
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<RecursivePartial<U>>
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};
