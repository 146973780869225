import client from "./client";
import * as types from "./types";

/**
 * Recupera el listado de agencias del vendedor.
 */
export async function find(): Promise<types.Agency[]> {
  const response = await client.get("/agencies");

  return response.data;
}
