/* eslint-disable react/prop-types */ // TODO Fix

import React, { useMemo } from "react";

import Button from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { Booking } from "~/services/webapi/types";

import { BookingViewMode } from "../types";

const useStyles = makeStyles(() =>
  createStyles({
    actionButton: {
      marginBottom: 8,
      marginTop: 8,
    },
    actionsPanel: {
      display: "flex",
      flex: "0 0",
      flexDirection: "column",
    },
    actionsPanelRow: {
      "& $actionButton:not(:first-child)": {
        marginLeft: 8,
      },
      display: "flex",
      justifyContent: "flex-end",
    },
    root: {},
  })
);

interface BookingViewActionsProps {
  /** Indica si se permite editar la reserva. */
  allowEdition?: boolean;

  /** Indica si se permite iniciar una nueva reserva desde esta vista. */
  allowStartNewBooking?: boolean;

  /** La reserva a mostrar. */
  booking: Booking;

  /** La reserva en edición (no guardada). */
  formBooking?: Booking;

  /** El modo en el que se ecuentra la vista. */
  mode: BookingViewMode;

  /** Función invocada al cambiar el modo de la vista. */
  onChangeMode: (mode: BookingViewMode) => void;

  /** Función invocada al cancelar la edición.  */
  onEditCancel: () => void;

  /** Función invocada al guardar cambios. */
  onEditSave: () => void;

  /** Función invocada al solicitar empezar una nueva reserva. */
  onStartNewBooking?: () => void;
}

/**
 * Botonera de acciones permitidas en la vista.
 * Las acciones mostradas dependen en función del estado y contexto de la vista.
 *
 * TODO: [CTX/REDUX]  Hooks de contexto y redux para las props correspondientes.
 */
const BookingViewActions: React.FC<BookingViewActionsProps & InjectedI18nProps> = props => {
  const {
    allowEdition,
    allowStartNewBooking,
    booking,
    formBooking,
    i18n: { formatMessage },
    mode,
    onChangeMode,
    onEditCancel: editCancel,
    onEditSave: editSave,
    onStartNewBooking,
  } = props;

  const classes = useStyles();

  /* Callbacks */
  const enterEditMode = () => onChangeMode("editing");

  /* Render. */
  const hasConfirmedTickets = useMemo(() => booking.tickets.find(tk => tk.status === "CONFIRMED") != null, [booking]);

  const showEditGroup = allowEdition && hasConfirmedTickets;

  /*
   * Ahora mismo la única validación es que tenga nombre de contacto. Cuando
   * esto se complique, habrá que unificarlo.
   */
  const saveEnabled = formBooking && formBooking.contact && formBooking.contact.name && formBooking.contact.name.trim();

  let editGroup: React.ReactNode = false;

  if (showEditGroup) {
    if (mode === "default") {
      editGroup = (
        <Button
          className={classes.actionButton}
          color="default"
          onClick={enterEditMode}
          startIcon={<EditIcon />}
          variant="outlined"
        >
          {formatMessage("bookingView.editBooking")}
        </Button>
      );
    } else if (mode === "editing") {
      editGroup = (
        <>
          <Button
            className={classes.actionButton}
            color="default"
            onClick={editCancel}
            startIcon={<CloseIcon />}
            variant="outlined"
          >
            {formatMessage("bookingView.editCancel")}
          </Button>
          <Button
            className={classes.actionButton}
            color="primary"
            disabled={!saveEnabled}
            onClick={editSave}
            startIcon={<DoneIcon />}
            variant="contained"
          >
            {formatMessage("bookingView.editSave")}
          </Button>
        </>
      );
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.actionsPanelRow}>{editGroup}</div>
      {allowStartNewBooking && (
        <Button className={classes.actionButton} color="primary" onClick={onStartNewBooking} variant="contained">
          {formatMessage("bookingView.startNewBooking")}
        </Button>
      )}
    </div>
  );
};

export default withI18n(BookingViewActions);
