/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

/**
 * Contiene la implementación de las funciones de traducción y formateo del API
 */

import { DateTimeFormatter, LocalDate, LocalDateTime, LocalTime, YearMonth } from "js-joda";
import { FormattedDate, FormattedMessage, FormattedNumber, InjectedIntl, MessageValue } from "react-intl";
import { LocalDateString, LocalDateTimeString, LocalTimeString } from "../webapi/types";

/**
 * El componente al crearse establece aquí la instancia de react-intl inyectado
 */
let injectedIntl: InjectedIntl;

/** Establece la instancia de react-intl */
export function setInjectedIntl(injectected: InjectedIntl) {
  injectedIntl = injectected;
}

/**
 * Devuelve la instancia de react-intl inyectado, si no se ha inicializado
 * correctamente lanza un error
 */
function getInjectedIntl(): InjectedIntl {
  if (!injectedIntl) {
    throw Error("Intl not properly initialized.");
  }

  return injectedIntl;
}

/**
 * Pone en mayúscula la primera letra de un texto.
 */
function capitalizeFirstLetter(value?: string): string {
  if (!value) {
    return "";
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
}

/** Indica si existe la traducción de una etiqueta */
export function existMessage(id: string): boolean {
  return getMessage(id) ? true : false;
}

const INTL_DEFAULT_DATE_FORMAT_OPTIONS: ReactIntl.IntlComponent.DateTimeFormatProps = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  timeZone: "UTC",
};

const INTL_WEEKDAY_NAME_FORMAT_OPTIONS: ReactIntl.IntlComponent.DateTimeFormatProps = {
  weekday: "long",
};

/**
 * Formatea una fecha.
 *
 * @param value fecha a formatear
 * @param options opciones de formato
 */
export function formatDate(value?: LocalDateString | LocalDateTimeString, options?: FormattedDate.PropsBase): string {
  if (!value) {
    return "";
  }

  let date;

  const year = Number(value.substr(0, 4));
  const month = Number(value.substr(5, 2)) - 1;
  const day = Number(value.substr(8, 2));

  if (isLocalDateTimeString(value)) {
    const hours = Number(value.substr(11, 2));
    const minutes = Number(value.substr(14, 2));
    const seconds = Number(value.substr(17, 2));

    date = Date.UTC(year, month, day, hours, minutes, seconds);
  } else {
    date = Date.UTC(year, month, day);
  }

  if (options) {
    options = { ...options, timeZone: "UTC" };
  }

  return getInjectedIntl().formatDate(date, options || INTL_DEFAULT_DATE_FORMAT_OPTIONS);
}

/** Devuelve el nombre del día de la semana */
export function formatWeekdayName(value?: LocalDateString): string {
  return capitalizeFirstLetter(formatDate(value, INTL_WEEKDAY_NAME_FORMAT_OPTIONS));
}

/** Expone la función formatNumber de react-intl */
export function formatNumber(value?: number, options?: FormattedNumber.PropsBase): string {
  if (value == null) {
    return "";
  }

  return getInjectedIntl().formatNumber(value, options);
}

const CURRENCY_UNKNOWN_FORMAT: FormattedNumber.PropsBase = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  minimumIntegerDigits: 1,
};

/**
 * Formatea importe monetario
 *
 * @param value importe a formatear
 * @param currency divisa
 */
export function formatCurrency(value?: number, currency?: string) {
  if (value == null) {
    return "";
  }
  if (currency) {
    return getInjectedIntl().formatNumber(value, {
      currency,
      style: "currency",
    });
  }

  return getInjectedIntl().formatNumber(value, CURRENCY_UNKNOWN_FORMAT);
}

/** Expone la función formatMessage de react-intl */
export function formatMessage(
  messageDescriptor: FormattedMessage.MessageDescriptor | string,
  values?: { [key: string]: MessageValue }
): string {
  let md: FormattedMessage.MessageDescriptor;
  if (typeof messageDescriptor === "string") {
    md = { id: messageDescriptor };
  } else {
    md = messageDescriptor;
  }

  return getInjectedIntl().formatMessage(md, values);
}

/** Expone la propiedad locale de react-intl */
export function getLocale() {
  return getInjectedIntl().locale;
}

/** Devuelve la traducción de una etiqueta */
export function getMessage(id: string) {
  return getMessages()[id];
}

/** Expone la propiedad messages de react-intl */
export function getMessages(): { [id: string]: string } {
  return getInjectedIntl().messages;
}

/** Formateo de elementos JS-JODA */
const DEFAULT_LOCAL_DATE_TIME_FORMATTER = DateTimeFormatter.ofPattern("dd/MM/yyyy HH:mm:ss");
const DEFAULT_LOCAL_DATE_FORMATTER = DateTimeFormatter.ofPattern("dd/MM/yyyy");
const DEFAULT_LOCAL_TIME_FORMATTER = DateTimeFormatter.ofPattern("HH:mm");
const DEFAULT_YEAR_MONTH_FORMATTER = DateTimeFormatter.ofPattern("MM/yyyy");

/**
 * Formatea un LocalDate
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (dd/MM/yyyy)
 */
export function formatLocalDate(value?: LocalDate, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_LOCAL_DATE_FORMATTER;

  return value.format(formatter);
}

/**
 * Formatea un LocalDateString o un LocalDateTimeString
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (dd/MM/yyyy)
 */
export function formatLocalDateString(value?: LocalDateString | LocalDateTimeString, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_LOCAL_DATE_FORMATTER;
  if (isLocalDateTimeString(value)) {
    return LocalDateTime.parse(value).format(formatter);
  }

  return LocalDate.parse(value).format(formatter);
}

/**
 * Formatea un LocalDateTime
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (dd/MM/yyyy HH:mm:ss)
 */
export function formatLocalDateTime(value?: LocalDateTime, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_LOCAL_DATE_TIME_FORMATTER;

  return value.format(formatter);
}

/**
 * Formatea un LocalDateTimeString
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (dd/MM/yyyy HH:mm:ss)
 */
export function formatLocalDateTimeString(value?: LocalDateTimeString, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_LOCAL_DATE_TIME_FORMATTER;

  return LocalDateTime.parse(value).format(formatter);
}

/**
 * Formatea un LocalTime
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (HH:mm)
 */
export function formatLocalTime(value?: LocalTime, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_LOCAL_TIME_FORMATTER;

  return value.format(formatter);
}

/**
 * Formatea un LocalTimeString
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (HH:mm)
 */
export function formatLocalTimeString(value?: LocalTimeString, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_LOCAL_TIME_FORMATTER;

  return LocalTime.parse(value).format(formatter);
}

/**
 * Formatea un YearMonth
 *
 * @param value elemento a formatear
 * @param pattern patrón a utilizar, si no se informa se utilizará el patrón por
 *    defecto (MM/yyyy)
 */
export function formatYearMonth(value?: YearMonth, pattern?: string): string {
  if (value == null) {
    return "";
  }

  const formatter = pattern ? DateTimeFormatter.ofPattern(pattern) : DEFAULT_YEAR_MONTH_FORMATTER;

  return value.format(formatter);
}

/**
 * Indica si una fecha es un LocalDateTimeString.
 *
 * @param date fecha a comprobar
 */
function isLocalDateTimeString(date: LocalDateString | LocalDateTimeString): date is string {
  return (date as LocalDateTimeString).includes("T");
}
