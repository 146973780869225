/* eslint-disable react/prop-types */ // TODO Fix

import React from "react";

import red from "@material-ui/core/colors/red";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import LabeledText from "~/components/LabeledText";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { ExcursionTicket } from "~/services/webapi/types";

const useStyles = makeStyles(() =>
  createStyles({
    cancelled: {
      color: red[500],
    },
  })
);

interface TicketHeaderProps {
  ticket: ExcursionTicket;
}

/**
 * Información principal que define el ticket.
 */
const TicketHeader: React.FC<TicketHeaderProps & InjectedI18nProps> = props => {
  const {
    i18n: { formatMessage },
    ticket: { excursionName, modalityName, status, ticketNumber },
  } = props;

  const classes = useStyles();

  const cancelled = status === "CANCELLED";
  const ticketLabel = (
    <>
      {formatMessage("excursionTicket.ticket")}
      {cancelled && <span className={classes.cancelled}> ({formatMessage("excursionTicket.cancelledLabel")})</span>}
    </>
  );

  return (
    <div>
      <LabeledText label={ticketLabel} text={ticketNumber} textSize="large" />

      <LabeledText label={formatMessage("excursionTicket.excursion")} text={excursionName} textSize="extraLarge" />

      <LabeledText label={formatMessage("excursionTicket.modality")} text={modalityName} textSize="large" />
    </div>
  );
};

export default withI18n(TicketHeader);
