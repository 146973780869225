/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import React from "react";

import classNames from "classnames";
import moize from "moize";

import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import BugIcon from "@material-ui/icons/BugReport";
import UserIcon from "@material-ui/icons/Face";
import ExitIcon from "@material-ui/icons/MeetingRoom";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import StoreIcon from "@material-ui/icons/Store";
import MyBookingsIcon from "@material-ui/icons/Work";

import QRIcon from "~/components/QRIcon";

import { ViewId } from "~/views";

import Environment from "~/environment";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

/**
 * Definición de los estilos por defecto.
 *
 * @param theme   el tema de la app
 */
const styles = (theme: Theme) =>
  createStyles({
    button: {
      color: "white",
      minWidth: "unset",
    },
    flexGrow: {
      flexGrow: 1,
    },
    icon: {
      color: "white",
      height: "0.85em",
      marginRight: theme.spacing(1),
      width: "0.85em",
    },
    iconButton: {
      color: "white",
    },
    qrButton: {
      padding: "7px 8px",
    },
    shoppingCardBadge: {
      backgroundColor: theme.palette.secondary.main,
      height: 18,
      width: 18,
    },
    userBox: {
      flexDirection: "column",
      flexGrow: 1,
      marginLeft: theme.spacing(2),
      width: 0,
    },
    userItem: {
      "& span": {
        color: "white",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      alignItems: "center",
      display: "flex",
      whiteSpace: "nowrap",
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles> {
  /** El nombre de la agencia a mostrar. */
  agency?: string | null;

  /** Indica el número de items en la cesta. */
  bookedItems?: number;

  /** La vista actual. */
  currentView: ViewId;

  /** Handler para el vento cerrar sesión. */
  onLogout: () => void;

  /** Handler para tratar el evento de cuando el usuario escoge una vista. */
  onViewSelect: (view: ViewId) => void;

  /** El nombre de usuario a mostrar. */
  user?: string | null;
}

/** El estado del componente. */
interface State {
  /** Referencia al elemento del botón desplegar para posicionar el menú. */
  anchorEl?: HTMLElement;

  /** Indica si el submenú de debug está desplegado */
  debugShow: boolean;

  /**
   * Referencia al elemento del botón desplegar para posicionar el menú de
   * idioma.
   */
  languageAnchorEl?: HTMLElement;
}

/** Posicionamiento entre el botón del menú y el desplegable. */
const menuAnchorPosition: PopoverOrigin = {
  horizontal: "right",
  vertical: "top",
};

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Wrapper para añadir el menú de la aplicación a las vistas.
 */
class HeaderBar extends React.PureComponent<ProvidedProps, State> {
  /** Estado por defecto. */
  public state: Readonly<State> = { debugShow: false };

  /**
   * Devuelve la función para tartar el evento de cuando el usuario selecciona
   * la opción de menú correspondiente a la vista indicada.
   *
   * Esta función evitar tener que crear una diferente para cada posible vista.
   */
  private getViewSelectHandler = moize((view: ViewId) => {
    return () => {
      this.handleViewSelect(view);
    };
  });

  /** #render */
  public render() {
    const { bookedItems, classes, currentView } = this.props;

    const { formatMessage } = this.props.i18n;
    const { anchorEl } = this.state;
    const open = anchorEl != null;

    return (
      <AppBar position="static">
        <Toolbar>
          <Button
            variant="contained"
            color="secondary"
            className={classNames(classes.button, classes.qrButton)}
            onClick={this.getViewSelectHandler(ViewId.qrScann)}
          >
            <QRIcon className={classes.iconButton} />
          </Button>
          <div className={classes.userBox}>
            <div className={classes.userItem}>
              <UserIcon className={classes.icon} />
              <span>{this.props.user}</span>
            </div>
            <div className={classes.userItem}>
              <StoreIcon className={classes.icon} />
              <span>{this.props.agency}</span>
            </div>
          </div>
          {bookedItems! > 0 && (
            <IconButton color="inherit" onClick={this.getViewSelectHandler(ViewId.confirmBooking)}>
              <Badge badgeContent={bookedItems} classes={{ badge: classes.shoppingCardBadge }}>
                <ShoppingCart />
              </Badge>
            </IconButton>
          )}
          {!Environment.APK && (
            // TODO: Ver si esto se puede controlar con "popstate" y para quitar este botón.
            <IconButton aria-label="Back" className={classes.iconButton} onClick={this.handleBack}>
              <ArrowBackIcon />
            </IconButton>
          )}
          <IconButton aria-label="Menu" className={classes.iconButton} onClick={this.handleMenuShow}>
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={menuAnchorPosition}
            open={open}
            transformOrigin={menuAnchorPosition}
            onClose={this.handleMenuClose}
          >
            <MenuItem onClick={this.getViewSelectHandler(ViewId.searcher)} disabled={currentView === ViewId.searcher}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText>{formatMessage("viewWrapper.menu.searcher")}</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={this.getViewSelectHandler(ViewId.bookingSearch)}
              disabled={currentView === ViewId.bookingSearch}
            >
              <ListItemIcon>
                <MyBookingsIcon />
              </ListItemIcon>
              <ListItemText>{formatMessage("viewWrapper.menu.bookingSearch")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.handleLogout}>
              <ListItemIcon>
                <ExitIcon />
              </ListItemIcon>
              <ListItemText>{formatMessage("viewWrapper.menu.logout")}</ListItemText>
            </MenuItem>
            {this.drawDebugMenuItem()}
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }

  private drawDebugMenuItem = () => {
    if (Environment.DEBUG) {
      const { debugShow } = this.state;
      const { formatMessage, getLocale, setLocale } = this.props.i18n;
      const currentLocale = getLocale();
      const ArrowDropIcon = debugShow ? ArrowDropUp : ArrowDropDown;

      return (
        <React.Fragment>
          <MenuItem onClick={this.handleToggleDebugMenuShow}>
            <ListItemIcon>
              <BugIcon />
            </ListItemIcon>
            <ListItemText>Debug</ListItemText>
            <ArrowDropIcon />
          </MenuItem>
          {debugShow && (
            <div>
              <Divider />
              <MenuItem
                onClick={() => {
                  setLocale("es");
                }}
                disabled={currentLocale === "es"}
              >
                {formatMessage("viewWrapper.language.spanish")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setLocale("en");
                }}
                disabled={currentLocale === "en"}
              >
                {formatMessage("viewWrapper.language.english")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.toggleSplashScreen();
                }}
              >
                Show splash screen
              </MenuItem>
              <MenuItem onClick={this.handleGenerateException}>Generate Exception</MenuItem>
            </div>
          )}
        </React.Fragment>
      );
    }

    return null;
  };

  /** Simulo el evento de pulsar el botón físico de volver en el dispositivo */
  private handleBack = () => {
    const backEvent = document.createEvent("Event");
    backEvent.initEvent("backbutton", false, false);
    document.dispatchEvent(backEvent);
  };

  private handleGenerateException = () => {
    throw new Error("Generated Debug Exception");
  };

  /** Trata el evento de que se ha seleccionado la opción de "salir". */
  private handleLogout = () => {
    this.props.onLogout();
    this.handleMenuClose();
  };

  /** Trata el evento de cerrar el menú. */
  private handleMenuClose = () => {
    this.setState({ anchorEl: undefined, languageAnchorEl: undefined });
  };

  /** Trata el evento de mostrar el menú. */
  private handleMenuShow = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  /** Trata el evento de mostrar el menú de selección de idioma. */
  private handleToggleDebugMenuShow = () => {
    this.setState({ debugShow: !this.state.debugShow });
  };

  /** Trata el evento de cuando se selecciona una vista determinada. */
  private handleViewSelect = (view: ViewId) => {
    this.handleMenuClose();
    this.props.onViewSelect(view);
  };

  /**
   * Genera un evento para mostrar la splash screen (finalidades de depuración)
   */
  private toggleSplashScreen = () => {
    const event = document.createEvent("Event");
    event.initEvent("toggleSplashScreen", false, false);
    document.dispatchEvent(event);
  };
}

export default withI18n(withStyles(styles)(HeaderBar));
