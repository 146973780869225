import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";

import { AppState } from "~/state";

import { fetchSellers } from "../../actions";
import BookingSearchFrom, { Props } from "./BookingSearcherForm";

const mapStateToProps: MapStateToProps<Pick<Props, "sellers">, void, AppState> = state => ({
  sellers: state.bookingSearchView.sellers,
});

const mapDispatchToProps: MapDispatchToProps<Pick<Props, "fetchSellers">, void> = {
  fetchSellers,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingSearchFrom);
