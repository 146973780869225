/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import { LocalDate, TemporalAdjusters } from "js-joda";

import { findTicketOptions, getTicketOption } from "~/services/webapi/excursions";
import * as types from "~/services/webapi/types";

export { find as findAgencies } from "~/services/webapi/agencies";
export { find as findDestinations } from "~/services/webapi/destinations";
export { find as findExcursions, findTicketOptions, getTicketOption } from "~/services/webapi/excursions";
export { find as findSellers } from "~/services/webapi/sellers";

/**
 * Busca la primera opción disponible dentro de N meses siguientes.
 * Se hace un máximo de N iteraciónes para evitar entrar en un bucle sin fin.
 *
 * @param excursionCode el código de excursión
 * @param modalityCode el código de modaliad
 * @param agencyCode el código de agencia
 */
/*
 * TODO: Ver si realmente basta para a 4 meses.
 * TODO: Si tenemos caché, otra opción sería pedir un año entero, debería ir
 * rápido.
 * TODO: Ver si implementar en servidor (cuando tengamos cachés sería bastante
 * rápido)
 */
export async function findFirstAvailableTicketOption(
  excursionCode: string,
  modalityCode: string,
  agencyCode?: string
): Promise<types.ExcursionTicketOption | undefined> {
  let fromDate = LocalDate.now();
  let toDate = fromDate.with(TemporalAdjusters.lastDayOfMonth());
  for (let idx = 0; idx < 4; idx++) {
    // TODO: Quitar el catch. Ahora la petición falla con 500 si no hay dispo. Cuando se arregle sobra el catch.
    const options = await findTicketOptions(
      excursionCode,
      modalityCode,
      agencyCode,
      fromDate.toString(),
      toDate.toString()
    ).catch(() => null);

    /*
     * Las opciones del rango vienen, o deberían, venir ordenadas por fechas.
     * Bastará comprobar que hay resultados y quedarnos con el priemro.
     */
    if (options && options.length > 0 && options[0].date) {
      return getTicketOption(excursionCode, modalityCode, agencyCode, options[0].date!);
    }

    fromDate = fromDate.plusMonths(1).with(TemporalAdjusters.firstDayOfMonth());
    toDate = fromDate.with(TemporalAdjusters.lastDayOfMonth());
  }

  return;
}
