/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import logger from "../logger";

type QRScannerCallback = (error: QRScannerError, status: QRScannerStatus) => void;

const onOpenSettings = (onScanQR: (error: QRScannerError, result?: string) => any): QRScannerCallback => {
  return (error: QRScannerError, status: QRScannerStatus): void => {
    if (error) {
      onScanQR(error);
      window.QRScanner.hide();
      window.QRScanner.destroy();
    } else {
      if (status.authorized) {
        scanQR(onScanQR);
      } else {
        onScanQR({ code: -1, name: "APPLICATION_ERROR", _message: "Not authorized" });
        window.QRScanner.hide();
        window.QRScanner.destroy();
      }
    }
  };
};

interface ScannerResultPoint {
  count: number;
  estimatedModuleSize: number;
  x: number;
  y: number;
}

/**
 * Cuando escaneamos desde browser en vez de devolver un string, está
 * devolviendo un objeto:
 *
 * {
 *   "result":"http://s.aliexpress.com/rMb2AjqM",
 *   "points":[
 *      {"x":553.5,"y":332.5,"count":2,"estimatedModuleSize":4.285714285714286},
 *      {"x":647.5,"y":329.5,"count":2,"estimatedModuleSize":4.285714285714286},
 *      {"x":650.5,"y":422,"count":2,"estimatedModuleSize":4.285714285714286},
 *      {"x":569,"y":412,"count":1,"estimatedModuleSize":4.333333333333333}
 *    ]
 * }
 */
interface ScannerResult {
  points: ScannerResultPoint[];
  result: string;
}

const onPrepare = (onScanQR: (error: QRScannerError, result?: string) => any): QRScannerCallback => {
  return (error: QRScannerError, status: QRScannerStatus): void => {
    if (error) {
      onScanQR(error);
      window.QRScanner.hide();
      window.QRScanner.destroy();
    } else {
      if (status.authorized) {
        window.QRScanner.show();
        window.QRScanner.scan((e: QRScannerError, r?: string | ScannerResult) => {
          if (r) {
            if (typeof r === "string") {
              onScanQR(e, r);
            } else {
              onScanQR(e, r.result);
            }
            window.QRScanner.hide();
            window.QRScanner.destroy();
          }
        });
      } else {
        if (status.canOpenSettings) {
          window.QRScanner.openSettings(onOpenSettings(onScanQR));
        } else {
          onScanQR({ code: -1, name: "APPLICATION_ERROR", _message: "Not authorized" });
          window.QRScanner.hide();
          window.QRScanner.destroy();
        }
      }
    }
  };
};

export function scanQR(callback: (error: QRScannerError, result?: string) => any): void {
  window.QRScanner.prepare(onPrepare(callback));
}

export function cancelScan(): Promise<QRScannerStatus> {
  return new Promise((resolve: (status?: QRScannerStatus) => any, reject: (reason: any) => any) => {
    try {
      window.QRScanner.hide(() => {
        window.QRScanner.destroy(destroyStatus => {
          resolve(destroyStatus);
        });
      });
    } catch (err) {
      logger.error("Unexpected error on cancelScan", err);
      reject(err);
    }
  });
}

export function getStatus(): Promise<QRScannerStatus> {
  return new Promise((resolve: (status?: QRScannerStatus) => any, reject: (reason: any) => any) => {
    try {
      window.QRScanner.getStatus(status => resolve(status));
    } catch (err) {
      reject(err);
    }
  });
}

export function turnLightOn(): Promise<QRScannerStatus> {
  return new Promise((resolve: (status?: QRScannerStatus) => any, reject: (reason: any) => any) => {
    try {
      window.QRScanner.enableLight((error, status) => {
        if (error) {
          reject(error);
        } else {
          resolve(status);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
}

export function turnLightOff(): Promise<QRScannerStatus> {
  return new Promise((resolve: (status?: QRScannerStatus) => any, reject: (reason: any) => any) => {
    try {
      window.QRScanner.disableLight((error, status) => {
        if (error) {
          reject(error);
        } else {
          resolve(status);
        }
      });
    } catch (err) {
      reject(err);
    }
  });
}
