import React from "react";

import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import CalendarIcon from "@material-ui/icons/Today";

import Calendar from "react-calendar";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";

import { LocalDate } from "js-joda";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import logger from "~/services/logger";

/** Estilos por defecto. */
const styles = () =>
  createStyles({
    icon: {
      padding: 0,
    },
    input: {
      flex: "1 1 auto",
    },
    root: {
      alignItems: "baseline",
      display: "flex",
      flex: "1 1 auto",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    separator: {
      flex: "1 1 auto",
      textAlign: "center",
    },
  });

/** Las propiedades del componente. */
interface Props extends WithStyles<typeof styles> {
  /** La fecha actual */
  date?: LocalDate;

  /** Etiqueta. */
  label?: string;

  /** La fecha máxima seleccionable */
  maxDate?: LocalDate;

  /** La fecha mínima seleccionable */
  minDate?: LocalDate;

  /** Handler para tratar cuando cambia el valor. */
  onChange?: (date?: LocalDate) => void;
}

interface ProviedProps extends Props, InjectedI18nProps {}

interface State {
  /** Inidica si la cortinilla debe estar visible o no. */
  open: boolean;
}

class CustomDatePicker extends React.PureComponent<ProviedProps, State> {
  public constructor(props: ProviedProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public render() {
    const { classes, label, date, minDate, maxDate, i18n } = this.props;
    /* Contamos con que toString de LocalDate usa el formatio ISO. */

    return (
      <FormControl className={classes.root}>
        <InputLabel htmlFor="dateStr">{label}</InputLabel>
        <Input
          className={classes.input}
          id="dateStr"
          onClick={() => {
            this.showCalendar();
          }}
          readOnly={true}
          value={date ? i18n.formatLocalDate(date) : ""}
        />
        <IconButton
          className={classes.icon}
          onClick={() => {
            this.showCalendar();
          }}
        >
          <CalendarIcon />
        </IconButton>
        <Dialog
          open={this.state.open}
          onClose={() => {
            this.hideCalendar();
          }}
        >
          {this.state.open && (
            <Calendar
              locale={i18n.getLocale()}
              value={date ? new Date(date.toString()) : new Date()}
              maxDate={maxDate ? new Date(maxDate.toString()) : undefined}
              minDate={minDate ? new Date(minDate.toString()) : undefined}
              onChange={newDate => {
                this.onDateChange(newDate);
              }}
            />
          )}
        </Dialog>
      </FormControl>
    );
  }

  private hideCalendar() {
    this.setState({ open: false });
  }

  private onDateChange(newDate: Date | Date[]) {
    const { onChange } = this.props;
    if (newDate && onChange) {
      const newDateAsDate = newDate as Date;
      try {
        const newLocalDate = LocalDate.of(
          newDateAsDate.getFullYear(),
          newDateAsDate.getMonth() + 1,
          newDateAsDate.getDate()
        );
        onChange(newLocalDate);
      } catch (err) {
        logger.error("Unexpected error building LocalDate from Date", err);
      }
    }
    this.hideCalendar();
  }

  private showCalendar() {
    this.setState({ open: true });
  }
}

export default withI18n(withStyles(styles)(CustomDatePicker));
