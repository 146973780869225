/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";

import ErrorIcon from "~/icons/ErrorFace";
import { InjectedI18nProps, withI18n } from "~/services/i18n";
import { ApiError, ConnectionTimeout, HostUnreachable } from "~/services/webapi/client/types";
import ErrorDetails from "./ErrorDetails";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    actions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      margin: theme.spacing(1),
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      justifyContent: "center",
    },
    icon: {
      fontSize: theme.typography.fontSize * 4,
      marginRight: theme.spacing(2),
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: theme.spacing(2),
    },
    title: {
      alignItems: "flex-start",
      display: "flex",
      justifyContent: "center",
    },
  });

/** Las propiedades del componente. */
export interface Props extends WithStyles<typeof styles>, InjectedI18nProps {
  /**
   * La acción a ejecutar cuando al cerrar el error. Si no se indica acción,
   * se reseteará todo el estado por defecto.
   */
  action?: () => void;

  /**
   * Etiqueta a mostrar en el botón para cerrar el error. Si no se indica se
   * utilizará un texto por defecto.
   */
  actionLabel?: string;

  /** El error producido. */
  error: any;

  /**
   * El mensaje de error a mostrar. Si no se indica se usará uno por defecto
   * según el tipo de error.
   */
  message?: string;
}

/** El estado del componente. */
export interface State {
  /** Número de veces que se ha pulsado para abrir los detalles del error. */
  detailsClickCount: number;

  /** Indica si el popup de detalles está abierto o cerrado. */
  detailsOpen: boolean;
}

/**
 * Pantalla genérica de error.
 */
class ErrorView extends React.PureComponent<Props, State> {
  /** #constructor */
  public constructor(props: Props) {
    super(props);

    this.state = {
      detailsClickCount: 0,
      detailsOpen: false,
    };
  }

  /** #render */
  public render() {
    const {
      action: propsAction,
      actionLabel: propsActionLabel,
      classes,
      error,
      i18n: { formatMessage },
      message: propsMessage,
    } = this.props;

    const { detailsOpen } = this.state;

    const defaults = this.defaults(error);
    const Icon = defaults.Icon;
    const message = propsMessage != null ? propsMessage : defaults.message;

    /*
     * ¿Cómo podemos llegar aquí sin tener una acción? Eso sí sería fallar a lo
     * grande. Pero estamos tratando errores, así que todo es posible. Si no hay
     * acción se recarga toda la página forzando un refresh.
     */
    const action = propsAction != null ? propsAction : () => window.location.reload(true);
    const actionLabel = propsActionLabel || formatMessage("errorView.close");

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.title}>
            <Icon className={classes.icon} onClick={this.handleOpenDetails} />
            <div>
              <Typography variant="h5">{formatMessage("errorView.errorTitle")}</Typography>
              {message.split("\n").map((line, index) => (
                <Typography variant="subtitle1" key={index}>
                  {line.trim()}
                </Typography>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.actions}>
          <Button color="primary" variant="contained" onClick={action} className={classes.button}>
            {actionLabel}
          </Button>
        </div>

        <Dialog open={detailsOpen} onClose={this.handleCloseDetails} fullScreen>
          <DialogContent>
            <ErrorDetails error={error} />
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleCloseDetails} variant="text">
              {formatMessage("errorView.close")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  /**
   * Devuelve el icono y texto por defecto para el error indicado.
   *
   * @param error el error que se ha producido.
   */
  private defaults = (error: any) => {
    const {
      i18n: { formatMessage },
    } = this.props;

    let Icon: React.ComponentType<SvgIconProps> = ErrorIcon;
    let message = formatMessage("errorView.error.applicationError");

    if (error != null) {
      if (error === HostUnreachable) {
        message = formatMessage("errorView.error.hostUnreachable");
      } else if (error === ConnectionTimeout) {
        message = formatMessage("errorView.error.connectionTimeout");
      } else if (error.type === ApiError) {
        const apiError: ApiError = error;

        /*
         * Todo error que no sea 5xx se supone que lo debería tratar la app. Si
         * no lo trata, entonces no es error del serivdor, sino de la app.
         */
        if (apiError.status >= 500 && apiError.status < 600) {
          Icon = ErrorIcon;
          message = formatMessage("errorView.error.serverError");
        }
      }
    }

    return { Icon, message };
  };

  /**
   * Handler para cerrar el diálogo de detalles.
   */
  private handleCloseDetails = () => {
    this.setState({
      detailsClickCount: 0,
      detailsOpen: false,
    });
  };

  /**
   * Handler para abrir el diálogo de detalles.
   */
  private handleOpenDetails = () => {
    this.setState(state => {
      const detailsClickCount = state.detailsClickCount + 1;

      return {
        detailsClickCount,
        detailsOpen: detailsClickCount >= 7,
      };
    });
  };
}

export default withI18n(withStyles(styles)(ErrorView));
