import axios from "axios";

import logger from "~/services/logger";

import { AUTH_TOKEN_HAEDER, BASE_URI } from "./config";

import { ApiError, ConnectionTimeout, HostUnreachable, Unauthorized } from "./types";

/** Instacia de axios para las conexiones con webapi. */
const axiosClient = axios.create({
  baseURL: BASE_URI,
  timeout: 30000,
});

/** Token de autenticación. */
let authToken = "";

/**
 * Función que intenta reautentica al usuario con las últimas credenciales
 * válidas conocidas.
 */
let reauthenticationHandler: () => Promise<void> = () => Promise.reject();

export type RequestTracker = (status: "START" | "END") => void;

/** Funciones a notificar cada vez que se inicia o finaliza una petición. */
const requestTrackers: Set<RequestTracker> = new Set();

/*
 * Interceptor para añadir el token de autenticación a todas las peticiones.
 * Se añade siempre y cuando no exista.
 */
axiosClient.interceptors.request.use(config => {
  const { auth, headers } = config;

  /*
   * Se añade el token de autenticación a la petición si:
   * - la petición no trae credenciales (auth)
   * - la petición no trae ya un token de autenticación
   * - tenemos un token de autenticación
   */
  if (auth == null && headers != null && headers.Authorization == null && authToken) {
    headers.Authorization = `Bearer ${authToken}`;
    logger.debug("Set authorization header: ", headers.Authorization);
  }

  return config;
});

/* Interceptor para notificar cuando se inicia una petición. */
axiosClient.interceptors.request.use(config => {
  requestTrackers.forEach(f => f("START"));

  return config;
});

/* Interceptor para notificar cuando finaliza una petición. */
axiosClient.interceptors.response.use(
  response => {
    requestTrackers.forEach(f => f("END"));

    return response;
  },
  error => {
    requestTrackers.forEach(f => f("END"));

    throw error;
  }
);

/*
 * Interceptor de respuesta para actualizar el token de autenticación en cada
 * llamada. Si el token está caducado, entonces se intenta refrescar y volver a
 * repetir la petición.
 */
axiosClient.interceptors.response.use(
  response => {
    const { status } = response;

    /*
     * Si es una respuesta OK, entonces actualizamos el token para enviarlo en
     * la siguiente petición.
     */
    if (status >= 200 && status < 300) {
      const newToken = response.headers[AUTH_TOKEN_HAEDER];
      if (newToken != null) {
        authToken = newToken;
        logger.debug("Update auth token", authToken);
      }
    }

    return response;
  },
  async error => {
    if (error.config && error.response && error.response.status === 401 && error.config.auth == null) {
      logger.debug("Authentication error, retry authentication");

      try {
        await reauthenticationHandler();
        logger.debug("Retry request");

        /* Hay que quitar la autorización inicial que fue mal. */
        error.config.headers.Authorization = null;

        return axiosClient.request(error.config);
      } catch (e) {
        /*
         * En caso de error, lo que nos interesa es lanzar el error original,
         * así que aquí no hacemos nada más que sacar un log.
         */
        logger.warn("Request retry failed");
      }
    }

    return Promise.reject(error);
  }
);

/** Interceptor para encapsular los errores Http en una estructura conocida. */
axiosClient.interceptors.response.use(
  response => response,
  async error => {
    /*
     * Tratamos los errores conocidos que la app puede gestionar. Si se da algún
     * otro error, se devuelve tal cual.
     */
    if (error.response) {
      /*
       * Que no tenga permiso lo tratamos diferente porque realmente no se ha
       * llegado a ejecutar nada.
       */
      if (error.response.status === 401) {
        return Promise.reject(Unauthorized);
      }

      const apiError: ApiError = {
        details: error.response.data != null ? { ...error.response.data } : undefined,
        status: error.response.status,
        type: ApiError,
      };

      return Promise.reject(apiError);
    } else if (error.code === "ECONNABORTED") {
      /* Según la doc de Axios devuelve "ECONNABORTED" si se da timeout. */
      return Promise.reject(ConnectionTimeout);
    } else if (error.config != null && error.request != null && error.response == null && error.code == null) {
      /*
       * Se mira que hay config y request, para intentar segurar que el error es
       * de una petición axios. Y miramos que no hay ni respuesta ni código,
       * para _suponer_ que es un error de conexión con el servidor. Puede ser
       * que no tengamos red o que le servidor no sea accesible por cualquier
       * otro motivo. La cuestión es que no llegamos.
       */

      // TODO: Encontrar una condición que asegure que capturamos el error correcto:
      /*
       * La condición del if es en base a varias pruebas, no estoy seguro que no
       * se escape algún caso.
       */

      // TODO: Ver cómo se podría probar que hay conexión con una petición anterior.

      return Promise.reject(HostUnreachable);
    }

    return Promise.reject(error);
  }
);

/**
 * Cliente para las peticiones a webapi.
 */
export default axiosClient;

/**
 * Función para establecer el la función que realiza el reintento de
 * autenticación.
 *
 * @param handler función a ejecutar para reautenticar
 */
export const setReauthenticationHandler = (handler: () => Promise<void>) => {
  reauthenticationHandler = handler;
};

/**
 * Añade una función para controlar el incio y fin de peticiones en curso.
 *
 * @param requestTracker función a registrar
 */
export const addRequestTracker = (requestTracker: RequestTracker) => {
  requestTrackers.add(requestTracker);
};

/**
 * Elimina una función registrada anteriormente.
 *
 * @param requestTracker referencia a la función a eliminar
 */
export const removeRequestTracker = (requestTracker: RequestTracker) => {
  requestTrackers.delete(requestTracker);
};
