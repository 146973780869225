import React from "react";

import Progress from "@material-ui/core/LinearProgress";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { InjectedI18nProps, withI18n } from "~/services/i18n";
import logger from "~/services/logger";
import { addRequestTracker, removeRequestTracker, RequestTracker } from "~/services/webapi/client";

/** Estilos por defecto. */
const styles = (theme: Theme) =>
  createStyles({
    backdrop: {
      backgroundColor: theme.palette.grey[100],
      height: "100%",
      left: 0,
      opacity: 0.68,
      position: "fixed",
      top: 0,
      width: "100%",
      // TODO: Llevar a theme
      zIndex: 10000,
    },
    content: {
      alignContent: "center",
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    root: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      left: 0,
      position: "fixed",
      top: 0,
      width: "100%",
      // TODO: Llevar a theme
      zIndex: 10000,
    },
    text: {
      display: "block",
      minWidth: 100,
    },
  });

/** El estado del componente. */
interface State {
  /** Número de procesos activos. */
  active: number;

  /** Inidica si la cortinilla debe estar visible o no. */
  visible: boolean;
}

interface ProvidedProps extends WithStyles<typeof styles>, InjectedI18nProps {}

/**
 * Cortinilla que muestra cuando la aplicación está cargando datos externos.
 */
class WaitingLayer extends React.PureComponent<ProvidedProps, State> {
  /** #constructor */
  public constructor(props: ProvidedProps) {
    super(props);

    this.state = {
      active: 0,
      visible: false,
    };
  }

  /**
   * Al montar el componente se registra el "tracker" en el cliente utilizado
   * para lanzar las peticiones al servidor. Permitirá saber cuántas peticiones
   * activas hay en cada comento.
   */
  public componentDidMount() {
    addRequestTracker(this.requestTracker);
  }

  /**
   * Antes de quitar el componente elimina el "tracker" de peticiones.
   * No se pueden seguir realizando actualizaciones una vez el componente ya no
   * está montado.
   */
  public componentWillUnmount() {
    removeRequestTracker(this.requestTracker);
  }

  /** #render */
  public render() {
    const classes = this.props.classes;
    const { active } = this.state;
    const { formatMessage } = this.props.i18n;

    logger.trace(`render active: ${active}`);

    return active > 0 ? (
      <>
        <div className={classes.backdrop}></div>
        <div className={classes.root}>
          <div className={classes.content}>
            <Typography variant="overline" className={classes.text}>
              {formatMessage("waitingLayer.loading")}
            </Typography>
            <Progress color="secondary" />
          </div>
        </div>
      </>
    ) : null;
  }

  private requestTracker: RequestTracker = status => {
    logger.trace(`trace: ${status}`);

    switch (status) {
      case "END":
        this.setState(prev => ({
          active: Math.max(prev.active - 1, 0),
        }));
        break;

      case "START":
        this.setState(prev => ({
          active: prev.active + 1,
        }));
        break;
    }
  };
}

export default withI18n(withStyles(styles)(WaitingLayer));
