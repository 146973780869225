/* eslint-disable @typescript-eslint/no-explicit-any */ // TODO Fix

import React from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import * as QRScanner from "~/services/scanner";

import { default as QRScannerView } from "./QRScannerView";

import logger from "~/services/logger";
import { AppState } from "~/state";
import { loadView, ViewId } from "~/views";

import { wrap } from "../ViewWrapper";

interface Props {
  goTo: (excursionCode: string, modalityCode: string) => void;
}

/** Estado propio de la vista */
interface State {
  isLightAvailable: boolean;
  isLightOn: boolean;
  qrCode?: string;
  scannError?: any;
}

/** Pantalla de escaneo de códigos QR */
class QRScannerViewContainer extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isLightAvailable: true,
      isLightOn: false,
      qrCode: undefined,
      scannError: null,
    };
  }

  /**
   * Al montar el componente se consulta el estado de la luz y se inicia el
   * escaner
   */
  public componentDidMount() {
    QRScanner.getStatus().then((status: QRScannerStatus) => {
      this.setState({
        isLightAvailable: status.canEnableLight,
        isLightOn: status.lightEnabled,
      });
    });

    QRScanner.scanQR((error, result) => {
      logger.debug("scannQR", error, result);
      if (error) {
        if (!this.state.scannError) {
          this.setState({ qrCode: undefined, scannError: error });
        }
      } else {
        if (result != null && result.includes("-") && result.split("-").length === 2) {
          const data = result.split("-");
          this.props.goTo(data[0], data[1]);
        } else {
          this.setState({ qrCode: result, scannError: null });
        }
      }

      /* Restaurar el fondo. */
      this.fixBackground(false);
    });

    /* Quitar el fondo. */
    this.fixBackground(true);
  }

  /** Al desmonatar el componente se cancela el escaneo */
  public componentWillUnmount() {
    QRScanner.cancelScan().then(() => {
      /* Restaurar el fondo. */
      this.fixBackground(false);
    });
  }

  public render() {
    /**
     * Si el escaner ha finalizado, bien o con error ahora se muestra
     * el resultado, lo suyo será hacer un redicrect a algún sitio
     */
    if (this.state.scannError) {
      return (
        <h1>
          Error:{" "}
          {typeof this.state.scannError === "object" ? JSON.stringify(this.state.scannError) : this.state.scannError}
        </h1>
      );
    }
    if (this.state.qrCode) {
      const qrCode = this.state.qrCode;

      return <h1>QRCode: {qrCode}</h1>;
    }

    /** Si se llega hasta aquí hay que mostrar la vista del escanner */
    return (
      <QRScannerView
        isLightAvailable={this.state.isLightAvailable}
        isLightOn={this.state.isLightOn}
        turnLightOn={this.turnLightOn}
        turnLightOff={this.turnLightOff}
      />
    );
  }

  /** Handler para cuando se solicita apagar la luz */
  public turnLightOff = () => {
    QRScanner.turnLightOff().then(status => {
      this.setState({ isLightOn: status.lightEnabled });
    });
  };

  /** Handler para cuando se solicita encender la luz */
  public turnLightOn = () => {
    QRScanner.turnLightOn().then(status => {
      this.setState({ isLightOn: status.lightEnabled });
    });
  };

  /**
   * Reestablece el color de fondo de la App.
   * Se hace porque la librería del scanner la deja transparente una vez
   * finalizado es scan. En el dispostivo se ve negro a partir de entonces.
   */
  // TODO: Debería encargarse el servicio scanner.
  private fixBackground = (clear: boolean) => {
    const body = document.body;
    if (body.style) {
      body.style.backgroundColor = clear ? "rgba(0,0,0,0.01)" : "#fff";
      body.style.backgroundImage = "";
      setTimeout(() => {
        body.style.backgroundColor = clear ? "transparent" : "#fff";
      }, 50);
      if (body.parentElement && body.parentElement.style) {
        body.parentElement.style.backgroundColor = clear ? "transparent" : "#fff";
        body.parentElement.style.backgroundImage = "";
      }
    }
  };
}

/* Se le pasa la propiedad para cambiar la vista. A lo bruto. */
export default connect(null, (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  goTo: (excursionCode: string, modalityCode: string) =>
    dispatch(loadView(ViewId.addExcursion, { excursionCode, modalityCode })),
}))(wrap(QRScannerViewContainer));
