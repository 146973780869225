/* eslint-disable @typescript-eslint/no-non-null-assertion */ // TODO Fix

import React from "react";

import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Agency, CodeName } from "~/services/webapi/types";

import { OneButtonBottomPane } from "~/components/BottomButtonsPane";
import CodeNameSelector from "~/components/CodeNameSelector";
import { InjectedI18nProps, withI18n } from "~/services/i18n";

const styles = () =>
  createStyles({
    button: {
      alignSelf: "flex-end",
      marginLeft: 20,
      marginTop: 20,
    },
    fill: {
      flexGrow: 1,
      flexShrink: 1,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    root: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexShrink: 0,
    },
  });

export interface Props extends WithStyles<typeof styles> {
  agencies?: Agency[];
  agencyCode?: string;
  agencyEnabled: boolean;

  /**
   * Indica que no se deben cargar datos. Se usa si hay error de carga de
   * destinos para no entrar en bucle de errores.
   */
  dataLoadError?: boolean;
  destinationCode?: string;
  destinations?: CodeName[];
  duration?: string;
  durations?: CodeName[];
  excursion?: string;
  excursions?: CodeName[];
  excursionType?: string;
  excursionTypes?: CodeName[];
  feature?: string;
  features?: CodeName[];
  fetchAgencies: () => void;
  fetchDestinations: () => void;
  fetchExcursions: (destinationCode: string) => void;
  modalities?: CodeName[];
  modality?: string;
  onAgencyChange: (value: Agency) => void;
  onDestinationChange: (value: string) => void;
  onDurationChange: (value: string) => void;
  onExcursionChange: (value: string) => void;
  onExcursionTypeChange: (value: string) => void;
  onFeaturechange: (value: string) => void;
  onModaliytChange: (value: string) => void;
  onReload: () => void;
  onSearch: (excursionCode: string, modalityCode: string) => void;
  onSegementatonChange: (value: string) => void;
  segmentation?: string;
  segmentations?: CodeName[];
}

interface ProvidedProps extends Props, InjectedI18nProps {}

/**
 * Pantalla de búsqueda o selección de excursión.
 */
class SearcherView extends React.PureComponent<ProvidedProps, { redirect: boolean }> {
  public componentDidMount() {
    this.checkData();
  }
  public componentDidUpdate() {
    this.checkData();
  }

  /** #render */
  public render() {
    const margin = "dense";
    const { formatMessage } = this.props.i18n;

    if (this.props.dataLoadError) {
      /*
       * No cargamos datos porque está fallando. Notificamos con un mensaje y
       * ponemos un botón de reintentar carga.
       */
      return (
        <div className={this.props.classes.root}>
          <form className={this.props.classes.form}>
            <Typography>{formatMessage("searcherView.unableToLoadDestinations")}</Typography>
            <OneButtonBottomPane
              nextEnabled
              nextLabel={formatMessage("searcherView.retry")}
              onNext={this.props.onReload}
            />
          </form>
        </div>
      );
    }

    return (
      <div className={this.props.classes.root}>
        <form className={this.props.classes.form}>
          {this.props.agencies != null && this.props.agencies.length > 1 && (
            <CodeNameSelector
              disabled={!this.props.agencyEnabled}
              label={formatMessage("searcherView.agency")}
              margin={margin}
              selectedCode={this.props.agencyCode}
              values={this.props.agencies}
              onValueChange={this.onAgencySelect}
            />
          )}
          <CodeNameSelector
            label={formatMessage("searcherView.destination")}
            margin={margin}
            selectedCode={this.props.destinationCode}
            values={this.props.destinations}
            onValueChange={this.props.onDestinationChange}
          />
          <CodeNameSelector
            addEmpty
            margin={margin}
            label={formatMessage("searcherView.excursionType")}
            selectedCode={this.props.excursionType}
            values={this.props.excursionTypes}
            onValueChange={this.props.onExcursionTypeChange}
          />
          <CodeNameSelector
            label={formatMessage("searcherView.excursion")}
            margin={margin}
            selectedCode={this.props.excursion}
            values={this.props.excursions}
            onValueChange={this.props.onExcursionChange}
          />
          <CodeNameSelector
            addEmpty
            label={formatMessage("searcherView.segmentation")}
            margin={margin}
            selectedCode={this.props.segmentation}
            values={this.props.segmentations}
            onValueChange={this.props.onSegementatonChange}
          />
          <CodeNameSelector
            addEmpty
            label={formatMessage("searcherView.feature")}
            margin={margin}
            selectedCode={this.props.feature}
            values={this.props.features}
            onValueChange={this.props.onFeaturechange}
          />
          <CodeNameSelector
            addEmpty
            margin={margin}
            label={formatMessage("searcherView.duration")}
            selectedCode={this.props.duration}
            values={this.props.durations}
            onValueChange={this.props.onDurationChange}
          />
          <CodeNameSelector
            label={formatMessage("searcherView.modality")}
            margin={margin}
            selectedCode={this.props.modality}
            values={this.props.modalities}
            onValueChange={this.props.onModaliytChange}
          />
          <OneButtonBottomPane
            nextEnabled={!!this.props.excursion && !!this.props.modality}
            nextLabel={formatMessage("searcherView.search")}
            onNext={this.onSearch}
          />
        </form>
      </div>
    );
  }

  private checkData() {
    if (!this.props.dataLoadError) {
      if (this.props.agencies === undefined) {
        this.props.fetchAgencies();
      } else if (this.props.destinations === undefined && this.props.agencyCode) {
        this.props.fetchDestinations();
      } else if (this.props.excursions === undefined && this.props.destinationCode) {
        this.props.fetchExcursions(this.props.destinationCode);
      }
    }
  }

  private onAgencySelect = (code: string) => {
    if (code != null) {
      const agency = this.props.agencies!.find(a => a.code === code);
      if (agency != null) {
        this.props.onAgencyChange(agency);
      }
    }
  };

  private onSearch = () => {
    this.props.onSearch(this.props.excursion || "", this.props.modality || "");
  };
}

export default withI18n(withStyles(styles)(SearcherView));
