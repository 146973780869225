/**
 * Text alignement
 */
export enum Alignment {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2,
}

/**
 * One - dimensional code type:
 *
 * UPC-A: [UPC_A] Found on nearly every retail product, these barcodes were
 * originally created for grocery stores to provide quick receipt printing
 * and inventory tracking. After securing a UPC number, a manufacturer will
 * receive a unique company number to combine with their individual product
 * numbers. UPC-A is used for marking products which are sold at retail in
 * the USA.
 *
 * UPC-E: [UPC_E] is a compressed barcode which is intended for use on
 * physically small items.  Compression works by squeezing extra zeroes out
 * of the barcode and then automatically re-inserting them at the scanner.
 * Only barcodes containing zeroes are candidates for the UPC-E symbol.
 * GS1 is very stingy when it comes to handing out manufacturer ID numbers
 * that are rich in zeroes; these are reserved manufacturers of products which
 * have a genuine need for the UPC-E symbol.  If you need a small symbol, tell
 * GS1 when you apply for a manufacturer's ID number and be prepared to
 * substantiate your need.
 *
 * EAN13 (JAN13): Considered a superset of the UPC, these barcodes are used
 * specifically by booksellers, libraries, universities and wholesalers for
 * book traceability. These 13-digit codes are created from the International
 * Standard Book Numbers (ISBN) for each respective book tracked. Like UPCs,
 * these are standardized for the unique identification of publishers.
 *
 * EAN8 (JAN8): Same as EAN13 but with 8-digit
 *
 * CODE39: This is one of the oldest barcodes around and is a common
 * symbology found in electronics, healthcare, and government. It is
 * a lineal, 1D, alphanumeric code with the ability to include the
 * entire 128 ASCII character set and extend to any length, only limited
 * by the size of the label. If space is a concern, Code 128 would be a
 * better choice to consider.
 *
 * ITF: (interleaved) Commonly found in warehouse, distribution, and
 * manufacturing, Code I 2 of 5 is a numeric-only barcode used to encode
 * pairs of numbers. Every two digits are paired to create one symbol.
 * The number of digits used must be even for this format to work, so a
 * zero is commonly added at the end of an odd set of numbers.
 *
 * CODABAR: Codabar is the barcode developed by Monarch Marking Systems
 * in 1972. It is the barcode introduced at early stage following "2 of 5".
 * It is widely used for applications that require serial numbers, such as
 * management of blood banks, slips for door-todoor delivery services and
 * member cards. Codabar has 4 bars and 3 spaces (total 7 elements) with
 * each narrow or wide width representing one character (letter).
 *
 * CODE93: designed in 1982 by Intermec to provide a higher density and data
 * security enhancement to Code 39. It is an alphanumeric, variable length
 * symbology. Code 93 is used primarily by Canada Post to encode supplementary
 * delivery information. Every symbol includes two check characters. Each Code
 * 93 character is nine modules wide, and always has three bars and three
 * spaces, thus the name. Each bar and space is from 1 to 4 modules wide. (For
 * comparison, a Code 39 character consists of five bars and five spaces, three
 * of which are wide, for a total width of 13–16 modules.)
 *
 * CODE128: Derived from the ASCII 128 character set (0-9, a-z, A-Z, and
 * some special characters), this compact barcode is used extensively in
 * packaging and shipping applications worldwide. Code 128 features an
 * automatic switching setting that allows users to optimize it for barcode
 * length.
 */
export enum BarCodeType {
  UPC_A = 0,
  UPC_E = 1,
  EAN13 = 2,
  EAN8 = 3,
  CODE39 = 4,
  ITF = 5,
  CODABAR = 6,
  CODE93 = 7,
  CODE128 = 8,
}

/**
 * Bar code text printing position
 */
export enum BarCodeTextPosition {
  NO_PRINT_TEXT = 0,
  ABOVE_THE_BARCODE = 1,
  BELOW_THE_BARCODE = 2,
  BOTH = 3,
}

/**
 * QR code error correction level
 *
 * L: Low (7%)
 * M: Medium (15%)
 * Q: Quality (25%)
 * H: High (30%)
 */
export enum QRErrorLevel {
  L = 0,
  M = 1,
  Q = 2,
  H = 3,
}

/**
 * Summi Inner Printer
 */
export interface SunmiInnerPrinter {
  /**
   * Get the print head print length
   */
  getPrintedLength(): Promise<number>;

  /**
   * Get the printer board serial numbe
   */
  getPrinterSerialNo(): Promise<string>;

  /**
   * Get the printer firmware version number
   */
  getPrinterVersion(): Promise<string>;

  hasPrinter(): Promise<number>;

  /**
   * The printer runs on n lines of paper
   * @param count lines of paper
   */
  lineWrap(count: number): Promise<void>;

  /**
   * One-dimensional code printing
   *
   * @param barCodeData one-dimensional code content
   * @param symbology barcode type
   * @param width bar code width, value 2-6, default: 2.
   * @param height bar code height, value 1-255, default: 162.
   * @param textPosition text position
   */
  printBarCode(
    barCodeData: string,
    symbology: BarCodeType,
    width: number,
    height: number,
    textPosition: BarCodeTextPosition
  ): Promise<void>;

  /**
   * Print pictures
   *
   * Note: the maximum width is 384 pixels, beyond which the exception function
   * cannot be printed and callback is called back
   * @param bitmapData base64ImageData
   * @param width image width
   * @param height image height
   */
  printBitmap(bitmapData: string, width: number, height: number): Promise<void>;

  /**
   * Print a row of the table (no Arabic characters are supported)
   *
   * @param colsTextArr column text string array
   * @param colsWidthArr column width array, in English characters, each Chinese
   * character takes up two English characters, each width greater than 0.
   * @param colsAlign alignment
   */
  printColumnsText(colsTextArr: string[], colsWidthArr: number[], colsAlign: Alignment[]): Promise<void>;

  /**
   * Printer initialization
   */
  printerInit(): Promise<void>;

  /**
   * Printer self check
   */
  printerSelfChecking(): Promise<void>;

  printerStatusStartListener(): Promise<void>;

  printerStatusStopListener(): Promise<void>;

  /**
   * Print vector text
   *
   * @param text Text to print. When the printed content is less than one
   * or more lines, you need to add a line break "\n" at the end of the
   * content to print it immediately, otherwise it will be cached in the
   * buffer.
   */
  printOriginalText(text: string): Promise<void>;

  /**
   * Two-dimensional code printing
   *
   * @param qrCode qr code content.
   * @param moduleSize qr code block size, unit: dot, values 4 to 16
   * @param errorLevel qr code error correction level
   */
  printQRCode(qrCode: string, moduleSize: number, errorLevel: QRErrorLevel): Promise<void>;

  /**
   * Print text
   * @param value Text to print. When the printed content is less than one or
   * more lines, you need to add a line break "\n" at the end of the content
   * to print it immediately, otherwise it will be cached in the buffer.
   */
  printString(value: string): Promise<void>;

  /**
   * Print the text of the specified font size
   * @param text Text to print. When the printed content is less than one or
   * more lines, you need to add a line break "\n" at the end of the content to
   * print it immediately, otherwise it will be cached in the buffer.
   * @param typeface font name (currently not supported by the existing version)
   * @param fontSize fontsize is only valid for this method.
   */
  printTextWithFont(text: string, typeface: string, fontSize: number): Promise<void>;

  /**
   * Print the ESC/POS format instruction
   * @param base64EncriptedData ESC/POS instruction base64 encoded
   */
  sendRAWData(base64EncriptedData: string): Promise<void>;

  /**
   * Set alignment mode
   * @param alignment alignment
   */
  setAlignment(alignment: Alignment): Promise<void>;

  /**
   * Set print font (not currently supported)
   * @param typeface The typeface font name currently supports the  font "gh",
   * which  is  an  equal width Chinese font.
   */
  setFontName(typeface: string): Promise<void>;

  /**
   * Set font size
   * @param fontSize font size
   */
  setFontSize(fontSize: number): Promise<void>;
}

/** Contains base64 encoded image data */
export interface BitmapData {
  /** base64 image data */
  base64Data: string;
  /** image height in pixels */
  height: number;
  /** image width in pixels */
  width: number;
}

/** Basic printing options */
export interface PrintingOptions {
  /** Number of blank lines before print */
  headingWhiteLines: number;
  /** Number of blank linkes after print */
  trailingWhiteLines: number;
}

/** QR printing options */
export interface QRPrintingOptions extends PrintingOptions {
  /** QR error level */
  errorLevel: QRErrorLevel;
  /** Dot size 4 to 16 */
  moduleSize: number;
}
